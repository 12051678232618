import React from "react";

const AppSpinner = (props) => {
  return (
    <>
      <div className="d-flex justify-content-center m-3">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">{props.title}</span>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <small role="status">{props.title}</small>
      </div>
    </>
  );
};

export default AppSpinner;

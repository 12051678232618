import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

export default function TableCountry({ columns, data }) {
  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("data.name", value);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex flex-row align-items-center align-self-center">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Data Filter:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder={"Search Country"}
              aria-label={"Search Country"}
              aria-describedby="basic-addon1"
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table table-striped table-borderless align-middle"
          >
            <thead className="table-dark">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((columns) => {
                    return columns.hideHeader === false ? null : (
                      <th
                        {...columns.getHeaderProps(
                          columns.getSortByToggleProps()
                        )}
                        scope="col"
                      >
                        {columns.render("Header")}
                        <span className="mx-1">
                          {columns.isSorted ? (
                            columns.isSortedDesc ? (
                              <i className="bi bi-arrow-down small"></i>
                            ) : (
                              <i className="bi bi-arrow-up small"></i>
                            )
                          ) : columns.disableSortBy === true ? null : (
                            <i className="bi bi-arrow-down-up small"></i>
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-center flex-wrap mb-5">
          <div className="px-2">
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-sm">
                <li
                  className="page-item"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <span className="page-link px-3">First</span>
                </li>
                <li
                  className="page-item"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <span className="page-link px-2">&laquo;</span>
                </li>
                <li className="page-item px-1">
                  <span className="page-link px-3 text-wrap">
                    Page {pageIndex + 1} of {pageOptions.length}{" "}
                  </span>
                </li>
                <li
                  className="page-item"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <span className="page-link px-2">&raquo;</span>
                </li>
                <li
                  className="page-item me-3"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <span className="page-link px-3">Last</span>
                </li>
              </ul>
            </nav>
          </div>
          <div className="px-2">
            <select
              className="form-select form-select-sm"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

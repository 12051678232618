import React, { useState, useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import AppBody from "./Components/AppBody";
import AppHeader from "./../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import "./../App.css";

export default function Dashboard() {
  const [success, setSuccess] = useState(true);

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Dashboard", slug: "/dashboard", status: "active" },
  ];

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
      // navigate("../login", { replace: true });
    }
  }, [token]);

  return (
    <>
      {success ? (
        <div className="container-fluid p-0 m-0">
          <AppHeader />
          <div className="d-flex flex-row border-bottom p-2 px-3">
            <div className="flex-grow-1 mt-1 small">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>&nbsp;</div>
          </div>
          <AppBody />
          <AppFooter />
        </div>
      ) : (
        <Navigate to="/login" replace={true} />
      )}
    </>
  );
}

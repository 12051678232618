import axios from "axios";
import React, { useState, useEffect } from "react";
import { API } from ".././Config/Config";

export default function AppFooter() {
  const [getVersioning, setVersioning] = useState([]);
  const [production, setProduction] = useState(true);

  useEffect(() => {
    // get api URL
    const apiUrl = API;
    // get versioning infor
    axios.get(apiUrl + "/version").then((response) => {
      // console.log(response.data.data);
      setVersioning(response.data.data);
    });
  }, []);

  return (
    <>
      {!production ? (
        <div className="d-flex fixed-bottom bg-dark p-2 text-white justify-content-md-between z-0 mt-5">
          <div className="px-2 text-center">
            <span className="m-0 py-0 text-white small">&copy; 2022 - 2023 | Maxx's Group International. All rights reserved.</span>
          </div>
          <div className="px-2 d-none d-lg-block">
            <span className="mx-1 py-1 px-2 badge text-bg-light">API: {API}</span>
            <span className="mx-1 py-1 px-2 badge text-bg-light">PHP: {getVersioning.php}</span>
            {/* |
        <span className="mx-1 py-0 px-1 text-white small">
          Laravel: {getVersioning.laravel}
        </span>
        |
        <span className="mx-1 py-0 px-1 text-white small">
          React: {getVersioning.reactjs}
        </span> */}
            <span className="mx-1 py-1 px-2 badge text-bg-light">Bootstrap: {getVersioning.bootstrap}</span>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from ".././Config/Config";

const Login = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const token = localStorage.getItem("token");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [validation, setValidation] = useState([]);

  const [ip, setIP] = useState("");

  const getIP = async () => {
    await axios
      .get("https://geolocation-db.com/json/")
      .then((response) => {
        // console.log(response.data);
        setIP(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let api = API;

  useEffect(() => {
    getIP();
    if (token) {
      setSuccess(true);
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Login In ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(
        api + "/users/login",
        JSON.stringify({
          email: email,
          password: password,
          geolocation: ip ? ip : "",
        }),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        },
        10000
      )
      .then(function (response) {
        if (response.data.status === "success") {
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("roles", response.data.data.roles);
          localStorage.setItem("expires_at", response.data.data.expires_at);
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(true);
        } else if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.close();
        }
      })
      .catch(function (error) {
        // console.log(error);
        Swal.close();
        if (error.code === "ERR_NETWORK") {
          Swal.fire({
            icon: "error",
            text: "An Error Occured, Please contact your administrator. Error code : " + error.code,
            // timer: 2000,
          });
        }
      });
  };

  const clickHandler = (event) => {
    alert("api source : " + api);
  };

  return (
    <>
      {success ? (
        <Navigate to="/dashboard" replace={true} />
      ) : (
        <div className="d-flex min-vh-100 min-vw-100 text-left bg-light justify-content-center py-3">
          <form className="m-auto pe-3" style={{ maxWidth: "330px" }}>
            <div className="logo text-center px-5">
              <img className="mb-5" src="/assets/brand/maxxs-logo-h-text-black.png" alt="Maxx's Group International" height="65" onDoubleClick={clickHandler} />
              <h1 className="h3 mb-3">Sign in</h1>
            </div>
            {validation.errMessage && (
              <div className={`text-wrap alert alert-danger ${validation.errMessage ? "d-block" : "d-none"}`}>
                <i className="bi bi-exclamation-circle me-2"></i>
                {validation.errMessage[0]}
              </div>
            )}
            <div className="form-floating mb-1">
              <input type="email" className={`form-control ${validation.email ? "is-invalid" : ""}`} id="floatingEmail" placeholder="name@example.com" required value={email} onChange={(e) => setEmail(e.target.value)} />
              <label htmlFor="floatingEmail">Email</label>
              {validation.email && (
                <div className={`invalid-feedback ${validation.email ? "d-block mb-3" : "d-none"}`}>
                  <i className="bi bi-exclamation-circle me-2"></i>
                  {validation.email[0]}
                </div>
              )}
            </div>
            <div className="form-floating mb-4">
              <input type="password" className={`form-control ${validation.password ? "is-invalid" : ""}`} id="floatingPassword" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
              <label htmlFor="floatingPassword">Password</label>
              {validation.password && (
                <div className={`invalid-feedback ${validation.password ? "d-block" : "d-none"}`}>
                  <i className="bi bi-exclamation-circle me-2"></i>
                  {validation.password[0]}
                </div>
              )}
            </div>
            <button className="w-100 btn btn-lg btn-primary" onClick={handleSubmit}>
              Sign in
            </button>
            <div className="checkbox mt-3 text-center">
              <button
                type="button"
                className="btn btn-link link-primary text-decoration-none btn-sm"
                onClick={() => {
                  navigate("/register" + location.search);
                }}
              >
                Create account
              </button>
              |
              <button
                type="button"
                className="btn btn-link link-primary text-decoration-none btn-sm"
                onClick={() => {
                  navigate("/#" + location.search);
                }}
              >
                Forgot Password
              </button>
            </div>
            <p className="mt-5 mb-3 text-muted small text-center">
              &copy; 2022-2023
              <br />
              Maxx's Group International
              <br />
              All rights reserved
            </p>
          </form>
        </div>
      )}
    </>
  );
};

export default Login;

import React, { useState } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

export default function TablePermission({ columns, data }) {
  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("data.name", value);
    setFilterInput(value);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="container-fluid">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            Data Filter:
          </span>
          <input className="form-control" value={filterInput} onChange={handleFilterChange} placeholder={"Search Permission"} />
        </div>
        <br />
        <table {...getTableProps()} className="table table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((columns) => {
                  return columns.hideHeader === false ? null : (
                    <th {...columns.getHeaderProps(columns.getSortByToggleProps())}>
                      {columns.render("Header")}
                      <span>
                        {columns.isSorted ? (
                          columns.isSortedDesc ? (
                            <i className="bi bi-arrow-down text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                          ) : (
                            <i className="bi bi-arrow-up text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                          )
                        ) : columns.disableSortBy === true ? null : (
                          <i className="bi bi-arrow-down-up text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table-group-divider">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="col-md-4 fixed">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <nav className="float-end">
          <ul className="pagination flex-wrap">
            <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <a className="page-link">First</a>
            </li>
            <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <a className="page-link">{"<"}</a>
            </li>
            <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
              <a className="page-link">{">"}</a>
            </li>
            <li className="page-item me-3" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <a className="page-link">Last</a>
            </li>
            <li className="me-3">
              <a className="page-link">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </a>
            </li>
            {/* <li className="me-3">
              <a className="page-link">
                <input
                  className="form-control"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px", height: "20px" }}
                />
              </a>
            </li>{" "} */}
            <select
              className="form-control"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ width: "120px", height: "38px" }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </ul>
        </nav>
      </div>
    </>
  );
}

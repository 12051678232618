import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { Navigate, Link } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { API } from ".././Config/Config";

export default function CreateOfficeLocation() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Office", slug: "/office", status: "" },
    { id: "3", title: "Create Office Location", slug: "/office/create", status: "active" },
  ];

  const [listCountry, setListCountry] = useState([]);
  const [listState, setListState] = useState([]);
  const [listCity, setListCity] = useState([]);

  const fetchCountry = useCallback(async () => {
    await axios.get(api + "/office/getCountry", header).then((response) => {
      setListCountry(response.data.data);
    });
  }, [token, header]);

  const fetchState = (e) => {
    setAddressCountry(e.target.value);
    axios.get(api + "/office/getState?country_id=" + e.target.value, header).then((response) => {
      setListState(response.data.data);
    });
  };

  const fetchCity = (e) => {
    setAddressState(e.target.value);
    axios.get(api + "/office/getCity?state_id=" + e.target.value, header).then((response) => {
      setListCity(response.data.data);
    });
  };

  const [success, setSuccess] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [telephone, setTelephone] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [validation, setValidation] = useState([]);

  const saveOffice = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("telephone", telephone);
    formData.append("address_name", addressName);
    formData.append("address_street", addressStreet);
    formData.append("address_city", addressCity);
    formData.append("address_state", addressState);
    formData.append("address_country", addressCountry);
    formData.append("address_zip_code", addressZipCode);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await axios.post(api + "/office/create", formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchCountry();
  }, []);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/office" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form onSubmit={saveOffice}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className={`form-control ${validation.name ? "is-invalid" : ""}`} value={name} onChange={(e) => setName(e.target.value)} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">Name</label>
                  {validation.name && (
                    <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.name[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="email" className={`form-control ${validation.email ? "is-invalid" : ""}`} value={email} onChange={(e) => setEmail(e.target.value)} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">Email address</label>
                  {validation.email && (
                    <div className={`invalid-feedback ${validation.email ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.email[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className={`form-control ${validation.mobile ? "is-invalid" : ""}`} value={mobile} onChange={(e) => setMobile(e.target.value)} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">Mobile</label>
                  {validation.mobile && (
                    <div className={`invalid-feedback ${validation.mobile ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.mobile[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className={`form-control ${validation.telephone ? "is-invalid" : ""}`} value={telephone} onChange={(e) => setTelephone(e.target.value)} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">Telephone</label>
                  {validation.telephone && (
                    <div className={`invalid-feedback ${validation.telephone ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.telephone[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className={`form-control ${validation.address_name ? "is-invalid" : ""}`} value={addressName} onChange={(e) => setAddressName(e.target.value)} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">Address Name</label>
                  {validation.address_name && (
                    <div className={`invalid-feedback ${validation.address_name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.address_name[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className={`form-control ${validation.address_street ? "is-invalid" : ""}`}
                    value={addressStreet}
                    onChange={(e) => setAddressStreet(e.target.value)}
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "100px" }}
                  ></textarea>
                  <label htmlFor="floatingTextarea2">Address Street</label>
                  {validation.address_street && (
                    <div className={`invalid-feedback ${validation.address_street ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.address_street[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.address_country ? "is-invalid" : ""}`} id="floatingSelect" value={addressCountry} onChange={fetchState} aria-label="Floating label select example">
                    <option>Select Country</option>
                    {listCountry.map((data, index) => (
                      <option key={index} value={data.data.id}>
                        {data.data.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Address Country</label>
                  {validation.address_country && (
                    <div className={`invalid-feedback ${validation.address_country ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.address_country[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.address_state ? "is-invalid" : ""}`} id="floatingSelect" value={addressState} onChange={fetchCity} aria-label="Floating label select example">
                    <option>Select State</option>
                    {listState.map((data, index) => (
                      <option key={index} value={data.data.id}>
                        {data.data.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Address State</label>
                  {validation.address_state && (
                    <div className={`invalid-feedback ${validation.address_state ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.address_state[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.address_city ? "is-invalid" : ""}`} id="floatingSelect" value={addressCity} onChange={(e) => setAddressCity(e.target.value)} aria-label="Floating label select example">
                    <option>Select City</option>
                    {listCity.map((data, index) => (
                      <option key={index} value={data.data.id}>
                        {data.data.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Address City</label>
                  {validation.address_city && (
                    <div className={`invalid-feedback ${validation.address_city ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.address_city[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className={`form-control ${validation.address_zip_code ? "is-invalid" : ""}`}
                    value={addressZipCode}
                    onChange={(e) => setAddressZipCode(e.target.value)}
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Address Zip Code</label>
                  {validation.address_zip_code && (
                    <div className={`invalid-feedback ${validation.address_zip_code ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.address_zip_code[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-2">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/office" replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import AppHeader from "./../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
import Swal from "sweetalert2";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API, Storage } from ".././Config/Config";

function EditParam() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const apiUrl = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Parameter", slug: "/parameter", status: "" },
    { id: "3", title: "Edit Parameter", slug: "/parameter/edit", status: "active" },
  ];

  const [success, setSuccess] = useState(true);

  const { id } = useParams();

  const [icon, setIcon] = useState([]);
  const [pict, setPict] = useState([]);
  const [iconUrl, setIconUrl] = useState([]);
  const [group, setGroup] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [show, setShow] = useState("");
  const [formData, setFormData] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    setFormData(new FormData());

    axios
      .get(apiUrl + `/parameters/data/${id}`, header)
      .then(function (response) {
        let params = response.data.data;
        setGroup(params.group);
        setName(params.name);
        setShow(params.show);
        setPict(params.icon);
        setDescription(params.description);
        setSlug(params.slug);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [apiUrl, header, id]);

  const handleToggle = () => {
    setShow(!show);
    var data = !show ? 1 : 0;
    formData.append("show", data);
  };

  useEffect(() => {
    if (icon.length < 1) return;
    const newIconUrls = [];
    icon.forEach((icons) => newIconUrls.push(URL.createObjectURL(icons)));
    setIconUrl(newIconUrls);
  }, [icon]);

  function onIconChange(e) {
    setIcon([...e.target.files]);
  }

  if (icon === null) {
    setIcon("");
  }
  if (description === null) {
    setDescription("");
  }
  if (slug === null) {
    setSlug("");
  }

  const updateParam = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    formData.append("icon", icon[0]);
    formData.append("group", group);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("slug", slug);

    // console.log(...formData);

    const response = await axios.post(apiUrl + `/parameters/update/${id}`, formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      // console.log(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/parameter" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form onSubmit={updateParam}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                {validation.errMessage && (
                  <div className={`alert alert-danger ${validation.errMessage ? "d-block" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle"></i>
                    {validation.errMessage[0]}
                  </div>
                )}
                <div className="mb-3">
                  <img
                    src={Storage + `/parameters/icons/${id}/${pict}`}
                    alt="Icons"
                    style={{ display: icon.length < 1 ? "block" : "none", marginLeft: "auto", marginRight: "auto", width: "125px", height: "125px", borderRadius: "50%", marginBottom: "2rem" }}
                  />
                  {iconUrl.map((imageSrc) => (
                    <img src={imageSrc} alt="Icons" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "125px", height: "125px", borderRadius: "50%", marginBottom: "2rem" }} />
                  ))}
                  <label htmlFor="formFile" className="form-label">
                    Icon
                  </label>
                  <input type="file" multiple accept="image/*" onChange={onIconChange} name="icon" className="form-control" />
                  {validation.icon && <div className="alert alert-danger">{validation.icon[0]}</div>}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={group} onChange={(e) => setGroup(e.target.value)} className={`form-control ${validation.group ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Group <sup className="text-danger">*</sup>
                  </label>
                  {validation.group && (
                    <div className={`invalid-feedback ${validation.group ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.group[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} className={`form-control ${validation.name ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Name <sup className="text-danger">*</sup>
                  </label>
                  {validation.name && (
                    <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.name[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className={`form-control ${validation.description ? "is-invalid" : ""}`}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Leave a Description here"
                    id="floatingTextarea2"
                    value={description}
                    style={{ height: "100px " }}
                  ></textarea>
                  <label htmlFor="floatingTextarea2">Description</label>
                  {validation.description && (
                    <div className={`invalid-feedback ${validation.description ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.description[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} className={`form-control ${validation.slug ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Slug <sup className="text-danger"></sup>
                  </label>
                  {validation.slug && (
                    <div className={`invalid-feedback ${validation.slug ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.slug[0]}
                    </div>
                  )}
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input" onChange={handleToggle} checked={show} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Show
                  </label>
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-3">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/parameter" replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

export default EditParam;

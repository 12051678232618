import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { API } from "../../Config/Config";
import AppFormater from "../../Components/AppFormater";
import AppSpinner from "../../Components/AppSpinner";

export default function DashboardCustomerProfile() {
  //
  let counter = 0;
  //get api from local storage
  const apiUrl = API;
  //get token from local storage
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [getLoadingStatus, setLoadingStatus] = useState(true);
  // const [getCounter, setCounter] = useState(0);

  const [getCustomers, setCustomers] = useState("");
  const [getCustomerByGender, setCustomerByGender] = useState("");
  const [getCustomerByStatus, setCustomerByStatus] = useState("");
  const [getCustomerByReligion, setCustomerByReligion] = useState("");
  const [getCustomerByMarital, setCustomerByMarital] = useState("");
  const [getCustomerByBloodType, setCustomerByBloodType] = useState("");
  const [getCustomerByRangeOfAges, setCustomerByRangeOfAges] = useState("");
  const [getCustomerByNationality, setCustomerByNationality] = useState("");

  useEffect(() => {
    axios
      .get(apiUrl + "/dashboard/customer", header)
      .then(function (response) {
        // console.log(response.data);
        setCustomers(response.data.data.customers);

        setCustomerByGender(response.data.data.customer_by_gender);
        setCustomerByStatus(response.data.data.customer_by_status);
        setCustomerByReligion(response.data.data.customer_by_religion);
        setCustomerByMarital(response.data.data.customer_by_marital);
        setCustomerByBloodType(response.data.data.customer_by_blood_type);
        setCustomerByRangeOfAges(response.data.data.customer_range_of_ages);
        setCustomerByNationality(response.data.data.customer_by_nationality);
        setLoadingStatus(false);
      })
      .catch(function (error) {
        // console.log(error);
        setLoadingStatus(false);
      });
  }, [apiUrl, header, getLoadingStatus]);

  return (
    <>
      <div>
        <div className="fs-5">
          <i className="bi bi-people me-2"></i>Customers Profile
          <div className="float-end">
            <i className="bi bi-three-dots me-2"></i>
          </div>
        </div>
        <hr />
        {getLoadingStatus ? (
          <AppSpinner title="Loading data customer profile ..." />
        ) : (
          <div>
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-primary-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-primary-subtle p-2 rounded">
                    <i className="bi bi-tags me-2"></i>Status
                  </h6>
                  <div className="table-responsive">
                    <div className="d-none">{(counter = 0)}</div>
                    <table className="table table-sm align-middle">
                      <tbody>
                        {getCustomerByStatus.map((status, index) => {
                          if (status.CountOfStatus != null) {
                            counter = counter + status.CountOfStatus;
                          }
                          return (
                            <tr key={index}>
                              <td className="fw-light">{status.name}</td>
                              <td className="text-end font-monospace">
                                {AppFormater.formatNumber(status.CountOfStatus)}
                              </td>
                            </tr>
                          );
                        })}
                        {parseInt(getCustomers) - counter > 0 ? (
                          <tr>
                            <td className="fw-light text-danger">Undefined</td>
                            <td className="text-end font-monospace text-danger">
                              {AppFormater.formatNumber(
                                parseInt(getCustomers) - counter
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(getCustomers)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-body small fst-italic fw-lighter mt-0 py-0 pe-0 text-end text-wrap">
                    Registered in the database
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-warning-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-warning-subtle p-2 rounded">
                    <i className="bi bi-gender-ambiguous me-2"></i>Gender
                  </h6>
                  <div className="table-responsive">
                    <div className="d-none">{(counter = 0)}</div>
                    <table className="table table-sm align-middle">
                      <tbody>
                        {getCustomerByGender.map((gender, index) => {
                          if (gender.CountOfGender != null) {
                            counter = counter + gender.CountOfGender;
                          }
                          return (
                            <tr key={index}>
                              <td className="fw-light">{gender.name}</td>
                              <td className="text-end font-monospace">
                                {AppFormater.formatNumber(gender.CountOfGender)}
                              </td>
                            </tr>
                          );
                        })}
                        {parseInt(getCustomers) - counter > 0 ? (
                          <tr>
                            <td className="fw-light text-danger">Undefined</td>
                            <td className="text-end font-monospace text-danger">
                              {AppFormater.formatNumber(
                                parseInt(getCustomers) - counter
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(getCustomers)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-body small fst-italic fw-lighter mt-0 py-0 pe-0 text-end text-wrap">
                    Registered in the database
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-danger-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-danger-subtle p-2 rounded">
                    <i className="bi bi-sliders me-2"></i>Age
                  </h6>
                  <div className="table-responsive">
                    <div className="d-none">{(counter = 0)}</div>
                    <table className="table table-sm align-middle">
                      <tbody>
                        {getCustomerByRangeOfAges.map((rangeAge, index) => {
                          if (rangeAge.CountOfAge != null) {
                            counter = counter + rangeAge.CountOfAge;
                          }
                          return (
                            <tr key={index}>
                              <td className="fw-light">{rangeAge.name}</td>
                              <td className="text-end font-monospace">
                                {AppFormater.formatNumber(rangeAge.CountOfAge)}
                              </td>
                            </tr>
                          );
                        })}
                        {parseInt(getCustomers) - counter > 0 ? (
                          <tr>
                            <td className="fw-light text-danger">Undefined</td>
                            <td className="text-end font-monospace text-danger">
                              {AppFormater.formatNumber(
                                parseInt(getCustomers) - counter
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(getCustomers)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-body small fst-italic fw-lighter mt-0 py-0 pe-0 text-end text-wrap">
                    Registered in the database
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-success-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-success-subtle p-2 rounded">
                    <i className="bi bi-sliders me-2"></i>Religion
                  </h6>
                  <div className="table-responsive">
                    <div className="d-none">{(counter = 0)}</div>
                    <table className="table table-sm align-middle">
                      <tbody>
                        {getCustomerByReligion.map((religion, index) => {
                          if (religion.CountOfReligion != null) {
                            counter = counter + religion.CountOfReligion;
                          }
                          return (
                            <tr key={index}>
                              <td className="fw-light">{religion.name}</td>
                              <td className="text-end font-monospace">
                                {AppFormater.formatNumber(
                                  religion.CountOfReligion
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        {parseInt(getCustomers) - counter > 0 ? (
                          <tr>
                            <td className="fw-light text-danger">Undefined</td>
                            <td className="text-end font-monospace text-danger">
                              {AppFormater.formatNumber(
                                parseInt(getCustomers) - counter
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(getCustomers)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-body small fst-italic fw-lighter mt-0 py-0 pe-0 text-end text-wrap">
                    Registered in the database
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-info-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-info-subtle p-2 rounded">
                    <i className="bi bi-sliders me-2"></i>Marital
                  </h6>
                  <div className="table-responsive">
                    <div className="d-none">{(counter = 0)}</div>
                    <table className="table table-sm align-middle">
                      <tbody>
                        {getCustomerByMarital.map((marital, index) => {
                          if (marital.CountOfMarital != null) {
                            counter = counter + marital.CountOfMarital;
                          }
                          return (
                            <tr key={index}>
                              <td className="fw-light">{marital.name}</td>
                              <td className="text-end font-monospace">
                                {AppFormater.formatNumber(
                                  marital.CountOfMarital
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        {parseInt(getCustomers) - counter > 0 ? (
                          <tr>
                            <td className="fw-light text-danger">Undefined</td>
                            <td className="text-end font-monospace text-danger">
                              {AppFormater.formatNumber(
                                parseInt(getCustomers) - counter
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(getCustomers)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-body small fst-italic fw-lighter mt-0 py-0 pe-0 text-end text-wrap">
                    Registered in the database
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-dark-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-dark-subtle p-2 rounded">
                    <i className="bi bi-sliders me-2"></i>Blood Type
                  </h6>
                  <div className="table-responsive">
                    <div className="d-none">{(counter = 0)}</div>
                    <table className="table table-sm align-middle">
                      <tbody>
                        {getCustomerByBloodType.map((bloodType, index) => {
                          if (bloodType.CountOfBloodType != null) {
                            counter = counter + bloodType.CountOfBloodType;
                          }
                          return (
                            <tr key={index}>
                              <td className="fw-light">{bloodType.name}</td>
                              <td className="text-end font-monospace">
                                {AppFormater.formatNumber(
                                  bloodType.CountOfBloodType
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        {parseInt(getCustomers) - counter > 0 ? (
                          <tr>
                            <td className="fw-light text-danger">Undefined</td>
                            <td className="text-end font-monospace text-danger">
                              {AppFormater.formatNumber(
                                parseInt(getCustomers) - counter
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(getCustomers)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-body small fst-italic fw-lighter mt-0 py-0 pe-0 text-end text-wrap">
                    Registered in the database
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-1">
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 bg-light border-secondary-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-secondary-subtle p-2 rounded">
                    <i className="bi bi-globe me-2"></i>Nationality
                  </h6>
                  <div className="d-none">{(counter = 0)}</div>
                  <div className="d-flex flex-wrap">
                    {getCustomerByNationality.map((nationality, index) => {
                      if (nationality.CountOfNationality != null) {
                        counter = counter + nationality.CountOfNationality;
                      }
                      return (
                        <div className="p-1" key={index}>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                          >
                            {nationality.name}
                            <span className="badge text-bg-light ms-1 font-monospace">
                              {AppFormater.formatNumber(
                                nationality.CountOfNationality
                              )}
                            </span>
                          </button>
                        </div>
                      );
                    })}
                    {parseInt(getCustomers) - counter > 0 ? (
                      <div className="p-1" key="-1">
                        <button type="button" className="btn btn-sm btn-danger">
                          Undefined
                          <span className="badge text-bg-light ms-1 font-monospace">
                            {AppFormater.formatNumber(
                              parseInt(getCustomers) - counter
                            )}
                          </span>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

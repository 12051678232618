import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import empty from "../Components/empty.jpg";
import { API, Storage } from ".././Config/Config";
import Table from "./TableVendor";

export default function DataTableVendor() {
  const api = API;

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [listVendor, setListVendor] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchVendor = useCallback(async () => {
    await axios
      .get(api + `/vendor/list`, header)
      .then(function (response) {
        setListVendor(response.data.data);
        // console.log(response.data.data);
        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [api, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(api + `/vendor/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchVendor();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, fetchVendor, header]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: (d) => `${d.id}`,
            disableSortby: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/vendor/edit/${value}`} className="btn btn-sm btn-primary m-1">
                    <i className="bi bi-pencil-fill"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "PIC",
            accessor: "vendor_pic",
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    fetchVendor();
  }, [fetchVendor]);

  if (!isLoaded) {
    return (
      <>
        <>
          <div className="d-flex justify-content-center align-items-center align-self-center" style={{ marginTop: "10rem" }}>
            <button className="btn btn-primary px-5 py-3" type="button" disabled>
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              &nbsp; Loading Vendor . . .
            </button>
          </div>
        </>
      </>
    );
  }

  return (
    <>
      {listVendor.length < 1 ? (
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-5 fw-bold text-body-emphasis">Data not found</h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">Vendor data not found, to start filling in new Vendor data, please click the add new button at the top right of your screen.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid table-responsive p-0 mb-5">
          <Table columns={columns} data={listVendor} />
        </div>
      )}
    </>
  );
}

import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { API } from ".././Config/Config";
import AppDateFormatter from "../Components/AppDateFormatter";

import Table from "./TableCustomerTransaction";

export default function DataTableCustomerTransaction() {
  const apiUrl = API;

  const { id } = useParams();

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [transaction, setTransaction] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchTransaction = useCallback(async () => {
    await axios.get(apiUrl + `/customers/listTransactions/${id}`, header).then(function (response) {
      setTransaction(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header, id]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: (d) => `${d.data.id}`,
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/transaction-legal/detail/${value}`} className="btn btn-sm btn-primary m-1">
                    <i className="bi bi-file-earmark-text-fill"></i>
                  </Link>
                  {/* <Link to="#" className="btn btn-sm btn-danger m-1">
                    <i className="bi bi-trash-fill"></i>
                  </Link> */}
                </>
              );
            },
          },
          {
            Header: "Number",
            accessor: "data.trans_number",
          },
          {
            Header: "Date",
            accessor: "data.trans_date",
            Cell: ({ cell: { value } }) => {
              return <p>{AppDateFormatter.beautyDateTime(value)}</p>;
            },
          },
          {
            Header: "Status",
            accessor: "data[trans_legal_visa_log_latest][0].description",
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    fetchTransaction();
  }, [fetchTransaction]);

  if (!isLoaded) {
    return (
      <>
        <>
          <div className="d-flex justify-content-center align-items-center align-self-center" style={{ marginTop: "10rem" }}>
            <button className="btn btn-primary px-5 py-3" type="button" disabled>
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              &nbsp; Loading Transaction . . .
            </button>
          </div>
        </>
      </>
    );
  }

  if (transaction.length < 1) {
    // if (transaction === null || transaction === undefined) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-6 fw-bold text-body-emphasis">Data not found</h1>
            <div className="col-12 mx-auto">
              <p className="fw-light mb-4">Customer Transactions is empty.</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={transaction} />
    </div>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
import AppSpinner from "../Components/AppSpinner";
import Swal from "sweetalert2";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API, Storage } from ".././Config/Config";
import empty from "../Components/empty.jpg";

export default function EditVendor() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;
  const navigate = useNavigate();
  const { id } = useParams();

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Vendors", slug: "/vendor", status: "" },
    {
      id: "3",
      title: "Detail Vendors",
      slug: "/vendor/detail/" + id,
      status: "",
    },
    {
      id: "4",
      title: "Edit Vendor",
      slug: "/vendor/edit",
      status: "active",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [listCountry, setListCountry] = useState([]);
  const [listState, setListState] = useState([]);
  const [listCity, setListCity] = useState([]);

  const fetchCountry = useCallback(async () => {
    await axios
      .get(api + "/vendor/countries", header)
      .then(function (response) {
        setListCountry(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [api, header]);

  const changeState = useCallback(
    async (items) => {
      setAddressCountry(items.target.value);
      await axios
        .get(api + `/vendor/states?country=${items.target.value}`, header)
        .then(function (response) {
          setListState(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [api, header]
  );

  const changeCity = useCallback(
    async (items) => {
      setAddressState(items.target.value);
      await axios
        .get(api + `/vendor/cities?state=${items.target.value}`, header)
        .then(function (response) {
          setListCity(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [api, header]
  );

  const [image, setImage] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const [pict, setPict] = useState([]);
  const [name, setName] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pic, setPIC] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    if (image.length < 1) return;
    const newImageUrls = [];
    image.forEach((images) => newImageUrls.push(URL.createObjectURL(images)));
    setPreviewImage(newImageUrls);
  }, [image]);

  function imageOnChange(e) {
    setImage([...e.target.files]);
  }

  const getDataVendor = useCallback(async () => {
    await axios
      .get(api + `/vendor/data/${id}`, header)
      .then(function (response) {
        setIsLoading(true);
        let vendor = response.data.data;

        setPict(vendor.image);
        setName(vendor.name);
        setAddressName(vendor.address_name);
        setAddressStreet(vendor.address_street);
        setAddressCity(vendor.address_city);
        setAddressState(vendor.address_state);
        setAddressCountry(vendor.address_country);
        setAddressZipCode(vendor.address_zip_code);
        setEmail(vendor.email);
        setPhone(vendor.phone);
        setPIC(vendor.vendor_pic);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [api, header, id]);

  const getSelectedState = useCallback(async () => {
    await axios
      .get(api + `/vendor/states?country=${addressCountry}`, header)
      .then(function (response) {
        setListState(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [addressCountry, api, header]);

  const getSelectedCity = useCallback(async () => {
    await axios
      .get(api + `/vendor/cities?state=${addressState}`, header)
      .then(function (response) {
        setListCity(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [addressState, api, header]);

  const saveVendor = async (e) => {
    e.preventDefault();
    setValidation([]);

    const formData = new FormData();

    formData.append("image", image[0]);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("vendor_pic", pic);
    formData.append("address_name", addressName);
    formData.append("address_street", addressStreet);
    formData.append("address_city", addressCity);
    formData.append("address_state", addressState);
    formData.append("address_country", addressCountry);
    formData.append("address_zip_code", addressZipCode);

    // console.log(...formData);

    Swal.fire({
      title: "Saving Vendor data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/vendor/update/${id}`, formData, header)
      .then(function (response) {
        if (response.data.status === "success") {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          navigate("/vendor");
        } else if (response.data.status === "error") {
          // console.log(response.data);
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCountry();
    getDataVendor();
    getSelectedState();
    getSelectedCity();
  }, [fetchCountry, getDataVendor, getSelectedState, getSelectedCity]);

  if (!isLoading) {
    return (
      <>
        <AppHeader />
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <AppSpinner title="Loading data vendor ..." />
        </div>
        <AppFooter />
      </>
    );
  }

  return (
    <>
      <AppHeader />
      <div className="d-flex flex-row border-bottom p-2 px-3">
        <div className="flex-grow-1 mt-1 small">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
        <div>
          <Link
            className="btn btn-sm btn-primary"
            to={`/vendor/detail/${id}`}
            role="button"
          >
            <i className="bi bi-arrow-left me-2"></i>
            <span className="">Back</span>
          </Link>
        </div>
      </div>
      <div className="container pt-5 mb-5 px-1">
        <form onSubmit={saveVendor}>
          <div className="row g-4">
            {pict ? (
              <img
                className="img-fluid img-thumbnail"
                alt="profile"
                src={Storage + `/vendor/image/${id}/${pict}`}
                style={{
                  display: image.length < 1 ? "block" : "none",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxHeight: "10rem",
                  maxWidth: "10rem",
                  borderRadius: "20%",
                  marginBottom: "1rem",
                }}
              />
            ) : (
              <img
                className="img-fluid img-thumbnail"
                alt="profile"
                src={empty}
                style={{
                  display: image.length < 1 ? "block" : "none",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxHeight: "10rem",
                  maxWidth: "10rem",
                  borderRadius: "20%",
                  marginBottom: "1rem",
                }}
              />
            )}
            {previewImage.map((imageSrc, id) => (
              <img
                className="img-fluid img-thumbnail"
                alt="profile"
                key={id}
                src={imageSrc}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxHeight: "10rem",
                  maxWidth: "10rem",
                  borderRadius: "20%",
                  marginBottom: "1rem",
                }}
              />
            ))}
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">
                  Image
                </label>
                <input
                  className={`form-control ${
                    validation.image ? "is-invalid" : ""
                  }`}
                  type="file"
                  id="formFile"
                  accept="image/*"
                  onChange={imageOnChange}
                />
                {validation.image ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.image[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    validation.name ? "is-invalid" : ""
                  }`}
                  id="floatingInputGrid"
                  value={name}
                  placeholder="name@example.com"
                  onChange={(e) => setName(e.target.value)}
                />
                <label htmlFor="floatingInputGrid">Name</label>
                {validation.name ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.name[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input
                  type="email"
                  className={`form-control ${
                    validation.email ? "is-invalid" : ""
                  }`}
                  id="floatingInputGrid"
                  value={email}
                  placeholder="name@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="floatingInputGrid">Email Address</label>
                {validation.email ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.email[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    validation.phone ? "is-invalid" : ""
                  }`}
                  id="floatingInputGrid"
                  value={phone}
                  placeholder="name@example.com"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label htmlFor="floatingInputGrid">Phone Number</label>
                {validation.phone ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.phone[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    validation.vendor_pic ? "is-invalid" : ""
                  }`}
                  id="floatingInputGrid"
                  value={pic}
                  placeholder="name@example.com"
                  onChange={(e) => setPIC(e.target.value)}
                />
                <label htmlFor="floatingInputGrid">Vendor PIC</label>
                {validation.vendor_pic ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.vendor_pic[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    validation.address_name ? "is-invalid" : ""
                  }`}
                  id="floatingInputGrid"
                  value={addressName}
                  placeholder="name@example.com"
                  onChange={(e) => setAddressName(e.target.value)}
                />
                <label htmlFor="floatingInputGrid">Address Name</label>
                {validation.address_name ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.address_name[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    validation.address_street ? "is-invalid" : ""
                  }`}
                  id="floatingInputGrid"
                  value={addressStreet}
                  placeholder="name@example.com"
                  onChange={(e) => setAddressStreet(e.target.value)}
                />
                <label htmlFor="floatingInputGrid">Address Street</label>
                {validation.address_street ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.address_street[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-floating">
                <select
                  className={`form-select ${
                    validation.address_country ? "is-invalid" : ""
                  }`}
                  value={addressCountry}
                  id="floatingSelectGrid"
                  onChange={changeState}
                >
                  <option disabled>Choose Country ...</option>
                  {listCountry.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelectGrid">Country</label>
                {validation.address_country ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.address_country[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-floating">
                <select
                  className={`form-select ${
                    validation.address_state ? "is-invalid" : ""
                  }`}
                  value={addressState}
                  id="floatingSelectGrid"
                  onChange={changeCity}
                >
                  <option disabled>Choose State ...</option>
                  {listState.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelectGrid">State</label>
                {validation.address_state ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.address_state[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-floating">
                <select
                  className={`form-select ${
                    validation.address_city ? "is-invalid" : ""
                  }`}
                  value={addressCity}
                  id="floatingSelectGrid"
                  onChange={(e) => setAddressCity(e.target.value)}
                >
                  <option disabled>Choose City ...</option>
                  {listCity.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelectGrid">City</label>
                {validation.address_city ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.address_city[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    validation.address_zip_code ? "is-invalid" : ""
                  }`}
                  value={addressZipCode}
                  id="floatingInputGrid"
                  placeholder="name@example.com"
                  onChange={(e) => setAddressZipCode(e.target.value)}
                />
                <label htmlFor="floatingInputGrid">ZIP Code</label>
                {validation.address_zip_code ? (
                  <div className="alert alert-danger small p-1 px-2 mt-1">
                    {validation.address_zip_code[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col">
              <button type="submit" className="btn btn-primary px-4 float-end">
                <i className="bi bi-save"></i> &nbsp; Save
              </button>
            </div>
          </div>
        </form>
      </div>
      <AppFooter />
    </>
  );
}

import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import AppSpinner from "../../Components/AppSpinner";
import Table from "./TableEula";
import { API } from "../../Config/Config";

export default function DataTableEula() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const [success, setSuccess] = useState(true);
  const [eula, setEula] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const fetchEula = useCallback(async () => {
    await axios.get(api + "/eula-template/list", header).then((response) => {
      setEula(response.data.data);
      setIsLoaded(true);
    });
  }, [header, api]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Title",
            accessor: "data.title",
          },
          {
            Header: "Version",
            accessor: "data.version",
          },
          {
            Header: "Status",
            accessor: "data.status",
            Cell: ({ cell: { value } }) => {
              return value === 1 ? <p>Active</p> : <p>Not Active</p>;
            },
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchEula();
  }, [fetchEula, token]);

  if (!isLoaded) {
    return <AppSpinner title="Loading Data . . ." />;
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={eula} />
    </div>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate, Link } from "react-router-dom";
import AppHeader from "./../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
// import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API } from ".././Config/Config";

function CreateState() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //get api from local storage
  const apiUrl = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "States", slug: "/state", status: "" },
    { id: "3", title: "Create States", slug: "/state/create", status: "active" },
  ];

  const [success, setSuccess] = useState(true);

  const [country, setCountry] = useState("");
  const [name, setName] = useState("");
  const [validation, setValidation] = useState([]);

  const [listCountry, setListCountry] = useState([]);

  const fetchCountry = useCallback(async () => {
    await axios.get(apiUrl + "/countries/list", header).then((response) => {
      setListCountry(response.data.data);
    });
  }, [apiUrl, header]);

  const saveState = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("country", country);
    formData.append("name", name);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await axios.post(apiUrl + "/states/create", formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchCountry();
  }, [token, fetchCountry]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/state" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form onSubmit={saveState}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                {validation.errMessage && (
                  <div className={`alert alert-danger ${validation.errMessage ? "d-block" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle"></i>
                    {validation.errMessage[0]}
                  </div>
                )}
                <div className="form-floating mb-3">
                  <select className="form-select" value={country} onChange={(e) => setCountry(e.target.value)} id="floatingSelect" aria-label="Floating label select example">
                    <option>Select Country</option>
                    {listCountry.map((data, id) => (
                      <option key={id} value={data.data.id}>
                        {data.data.name}
                      </option>
                    ))}
                  </select>
                  {validation.country && <div className="alert alert-danger">{validation.country[0]}</div>}
                  <label htmlFor="floatingSelect">
                    Country <sup className="text-danger">*</sup>
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Name <sup className="text-danger">*</sup>
                  </label>
                  {validation.name && <div className="alert alert-danger">{validation.name[0]}</div>}
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-2">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/state" replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}
export default CreateState;

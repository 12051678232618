import React, { useState, useMemo } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
} from "react-table";
import { useNavigate } from "react-router-dom";

export default function TableCustomer({ columns, data }) {
  const navigate = useNavigate();
  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 750);

    return (
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records ...`}
      ></input>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        placeholder={`Search ${count} records ...`}
      ></input>
    );
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    // state: { pageIndex, pageSize },
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: ["Action"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="container-fluid">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            Data Filter:
          </span>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <table {...getTableProps()} className="table table-hover mt-3">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((columns) => {
                  if (columns.Header === "Customer Lists") {
                    return (
                      <th
                        className="d-md-none d-sm-table-cell"
                        {...columns.getHeaderProps(
                          columns.getSortByToggleProps()
                        )}
                      >
                        {columns.render("Header")}
                        <span>
                          {columns.isSorted ? (
                            columns.isSortedDesc ? (
                              <i className="bi bi-arrow-down text-muted ms-1 small"></i>
                            ) : (
                              <i className="bi bi-arrow-up text-muted ms-1 small"></i>
                            )
                          ) : columns.disableSortBy === true ? null : (
                            <i className="bi bi-arrow-down-up text-muted ms-1 small"></i>
                          )}
                        </span>
                      </th>
                    );
                  } else {
                    return columns.hideHeader === false ? null : (
                      <th
                        className="d-none d-sm-table-cell"
                        {...columns.getHeaderProps(
                          columns.getSortByToggleProps()
                        )}
                      >
                        {columns.render("Header")}
                        <span>
                          {columns.isSorted ? (
                            columns.isSortedDesc ? (
                              <i className="bi bi-arrow-down text-muted ms-1 small"></i>
                            ) : (
                              <i className="bi bi-arrow-up text-muted ms-1 small"></i>
                            )
                          ) : columns.disableSortBy === true ? null : (
                            <i className="bi bi-arrow-down-up text-muted ms-1 small"></i>
                          )}
                        </span>
                      </th>
                    );
                  }
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    navigate(`/customers/details/${row.values.Action}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {row.cells.map((cell) => {
                    if (cell.column.Header === "Customer Lists") {
                      return (
                        <td
                          className="d-md-none d-sm-table-cell"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          className="d-none d-sm-table-cell"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex p-0 m-0 align-items-center justify-content-center mb-5">
          <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm p-0 m-0">
              <li
                className="page-item"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <a className="page-link px-3" href="#!">
                  &laquo;&laquo;
                </a>
              </li>
              <li
                className="page-item"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <a className="page-link px-3" href="#!">
                  &laquo;
                </a>
              </li>
              <li className="page-item">
                <a className="page-link px-3" href="#!">
                  Page {pageIndex + 1} of {pageOptions.length}
                </a>
              </li>
              <li
                className="page-item"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <a className="page-link px-3" href="#!">
                  &raquo;
                </a>
              </li>
              <li
                className="page-item"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <a className="page-link px-3" href="#!">
                  &raquo;&raquo;
                </a>
              </li>
            </ul>
          </nav>
          {/* <nav aria-label="Page navigation example">
            <ul className="pagination p-0 m-0">
              <li className="me-3">
                <a className="page-link" href="#!">
                  <input
                    className="form-control"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "100px", height: "20px" }}
                  />
                </a>
              </li>{" "}
              <select
                className="form-control"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{ width: "120px", height: "38px" }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </ul>
          </nav> */}
        </div>
      </div>
    </>
  );
}

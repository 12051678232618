import React, { useCallback, useState, useMemo, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { API } from "../Config/Config";

export default function CreateUser() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;
  const navigate = useNavigate();

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "User", slug: "/user", status: "" },
    { id: "3", title: "Create User", slug: "/user/create", status: "active" },
  ];

  const [listRoles, setListRoles] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validation, setValidation] = useState([]);

  const fetchRoles = useCallback(async () => {
    await axios
      .get(api + `/users/list_roles`, header)
      .then(function (response) {
        setListRoles(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [api, header]);

  const saveUser = async (e) => {
    e.preventDefault();
    setValidation([]);

    const formData = new FormData();

    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("confirm_password", confirmPassword);
    formData.append("roles", roles);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + "/users/create", formData, header)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          navigate("/user");
        } else if (response.data.status === "error") {
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
          setValidation(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return (
    <>
      <AppHeader />
      <div className="d-flex flex-row align-items-center align-self-center ps-4 pe-4 pt-2 pb-3 border-bottom mb-3">
        <div className="flex-grow-1">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
        <div>
          <a className="btn btn-primary" href="/user" role="button">
            <span className="me-2">
              <i className="bi bi-arrow-left"></i>
            </span>
            Back
          </a>
        </div>
      </div>
      <div className="container-fluid mb-5">
        <form className="p-3" onSubmit={saveUser}>
          <div className="alert alert-info" role="alert">
            <i className="bi bi-exclamation-circle-fill"></i> Sign <span className="text-danger">*</span> is a mandatory data
          </div>
          <div className="row">
            <div className="col">
              <div className="form-floating mb-3">
                <input type="text" className={`form-control ${validation.first_name ? "is-invalid" : ""}`} value={firstName} onChange={(e) => setFirstName(e.target.value)} id="floatingFirstName" placeholder="First Name" />
                <label htmlFor="floatingFirstName">
                  <span className="text-danger">*</span> First Name
                </label>
                {validation.first_name && (
                  <div className={`invalid-feedback ${validation.first_name ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.first_name[0]}
                  </div>
                )}
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <input type="text" className={`form-control ${validation.last_name ? "is-invalid" : ""}`} value={lastName} onChange={(e) => setLastName(e.target.value)} id="floatingLastName" placeholder="First Name" />
                <label htmlFor="floatingLastName">
                  <span className="text-danger">*</span> Last Name
                </label>
                {validation.last_name && (
                  <div className={`invalid-feedback ${validation.last_name ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.last_name[0]}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-floating mb-3">
            <input type="email" className={`form-control ${validation.email ? "is-invalid" : ""}`} value={email} onChange={(e) => setEmail(e.target.value)} id="floatingEmail" placeholder="First Name" />
            <label htmlFor="floatingEmail">
              <span className="text-danger">*</span> Email
            </label>
            {validation.email && (
              <div className={`invalid-feedback ${validation.email ? "d-block mb-3" : "d-none"}`}>
                <i className="bi bi-exclamation-circle me-2"></i>
                {validation.email[0]}
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <div className="form-floating mb-3">
                <input type="password" className={`form-control ${validation.password ? "is-invalid" : ""}`} value={password} onChange={(e) => setPassword(e.target.value)} id="floatingPassword" placeholder="Password" />
                <label htmlFor="floatingPassword">
                  <span className="text-danger">*</span> Password
                </label>
                {validation.password && (
                  <div className={`invalid-feedback ${validation.password ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.password[0]}
                  </div>
                )}
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className={`form-control ${validation.confirm_password ? "is-invalid" : ""}`}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  id="floatingConfirmPassword"
                  placeholder="Password"
                />
                <label htmlFor="floatingConfirmPassword">
                  <span className="text-danger">*</span> Confirm Password
                </label>
                {validation.confirm_password && (
                  <div className={`invalid-feedback ${validation.confirm_password ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.confirm_password[0]}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-floating mb-3">
            <select className={`form-control ${validation.roles ? "is-invalid" : ""}`} value={roles} onChange={(e) => setRoles(e.target.value)} id="floatingSelect" aria-label="Floating label select example">
              <option>Select Roles</option>
              {listRoles.map((data, index) => (
                <option key={index} value={data.id}>
                  {data.name}
                </option>
              ))}
            </select>
            <label htmlFor="floatingSelect">
              <span className="text-danger">*</span> Roles
            </label>
            {validation.roles && (
              <div className={`invalid-feedback ${validation.roles ? "d-block mb-3" : "d-none"}`}>
                <i className="bi bi-exclamation-circle me-2"></i> {validation.roles[0]}
              </div>
            )}
          </div>
          <button type="submit" className="btn btn-primary">
            <i className="bi bi-save"></i> Save
          </button>
        </form>
      </div>
      <AppFooter />
    </>
  );
}

import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { API } from ".././Config/Config";
import AppSpinner from "../Components/AppSpinner";
import ModalCustomerAddress from "./ModalCustomerAddress";
import Table from "./TableCustomerAddress";

export default function DataTableCustomerAddress() {
  // --- BEGIN | General --- //
  const apiUrl = API;
  const { id } = useParams();

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [getListAddress, setListAddress] = useState([]);
  const [getIsLoaded, setIsLoaded] = useState(false);
  const [getAddressID, setAddressID] = useState("");
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const onShowModalEdit = (items) => {
    setShowModalEdit(true);
    setAddressID(items);
  };

  const onCloseModalEdit = () => {
    setShowModalEdit(false);
    setAddressID("");
  };
  // --- END | General --- //

  // --- BEGIN | Fetch customer address --- //
  const fetchAddress = useCallback(async () => {
    await axios
      .get(apiUrl + `/customers/address/list/${id}`, header)
      .then(function (response) {
        setIsLoaded(true);
        setListAddress(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [apiUrl, header, id]);
  // --- END | Fetch customer address --- //

  // --- BEGIN | Delete customer address --- //
  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Delete address ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(apiUrl + `/customers/address/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchAddress();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, fetchAddress, header]
  );
  // --- END | Delete customer address --- //

  // -- Begin Setup Table -- //

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link
                    to="#"
                    className="btn btn-sm btn-primary m-1"
                    onClick={() => {
                      onShowModalEdit(value);
                    }}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-sm btn-danger m-1"
                    onClick={() => handleDelete(value)}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "data.address_name",
          },
          {
            Header: "Street",
            accessor: "data.address_street",
          },
          {
            Header: "City",
            accessor: "data.city.name",
          },
          {
            Header: "State",
            accessor: "data.state.name",
          },
          {
            Header: "Country",
            accessor: "data.country.name",
          },
          {
            Header: "Zip Code",
            accessor: "data.address_zip_code",
            Cell: ({ cell: { value } }) => {
              return value === null ? <p>-</p> : <p>{value}</p>;
            },
          },
        ],
      },
    ],
    [handleDelete]
  );
  // --- END | Setup Tables --- //

  useEffect(() => {
    fetchAddress();
  }, [fetchAddress]);

  if (!getIsLoaded) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <AppSpinner title="Loading data customer address(es) ..." />
        </div>
      </>
    );
  }

  if (getListAddress.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-6 fw-bold text-body-emphasis">
              Data not found
            </h1>
            <div className="col-lg-6 mx-auto">
              <p className="fw-light mb-4">
                Customer address(es) not found, to start filling in new customer
                address, please click the add new button below.
              </p>
              <Button
                variant="primary"
                className="btn btn-sm px-3"
                onClick={() => setShowModalNew(true)}
              >
                <i className="bi bi-plus-circle"></i> Add New Address
              </Button>
            </div>
          </div>
        </div>

        <ModalCustomerAddress
          method="ADD"
          title="New Address"
          addressID="0"
          customerID={id}
          show={showModalNew}
          close={() => {
            fetchAddress();
            setShowModalNew(false);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-start p-0 m-0 pb-2">
        <Button
          variant="primary"
          className="btn btn-sm px-3"
          onClick={() => setShowModalNew(true)}
        >
          <i className="bi bi-plus-circle"></i> Add New Address
        </Button>
      </div>
      <div className="table-responsive p-0">
        <Table columns={columns} data={getListAddress} />
      </div>

      <ModalCustomerAddress
        method="ADD"
        title="New Address"
        addressID="0"
        customerID={id}
        show={showModalNew}
        close={() => {
          fetchAddress();
          setShowModalNew(false);
        }}
      />

      <ModalCustomerAddress
        method="EDIT"
        title="Edit Address"
        addressID={getAddressID}
        show={showModalEdit}
        close={() => {
          fetchAddress();
          onCloseModalEdit();
        }}
      />
    </>
  );
}

import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { API } from "../Config/Config";
import AppSpinner from "../Components/AppSpinner";
import Table from "./TableVendorAddress";
import ModalVendorAddress from "./ModalVendorAddress";

export default function DataTableVendorAddress() {
  // -- GENERAL SETTING -- //
  const api = API;
  const { id } = useParams();

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }, [token]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [addressID, setAddressID] = useState("");
  const [listAddress, setListAddress] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const onShowModalEdit = (items) => {
    setShowModalEdit(true);
    setAddressID(items);
  };

  const onCloseModalEdit = () => {
    setShowModalEdit(false);
    setAddressID("");
  };

  // -- END GENERAL SETTING --//

  // -- FETCH VENDOR ADDRESS -- //
  const fetchVendorAddress = useCallback(async () => {
    await axios
      .get(api + `/vendor/address/list/${id}`, header)
      .then(function (response) {
        setIsLoaded(true);
        setListAddress(response.data.data);
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [api, header, id]);
  // -- END FETCH VENDOR ADDRESS -- //

  // -- DELETE DATA VENDOR ADDRESS -- //
  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Delete data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(api + `/vendor/address/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchVendorAddress();
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, fetchVendorAddress, header]
  );
  // -- END DELETE DATA VENDOR ADDRESS -- //
  // -- SETUP DATA TABLE VENDOR ADDRESS -- //
  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link
                    to="#"
                    className="btn btn-sm btn-primary m-1"
                    onClick={() => {
                      onShowModalEdit(value);
                    }}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-sm btn-danger m-1"
                    onClick={() => handleDelete(value)}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "address_name",
          },
          {
            Header: "Street",
            accessor: "address_street",
          },
          {
            Header: "City",
            accessor: "city.name",
          },
          {
            Header: "State",
            accessor: "state.name",
          },
          {
            Header: "Country",
            accessor: "country.name",
          },
          {
            Header: "Zip Code",
            accessor: "address_zip_code",
          },
        ],
      },
    ],
    [handleDelete]
  );
  // -- END SETUP DATA TABLE VENDOR ADDRESS -- //
  useEffect(() => {
    fetchVendorAddress();
  }, [fetchVendorAddress]);

  if (!isLoaded) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <AppSpinner title="Loading data vendor address(es) ..." />
        </div>
      </>
    );
  }

  if (listAddress.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-6 fw-bold text-body-emphasis">
              Data not found
            </h1>
            <div className="col-lg-6 mx-auto">
              <p className="fw-light mb-4">
                Vendor address(es) not found, to start filling in new vendor
                address, please click the add new button below.
              </p>
              <button
                type="button"
                className="btn btn-sm btn-primary px-3"
                onClick={() => setShowModalNew(true)}
              >
                <i className="bi bi-plus-circle"></i> Add New Address
              </button>
            </div>
          </div>
        </div>

        <ModalVendorAddress
          method="ADD"
          title="New Address"
          addressID="0"
          vendorID={id}
          show={showModalNew}
          close={() => {
            setShowModalNew(false);
            fetchVendorAddress();
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end mt-3 mb-3">
        <button
          className="btn btn-sm btn-primary px-3"
          onClick={() => setShowModalNew(true)}
        >
          <i className="bi bi-plus-circle"></i> Add New Address
        </button>
      </div>
      <div className="table-responsive">
        <Table columns={columns} data={listAddress} />
      </div>

      <ModalVendorAddress
        method="ADD"
        title="New Address"
        addressID="0"
        vendorID={id}
        show={showModalNew}
        close={() => {
          setShowModalNew(false);
          fetchVendorAddress();
        }}
      />

      <ModalVendorAddress
        method="EDIT"
        title="Edit Address"
        addressID={addressID}
        vendorID="0"
        show={showModalEdit}
        close={() => {
          onCloseModalEdit();
          fetchVendorAddress();
        }}
      />
    </>
  );
}

import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { API } from ".././Config/Config";
import AppSpinner from "../Components/AppSpinner";
import ModalCustomerNotes from "./ModalCustomerNotes";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

import Table from "./TableCustomerNotes";
import Formater from "../Components/AppDateFormatter";

export default function DataTableCustomerNotes() {
  // --- BEGIN | General --- //
  const apiUrl = API;
  const { id } = useParams();

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);
  // --- END | General --- //

  const [getListNotes, setListNotes] = useState([]);
  const [getIsLoaded, setIsLoaded] = useState(false);
  const [getNotesID, setNotesID] = useState("");
  const [getModalNewForm, setModalNewForm] = useState(false);
  const [getModalEditForm, setModalEditForm] = useState(false);

  // --- BEGIN | Fetch customer notes --- //
  const fetchNotes = useCallback(async () => {
    await axios
      .get(apiUrl + `/customers/notes/list/${id}`, header)
      .then(function (response) {
        setListNotes(response.data.data);
        setIsLoaded(true);
      });
  }, [apiUrl, header, id]);
  // --- END | Fetch customer notes --- //

  // --- BEGIN | Load customer notes --- //
  const showNotesOnModal = (items) => {
    setNotesID(items);
    setModalEditForm(true);
  };

  const closeNotesModal = () => {
    setNotesID("");
    setModalEditForm(false);
  };
  // --- END | Load customer notes --- //

  // --- BEGIN | Delete customer notes --- //
  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Delete notes ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(apiUrl + `/customers/notes/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchNotes();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, fetchNotes, header]
  );
  // --- END | Delete customer notes --- //

  // --- BEGIN | Setup Tables --- //
  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            disableSortBy: true,
            accessor: "data.id",
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link
                    to="#"
                    className="btn btn-sm btn-primary m-1"
                    onClick={() => showNotesOnModal(value)}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-sm btn-danger m-1"
                    onClick={() => handleDelete(value)}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Notes",
            accessor: (d) => `${d.data.notes}`,
            Cell: ({ row }) => {
              return (
                <div className="my-2">
                  <p>{row.original.data.notes}</p>
                  <p className="small fw-light text-end m-0 p-0">
                    {"Created at "}
                    {Formater.beautyDateTime(row.original.data.created_at)}
                    {" | By "}
                    {row.original.data.created_user.first_name}{" "}
                    {row.original.data.created_user.last_name}
                  </p>
                  <p className="small fw-light text-end m-0 p-0">
                    {"Updated at "}
                    {Formater.beautyDateTime(row.original.data.updated_at)}
                    {" | By "}
                    {row.original.data.updated_user.first_name}{" "}
                    {row.original.data.updated_user.last_name}
                  </p>
                </div>
              );
            },
          },
        ],
      },
    ],
    [handleDelete]
  );
  // --- END | Setup Tables --- //

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  if (!getIsLoaded) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <AppSpinner title="Loading data customer notes ..." />
        </div>
      </>
    );
  }

  // console.log(getListNotes);
  if (getListNotes.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-6 fw-bold text-body-emphasis">
              Data not found
            </h1>
            <div className="col-lg-6 mx-auto">
              <p className="fw-light mb-4">
                Customer notes not found, to start filling in new customer
                notes, please click the add new button below.
              </p>
              <Button
                variant="primary"
                className="btn btn-sm px-3"
                onClick={() => setModalNewForm(true)}
              >
                <i className="bi bi-plus-circle"></i> Add New Notes
              </Button>
            </div>
          </div>
        </div>

        <ModalCustomerNotes
          method="ADD"
          title="New Notes"
          notesID="0"
          customerID={id}
          show={getModalNewForm}
          close={() => {
            setModalNewForm(false);
            fetchNotes();
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-start p-0 m-0 pb-2">
        <Button
          variant="primary"
          className="btn btn-sm px-3"
          onClick={() => setModalNewForm(true)}
        >
          <i className="bi bi-plus-circle"></i> Add New Notes
        </Button>
      </div>
      <div className="table-responsive p-0">
        <Table columns={columns} data={getListNotes} />
      </div>
      <ModalCustomerNotes
        method="ADD"
        title="New Notes"
        notesID="0"
        customerID={id}
        show={getModalNewForm}
        close={() => {
          setModalNewForm(false);
          fetchNotes();
        }}
      ></ModalCustomerNotes>

      <ModalCustomerNotes
        method="EDIT"
        title="Edit Notes"
        notesID={getNotesID}
        show={getModalEditForm}
        close={() => {
          closeNotesModal();
          fetchNotes();
        }}
      ></ModalCustomerNotes>
    </>
  );
}

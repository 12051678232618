import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import DataTableProductPrice from "./DataTableProductPrice";

export default function IndexProductPrice() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Product Price Visa", slug: "/product-price", status: "active" },
  ];

  let navigate = useNavigate();
  const addNewProductPrice = () => {
    navigate(`create`);
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
      // navigate("../login", { replace: true });
    }
  }, [token]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
          <div className="flex-grow-1 py-2">
            <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
          </div>
          <div className="py-1">
            <button type="button" className="btn btn-sm btn-primary px-2" onClick={addNewProductPrice}>
              <i className="bi bi-plus-circle"></i> Add New
            </button>
          </div>
        </div>
        <div className="container-fluid p-0 m-0 mb-5">
          <DataTableProductPrice />
        </div>
        <AppFooter />
      </div>
    </>
  );
}

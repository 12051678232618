import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import AppSpinner from "../Components/AppSpinner";
import Table from "./TableTransactionLegal";
import { API } from ".././Config/Config";
import Swal from "sweetalert2";
import AppDateFormatter from "../Components/AppDateFormatter";

export default function DataTableTransactionLegal() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const [success, setSuccess] = useState(true);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [transLegal, setTransLegal] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchTransLegal = useCallback(async () => {
    await axios.get(api + `/transaction-legal/list`, header).then((response) => {
      setTransLegal(response.data.data);
      // console.log(response.data.data);
      setIsLoaded(true);
    });
  }, [api, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(api + `/transaction-legal/visa/destroy/${id}`, header)
            .then(function (response) {
              if (response.data.status === "error") {
                Swal.fire({
                  icon: "error",
                  title: response.data.message,
                  timer: 1500,
                });
              } else if (response.data.status === "success") {
                Swal.fire({
                  icon: "success",
                  title: response.data.message,
                  timer: 1500,
                });
              }
              fetchTransLegal();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, fetchTransLegal, header]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/transaction-legal/detail/${value}`} className="btn btn-sm btn-primary m-1">
                    <i className="bi bi-file-earmark-text-fill"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: (d) => `${d.data["customer"].first_name} ${d.data["customer"].last_name}`,
          },
          {
            Header: "Number",
            accessor: "data.trans_number",
          },
          {
            Header: "Date",
            accessor: "data.trans_date",
            Cell: ({ cell: { value } }) => {
              return <p>{AppDateFormatter.beautyDateTime(value)}</p>;
            },
          },
          {
            Header: "Status",
            accessor: "data[trans_legal_visa_log_latest][0].description",
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchTransLegal();
  }, [token, fetchTransLegal]);

  if (!isLoaded) {
    return (
      <>
        <AppSpinner title="Loading data Transaction . . ." />
      </>
    );
  }

  if (transLegal.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center min-vh-100">
          <button className="btn btn-secondary px-5 py-3" type="button" disabled>
            Data Kosong . . .
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={transLegal} />
    </div>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import DataTableUser from "./DataTableUser";
import { useNavigate } from "react-router-dom";

export default function IndexUser() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "User", slug: "/user", status: "active" },
  ];

  let navigate = useNavigate();
  const addUser = () => {
    navigate("/user/create");
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }
  }, [token, success]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
          <div className="flex-grow-1 py-2">
            <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
          </div>
          <div className="py-1">
            <button type="button" className="btn btn-sm btn-primary px-2" onClick={addUser}>
              <i className="bi bi-plus-circle"></i> Add New
            </button>
          </div>
        </div>
        <div className="container-fluid p-0 m-0 mb-5">
          <DataTableUser />
        </div>
        <AppFooter />
      </div>
    </>
  );
}

// ---------- API Development -----------------------------//
// export const API = "http://localhost:8000/api";
// export const API = "http://192.168.1.37:8000/api";
// export const API = "http://192.168.1.45:8000/api";
// export const API = "http://192.168.1.213:8000/api";
// export const API = "http://192.168.135.6:8000/api";
// export const API = "http://192.168.1.9:8000/api";
// export const API = "http://192.168.135.47:8000/api";

// export const Storage = "http://localhost:8000/storage";
// export const Storage = "http://192.168.1.37:8000/storage";
// export const Storage = "http://192.168.1.45:8000/storage";
// export const Storage = "http://192.168.1.213:8000/storage";
// export const Storage = "http://192.168.135.6:8000/storage";
// export const Storage = "http://192.168.1.9:8000/storage";
// export const Storage = "http://192.168.135.47:8000/storage";

// ---------- End API Development ------------------------//

// ---------- API Production ------------------------------//
export const API = "https://maxxsco-api.maxxsgroup.com/api";
export const Storage = "https://maxxsco-api.maxxsgroup.com/storage";
// ---------- End API Production -------------------------//

// export const GlobalQR = "http://192.168.1.7:3001/";
export const GlobalQR = "http://192.168.135.6:3001/";

export const AUTH_MIDTRANS = "Basic U0ItTWlkLXNlcnZlci1jYnZZb3FTWjN2RWpsQlFOenJnYjVJMV86";

import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import { API } from ".././Config/Config";

import Table from "./TableParam";

export default function DataTableParam() {
  const apiUrl = API;

  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [parameter, setParameter] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchParameter = useCallback(async () => {
    await axios.get(apiUrl + "/parameters/list", header).then((response) => {
      setParameter(response.data.data);
      // console.log(response.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          axios
            .delete(apiUrl + `/parameters/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchParameter();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, fetchParameter, header]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/parameter/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Group",
            accessor: "group",
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Show",
            accessor: "show",
            Cell: ({ cell: { value } }) => {
              return <div>{value === 1 ? <p>Yes</p> : <p>No</p>}</div>;
            },
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchParameter();
  }, [token, fetchParameter]);

  if (!isLoaded) {
    return (
      <>
        <AppSpinner title="Loading data parameters . . ." />
      </>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={parameter} />
    </div>
  );
}

import React from "react";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import { Link } from "react-router-dom";
import pict1 from "./img/pict1.jpg";
import passpor from "./img/passpor.jpg";
import saving from "./img/saving.png";
import vaccine from "./img/vaccine.png";

export default function DetailRequest() {
  return (
    <>
      <AppHeader />
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card mb-5">
              <div className="card-body">
                <div className="row justify-content-around">
                  <div className="col-12">
                    <Link to="/detailTransaction" className="text-muted float-start" style={{ textDecoration: "none", fontSize: "0.9rem" }}>
                      <i className="bi bi-chevron-left"></i> Back
                    </Link>
                    <h6 className="card-title text-center">
                      <strong>Detail Request</strong>
                    </h6>
                  </div>
                  <hr className="mt-2" style={{ width: "98%" }} />
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Photo</td>
                      <td>
                        <img src={pict1} alt="" className="img-thumbnail" style={{ maxWidth: "6.5rem", maxHeight: "6.5rem" }} />
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Full Name</td>
                      <td>John Doe</td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>No Passport</td>
                      <td>092839230</td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Nationality</td>
                      <td>Indonesian</td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Address (According to ID)</td>
                      <td>Sunrise Street no. 14, Tulung Agung, Kediri, West Java</td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Current Address</td>
                      <td>Pangeran Antasari Street No. 22 Pejaten, South Jakarta</td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Passport</td>
                      <td>
                        <img src={passpor} alt="" className="mt-1" style={{ maxWidth: "10rem", maxHeight: "10rem" }} />
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Saving Bank Account</td>
                      <td>
                        <img src={saving} alt="" className="mt-1 rounded" style={{ maxWidth: "10rem", maxHeight: "10rem" }} />
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Vaccine Sertificate</td>
                      <td>
                        <img src={vaccine} alt="" className="mt-1 rounded" style={{ maxWidth: "10rem", maxHeight: "10rem" }} />
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Estimated Arrival</td>
                      <td>25 July, 2022</td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Airport Destination</td>
                      <td>Soekarno-Hatta International Airport</td>
                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div className="form-floating">
                  <textarea className="form-control" placeholder="Input notes here"></textarea>
                  <label htmlFor="floatingTextarea">Notes</label>
                </div>
                <br />
                <Link to="/detailTransaction" className="btn btn-primary">
                  Submit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
}

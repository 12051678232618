import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import AppHeader from "./../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
// import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API } from ".././Config/Config";

function EditCountry() {
  // get token
  // use this method to avoid react-hooks/exhaustive-deps
  // add token at useEffect
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //get api from local storage
  const apiUrl = API;

  const getHeader = useMemo(() => {
    return {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Countries", slug: "/country", status: "" },
    { id: "3", title: "Edit Countries", slug: "/country/edit", status: "active" },
  ];

  const { id } = useParams();
  const [success, setSuccess] = useState(true);
  const [name, setName] = useState("");
  const [show, setShow] = useState("");
  const [formData, setFormData] = useState("");
  const [validation, setValidation] = useState([]);

  const handleToggle = () => {
    setShow(!show);
    var data = !show ? 1 : 0;
    formData.append("show", data);
  };

  useEffect(() => {
    axios
      .get(apiUrl + `/countries/data/${id}`, getHeader)
      .then(function (response) {
        let countries = response.data.data.data;
        setName(countries.name);
        setShow(countries.show);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [apiUrl, id, getHeader]);

  const updateCountry = async (e) => {
    e.preventDefault();

    formData.append("name", name);

    const response = await axios.post(apiUrl + `/countries/update/${id}`, formData, getHeader);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }
    setFormData(new FormData());
  }, [token]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/country" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>{" "}
              </div>
            </div>
            <div className="px-4 pb-4">
              <form onSubmit={updateCountry}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} className={`form-control ${validation.name ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Name <sup className="text-danger">*</sup>
                  </label>
                  {validation.name && (
                    <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.name[0]}
                    </div>
                  )}
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input" onChange={handleToggle} value={show} checked={show} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Show
                  </label>
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-3">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/country" replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

export default EditCountry;

import axios from "axios";
import { useParams, Link, Navigate } from "react-router-dom";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppSpinner from "../../Components/AppSpinner";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { API } from "../../Config/Config";

export default function CreateAnotherDocuments() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const { visa } = useParams();
  const [success, setSuccess] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Transaction Legal", slug: "/transaction-legal", status: "" },
    { id: "3", title: "Detail Transaction", slug: `/transaction-legal/detail/${visa}`, status: "" },
    { id: "4", title: "Create Another Documents", slug: "/transaction-legal/detail/:id/create-another-documents", status: "active" },
  ];

  const [formModules, setFormModules] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const [listNationality, setListNationality] = useState([]);
  const [listVisaType, setListVisaType] = useState([]);
  const [listVisaPurpose, setListVisaPurpose] = useState([]);
  const [listTransport, setListTransport] = useState([]);
  const [listAirport, setListAirport] = useState([]);
  const [validation, setValidation] = useState([]);

  const [visaType, setVisaType] = useState("");
  const [visaPurpose, setVisaPurpose] = useState("");
  const [fullName, setFullName] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [nationality, setNationality] = useState("");
  const [addressID, setAddressID] = useState("");
  const [addressDomicile, setAddressDomicile] = useState("");
  const [filePhoto, setFilePhoto] = useState([]);
  const [fileIDCard, setFileIDCard] = useState([]);
  const [filePassport, setFilePassport] = useState([]);
  const [fileSavingAccount, setFileSavingAccount] = useState([]);
  const [fileVaccine, setFileVaccine] = useState([]);
  const [estArrival, setEstArrival] = useState("");
  const [transportType, setTrasportType] = useState("");
  const [airportDestination, setAirportDestination] = useState("");
  const [fileTicket, setFileTicket] = useState([]);
  const [vvipEscort, setVvipEscort] = useState("");

  const fetchFormModules = useCallback(async () => {
    await axios.get(api + `/transaction-legal/visa/form-visa`, header).then((response) => {
      setFormModules(response.data.data);
      // console.log(response.data.data);
      setIsLoaded(true);
    });
  }, [api, header]);

  const fetchParameter = useCallback(async () => {
    await axios.get(api + `/transaction-legal/visa/listParameter`, header).then((response) => {
      let nationality = response.data.data.nationality;
      let visa_type = response.data.data.visa_type;
      let visa_purpose = response.data.data.visa_purpose;
      let transport = response.data.data.transport_type;
      let airport = response.data.data.airport_destination;

      setListNationality(nationality);
      setListVisaType(visa_type);
      setListVisaPurpose(visa_purpose);
      setListTransport(transport);
      setListAirport(airport);
    });
  }, [api, header]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();

    formData.append("select_visa_type", visaType);
    formData.append("select_visa_purpose", visaPurpose);
    formData.append("tx_full_name", fullName);
    formData.append("tx_passport_number", passportNumber);
    formData.append("select_nationality", nationality);
    formData.append("tx_area_address_id", addressID);
    formData.append("tx_area_address_domicile", addressDomicile);
    formData.append("file_upload_photo", filePhoto);
    formData.append("file_id_card", fileIDCard);
    formData.append("file_upload_passport", filePassport);
    formData.append("file_saving_account", fileSavingAccount);
    formData.append("file_vaccine", fileVaccine);
    formData.append("date_est_arrival", estArrival);
    formData.append("select_transport_type", transportType);
    formData.append("select_airport_destination", airportDestination);
    formData.append("file_boarding_ticket", fileTicket);
    formData.append("check_vvip_escort", vvipEscort);

    // console.log(...formData);

    await axios
      .post(api + `/transaction-legal/visa/${visa}/add-another-documents`, formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          // console.log(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2500,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchFormModules();
    fetchParameter();
  }, [token, fetchFormModules, fetchParameter]);

  if (!isLoaded) {
    return (
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
          <div className="flex-grow-1 py-2">
            <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
          </div>
        </div>
        <AppSpinner title="Loading Form Modules . . ." />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/transaction-legal" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form encType="multipart/form-data" onSubmit={handleSubmit}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                <div className="row">
                  <div>
                    {formModules.map((data) => {
                      return data.type === "Text" ? (
                        <>
                          <div className="form-floating mb-3">
                            {data.slug === "tx_full_name" ? (
                              <>
                                <input
                                  type="text"
                                  className={`form-control ${validation.tx_full_name ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  defaultValue={fullName}
                                  onChange={(e) => setFullName(e.target.value)}
                                  id="floatingInput"
                                  placeholder="Full Name"
                                  required={data.required === 1 ? true : false}
                                />
                                {validation.tx_full_name && (
                                  <div className={`invalid-feedback ${validation.tx_full_name ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.tx_full_name[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "tx_passport_number" ? (
                              <>
                                <input
                                  type="text"
                                  className={`form-control ${validation.tx_passport_number ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setPassportNumber(e.target.value)}
                                  id="floatingInput"
                                  placeholder="Passport Number"
                                  required={data.required === 1 ? true : false}
                                />
                                {validation.tx_passport_number && (
                                  <div className={`invalid-feedback ${validation.tx_passport_number ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.tx_passport_number[0]}
                                  </div>
                                )}
                              </>
                            ) : null}
                            <label htmlFor="floatingInput">
                              {data.required === 1 ? <span className="text-danger">*</span> : ""} {data.caption}
                            </label>
                          </div>
                        </>
                      ) : data.type === "Text Area" ? (
                        <>
                          <div className="form-floating mb-3">
                            {data.slug === "tx_area_address_id" ? (
                              <>
                                <textarea
                                  className={`form-control ${validation.tx_area_address_id ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setAddressID(e.target.value)}
                                  id="floatingTextarea"
                                  placeholder="Address ID"
                                  style={{ height: "75px" }}
                                  required={data.required === 1 ? true : false}
                                ></textarea>
                                {validation.tx_area_address_id && (
                                  <div className={`invalid-feedback ${validation.tx_area_address_id ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.tx_area_address_id[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "tx_area_address_domicile" ? (
                              <>
                                <textarea
                                  className={`form-control ${validation.tx_area_address_domicile ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setAddressDomicile(e.target.value)}
                                  id="floatingTextarea"
                                  placeholder="Address Domicile"
                                  style={{ height: "75px" }}
                                  required={data.required === 1 ? true : false}
                                ></textarea>
                                {validation.tx_area_address_domicile && (
                                  <div className={`invalid-feedback ${validation.tx_area_address_domicile ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.tx_area_address_domicile[0]}
                                  </div>
                                )}
                              </>
                            ) : null}
                            <label htmlFor="floatingTextarea">
                              {data.required === 1 ? <span className="text-danger">*</span> : ""} {data.caption}
                            </label>
                          </div>
                        </>
                      ) : data.type === "Select" ? (
                        <>
                          <div className="form-floating mb-3">
                            {data.slug === "select_nationality" ? (
                              <>
                                <select
                                  className={`form-select ${validation.select_nationality ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setNationality(e.target.value)}
                                  id="floatingSelect"
                                  required={data.required === 1 ? true : false}
                                >
                                  <option value="">Select Nationality</option>
                                  {listNationality.map((value) => (
                                    <option value={value.id}>{value.name}</option>
                                  ))}
                                </select>
                                {validation.select_nationality && (
                                  <div className={`invalid-feedback ${validation.select_nationality ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.select_nationality[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "select_transport_type" ? (
                              <>
                                <select
                                  className={`form-select ${validation.select_transport_type ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setTrasportType(e.target.value)}
                                  id="floatingSelect"
                                  required={data.required === 1 ? true : false}
                                >
                                  <option value="">Select Transportation Type</option>
                                  {listTransport.map((value) => (
                                    <option value={value.id}>{value.name}</option>
                                  ))}
                                </select>
                                {validation.select_transport_type && (
                                  <div className={`invalid-feedback ${validation.select_transport_type ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.select_transport_type[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "select_airport_destination" ? (
                              <>
                                <select className="form-select" name={data.slug} onChange={(e) => setAirportDestination(e.target.value)} id="floatingSelect" required={data.required === 1 ? true : false}>
                                  <option value="">Select Airport Destination</option>
                                  {listAirport.map((value) => (
                                    <option value={value.id}>{value.name}</option>
                                  ))}
                                </select>
                              </>
                            ) : null}
                            <label htmlFor="floatingSelect">
                              {data.required === 1 ? <span className="text-danger">*</span> : ""} {data.caption}
                            </label>
                          </div>
                        </>
                      ) : data.type === "File" ? (
                        <>
                          <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                              {data.required === 1 ? <span className="text-danger">*</span> : ""} {data.caption}
                            </label>
                            {data.slug === "file_upload_photo" ? (
                              <>
                                <input
                                  className={`form-control ${validation.file_upload_photo ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setFilePhoto(e.target.files[0])}
                                  type="file"
                                  id="formFile"
                                  required={data.required === 1 ? true : false}
                                />
                                {validation.file_upload_photo && (
                                  <div className={`invalid-feedback ${validation.file_upload_photo ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.file_upload_photo[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "file_upload_id_card" ? (
                              <>
                                <input
                                  className={`form-control ${validation.file_id_card ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setFileIDCard(e.target.files[0])}
                                  type="file"
                                  id="formFile"
                                  required={data.required === 1 ? true : false}
                                />
                                {validation.file_id_card && (
                                  <div className={`invalid-feedback ${validation.file_id_card ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.file_id_card[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "file_upload_passport" ? (
                              <>
                                <input
                                  className={`form-control ${validation.file_upload_passport ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setFilePassport(e.target.files[0])}
                                  type="file"
                                  id="formFile"
                                  required={data.required === 1 ? true : false}
                                />
                                {validation.file_upload_passport && (
                                  <div className={`invalid-feedback ${validation.file_upload_passport ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.file_upload_passport[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "file_saving_account" ? (
                              <>
                                <input
                                  className={`form-control ${validation.file_saving_account ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setFileSavingAccount(e.target.files[0])}
                                  type="file"
                                  id="formFile"
                                  required={data.required === 1 ? true : false}
                                />
                                {validation.file_saving_account && (
                                  <div className={`invalid-feedback ${validation.file_saving_account ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.file_saving_account[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "file_vaccine" ? (
                              <>
                                <input
                                  className={`form-control ${validation.file_vaccine ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setFileVaccine(e.target.files[0])}
                                  type="file"
                                  id="formFile"
                                  required={data.required === 1 ? true : false}
                                />
                                {validation.file_vaccine && (
                                  <div className={`invalid-feedback ${validation.file_vaccine ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.file_vaccine[0]}
                                  </div>
                                )}
                              </>
                            ) : data.slug === "file_boarding_ticket" ? (
                              <>
                                <input
                                  className={`form-control ${validation.file_boarding_ticket ? "is-invalid" : ""}`}
                                  name={data.slug}
                                  onChange={(e) => setFileTicket(e.target.files[0])}
                                  type="file"
                                  id="formFile"
                                  required={data.required === 1 ? true : false}
                                />
                                {validation.file_boarding_ticket && (
                                  <div className={`invalid-feedback ${validation.file_boarding_ticket ? "d-block mb-3" : "d-none"}`}>
                                    <i className="bi bi-exclamation-circle me-2"></i>
                                    {validation.file_boarding_ticket[0]}
                                  </div>
                                )}
                              </>
                            ) : null}
                          </div>
                        </>
                      ) : data.type === "Checkbox" ? (
                        <div className="form-check form-switch">
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            {data.required === 1 ? <span className="text-danger">*</span> : ""} {data.caption}
                          </label>
                          {data.slug === "check_vvip_escort" ? (
                            <input className="form-check-input" type="checkbox" name={data.slug} value={!vvipEscort ? 1 : 0} onChange={(e) => setVvipEscort(e.target.value)} role="switch" id="flexSwitchCheckDefault" />
                          ) : null}
                        </div>
                      ) : data.type === "Date" ? (
                        <>
                          <div className="form-floating mb-3">
                            {data.slug === "date_est_arrival" ? (
                              <input
                                type="date"
                                className="form-control"
                                name={data.slug}
                                onFocus={(e) => (e.target.type = "date")}
                                onBlur={(e) => (e.target.type = "text")}
                                onChange={(e) => setEstArrival(e.target.value)}
                                id="floatingDate"
                                required={data.required === 1 ? true : false}
                                placeholder="Input Estimated Arrival"
                              />
                            ) : null}
                            <label htmlFor="floatingDate">
                              {data.required === 1 ? <span className="text-danger">*</span> : ""} {data.caption}
                            </label>
                          </div>
                        </>
                      ) : null;
                    })}
                  </div>
                </div>
                <br />
                <button className="btn btn-primary" type="submit">
                  <i className="bi bi-save2"></i> Save
                </button>
              </form>
            </div>
          </>
        ) : (
          <Navigate to={`/transaction-legal/detail/${visa}`} replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

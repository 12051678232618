import React, { useState, useMemo } from "react";
import { useTable, useFilters, useSortBy, usePagination, useAsyncDebounce, useGlobalFilter } from "react-table";

export default function TableCustomerCard({ columns, data }) {
  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 800);

    return (
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records ...`}
      ></input>
    );
  }

  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        placeholder={`Search ${count} records ...`}
      ></input>
    );
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        // hiddenColumns: ["Action"],
        pageSize: 5,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="container-fluid">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            Data Filter:
          </span>
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
        </div>
        <br />
        <table {...getTableProps()} className="table table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((columns) => {
                  return columns.hideHeader === false ? null : (
                    <th {...columns.getHeaderProps(columns.getSortByToggleProps())}>
                      {columns.render("Header")}
                      <span>
                        {columns.isSorted ? (
                          columns.isSortedDesc ? (
                            <i className="bi bi-arrow-down text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                          ) : (
                            <i className="bi bi-arrow-up text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                          )
                        ) : columns.disableSortBy === true ? null : (
                          <i className="bi bi-arrow-down-up text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <nav className="float-end">
          <ul className="pagination">
            <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <a className="page-link" href="#!">
                First
              </a>
            </li>
            <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <a className="page-link" href="#!">
                {"<"}
              </a>
            </li>
            <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
              <a className="page-link" href="#!">
                {">"}
              </a>
            </li>
            <li className="page-item me-3" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <a className="page-link" href="#!">
                Last
              </a>
            </li>
            <li className="me-3">
              <a className="page-link" href="#!">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </a>
            </li>
            <li className="me-3">
              <a className="page-link" href="#!">
                <input
                  className="form-control"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px", height: "20px" }}
                />
              </a>
            </li>{" "}
            <select
              className="form-control"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ width: "120px", height: "38px" }}
            >
              {[5].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </ul>
        </nav>
      </div>
    </>
  );
}

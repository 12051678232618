import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicExtended from "ckeditor5-build-classic-extended";
import { API } from "../../Config/Config";

export default function UpdateEula() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Eula Templates", slug: "/index-eula", status: "" },
    { id: "3", title: "Update Eula Templates", slug: "/eula-template", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState([]);
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    axios
      .get(api + "/eula-template/data", header)
      .then(function (response) {
        let data = response.data.data.data;
        setTitle(data.title);
        setContent(data.content);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [api, header]);

  const updateEula = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("title", title);
    formData.append("content", content);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await axios.post(api + "/eula-template/create", formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <AppHeader />
      {success ? (
        <div className="container-fluid p-0 m-0 mb-5">
          <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
            <div className="flex-grow-1 py-2">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
          </div>
          <div className="px-4 pb-4">
            <form onSubmit={updateEula}>
              <div className="form-floating mb-3">
                <input type="text" className={`form-control ${validation.title ? "is-invalid" : ""}`} value={title} onChange={(e) => setTitle(e.target.value)} id="floatingInput" />
                <label htmlFor="floatingInput">Title</label>
                {validation.title && (
                  <div className={`invalid-feedback ${validation.title ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.title[0]}
                  </div>
                )}
              </div>
              <CKEditor
                editor={ClassicExtended}
                data={content ? content : ""}
                config={{
                  toolbar: ["heading", "|", "bold", "italic", "underline", "link", "bulletedList", "numberedList", "|", "alignment", "|", "pageBreak", "|", "blockQuote", "insertTable", "|", "undo", "redo"],
                }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                  // console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  // console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  // console.log("Focus.", editor);
                }}
              />
              <div className="form-floating mb-3 mt-3 text-start">
                <button type="submit" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-save"></i>
                  </span>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Navigate to="/index-eula" replace={true} />
      )}
      <AppFooter />
    </>
  );
}

import axios from "axios";
import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import AppSpinner from "../../Components/AppSpinner";
import Swal from "sweetalert2";
import { API } from "../../Config/Config";
import { Modal } from "react-bootstrap";

export default function IndexFormModules() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const { service, module } = useParams();
  const [showModal, setShowModal] = useState(false);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Services", slug: "/services", status: "" },
    { id: "3", title: "Modules", slug: `/services/${service}/modules`, status: "" },
    { id: "4", title: "Form Modules", slug: "/modules/form", status: "active" },
  ];

  const [formElement, setFormElement] = useState([]);
  const [element, setElement] = useState("");

  const fetchFormData = useCallback(async () => {
    await axios.get(api + `/services/${service}/modules/${module}/form/list`, header).then(function (response) {
      let forms = response.data.data;
      // console.log(forms);
      setFormModules(forms);
      setIsLoaded(true);
    });
  }, [api, header, module, service]);

  const fetchFormElement = useCallback(async () => {
    await axios.get(api + `/services/${service}/modules/${module}/form/element`, header).then((response) => {
      setFormElement(response.data.data);
    });
  }, [api, header, module, service]);

  const [formModules, setFormModules] = useState([{ caption: "", description: "", type: "", slug: "", order: "", show: "", required: "" }]);

  const handleFormChange = (index, event) => {
    let result = [...formModules];
    result[index][event.target.name] = event.target.value;
    setFormModules(result);
  };

  const addElement = (e) => {
    axios.get(api + `/services/${service}/modules/${module}/form/form-element?element=` + e, header).then((response) => {
      let data = response.data.data.data;
      var valueInput = { caption: data.caption, description: data.description, type: data.type, slug: data.slug, order: "", show: "", required: data.required };
      // console.log(valueInput);
      setFormModules([...formModules, valueInput]);
    });
  };

  const addFields = () => {
    let newfield = { caption: "", description: "", type: "", slug: "", order: "", show: "", required: "" };

    setFormModules([...formModules, newfield]);
  };

  const [selectElement, setSelectElement] = useState("");

  const onCloseModal = () => {
    setSelectElement("");
    setShowModal(false);
  };

  const selectHandler = () => {
    addElement(selectElement);
    setSelectElement("");
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var data = [...formModules];
    // console.log(data);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/services/${service}/modules/${module}/form/create`, data, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          // console.log(response.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2500,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          fetchFormData();
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(api + `/services/${service}/modules/${module}/form/${id}/delete`, header)
            .then(function (response) {
              // console.log(response);
              if (response.data.status === "error") {
                let data = [...formModules];
                data.splice(id, 1);
                setFormModules(data);
              } else if (response.data.status === "success") {
                Swal.fire({
                  icon: "success",
                  title: response.data.message,
                  timer: 1500,
                });
                fetchFormData();
              }
            })
            .catch(function (error) {
              // console.log(error);
            });
        }
      });
    },
    [api, header, fetchFormData, formModules, module, service]
  );

  const [success, setSuccess] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [validation, setValidation] = useState([]);

  const [name, setName] = useState("");
  const [parent, setParent] = useState([]);
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    axios.get(api + `/services/${service}/modules/data/${module}`, header).then(function (response) {
      let modules = response.data.data.data;
      setName(modules.name);
      setSlug(modules.slug);
      setDescription(modules.description);
      setParent(modules.parent);
    });
  }, [api, header, module, service]);

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchFormData();
    fetchFormElement();
  }, [token, fetchFormData, fetchFormElement]);

  if (!isLoaded) {
    return (
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
          <div className="flex-grow-1 py-2">
            <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
          </div>
        </div>
        <AppSpinner title="Loading data Form Modules . . ." />
      </div>
    );
  }
  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
          <div className="flex-grow-1 py-2">
            <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
          </div>
        </div>
        <div className="container-fluid">
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td style={{ width: "10%" }}>Parent</td>
                  <td style={{ width: "2%" }}>:</td>
                  {parent === null ? <td>-</td> : <td>{parent.name}</td>}
                </tr>
                <tr>
                  <td style={{ width: "10%" }}>Name</td>
                  <td style={{ width: "2%" }}>:</td>
                  <td>{name}</td>
                </tr>
                <tr>
                  <td style={{ width: "10%" }}>Description</td>
                  <td style={{ width: "2%" }}>:</td>
                  <td>{description}</td>
                </tr>
                <tr>
                  <td style={{ width: "10%" }}>Slug</td>
                  <td style={{ width: "2%" }}>:</td>
                  <td>{slug}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {validation.errMessage && (
            <div className={`alert alert-danger alert-dismissible fade show ${validation.errMessage ? "d-block" : "d-none"}`} role="alert">
              <i className="bi bi-exclamation-circle"></i>
              {validation.errMessage[0]}
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Caption</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Slug</th>
                    <th>Order</th>
                    <th>Show</th>
                    <th>Required</th>
                  </tr>
                </thead>
                <tbody>
                  {formModules.map((input, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <div className="form-floating mb-3">
                              <input type="text" className="form-control" id="floatingInputCaption" name="caption" defaultValue={input.caption} onChange={(event) => handleFormChange(index, event)} required />
                              <label htmlFor="floatingInputCaption">Caption</label>
                            </div>
                          </td>
                          <td>
                            <div className="form-floating mb-3">
                              <textarea className="form-control" name="description" id="floatingTextArea" defaultValue={input.description} onChange={(event) => handleFormChange(index, event)} />
                              <label htmlFor="floatingTextArea">Description</label>
                            </div>
                          </td>
                          <td>
                            <div className="form-floating">
                              <select className="form-select" name="type" id="floatingSelect" defaultValue={input.type} onChange={(event) => handleFormChange(index, event)} required>
                                <option value="">Select Type</option>
                                <option value="Text">Text</option>
                                <option value="Text Area">Text Area</option>
                                <option value="File">File</option>
                                <option value="Select">Select</option>
                                <option value="Date">Date</option>
                                <option value="Checkbox">Checkbox</option>
                              </select>
                              <label htmlFor="floatingSelect">Type</label>
                            </div>
                          </td>
                          <td>
                            <div className="form-floating mb-3">
                              <input type="text" className="form-control" name="slug" id="floatingInput" defaultValue={input.slug} onChange={(event) => handleFormChange(index, event)} required />
                              <label htmlFor="floatingInput">Slug</label>
                            </div>
                          </td>
                          <td>
                            <div className="form-floating mb-3">
                              <input type="number" className="form-control" name="order" id="floatingInput" defaultValue={input.order} onChange={(event) => handleFormChange(index, event)} required />
                              <label htmlFor="floatingInput">Order</label>
                            </div>
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="show"
                                value={!input.show ? 1 : 0}
                                defaultChecked={input.show}
                                onChange={(event) => handleFormChange(index, event)}
                                role="switch"
                                id="flexSwitchCheckDefault"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="required"
                                value={!input.required ? 1 : 0}
                                defaultChecked={input.required}
                                onChange={(event) => handleFormChange(index, event)}
                                role="switch"
                                id="flexSwitchCheckDefault"
                              />
                            </div>
                          </td>
                          <td>
                            <Link to="#" className="btn btn-danger" onClick={() => handleDelete(input.id)}>
                              <i className="bi bi-trash-fill"></i>
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <tr>
                    <td colSpan={8}>
                      <div className="dropdown">
                        <div className="d-grid gap-2">
                          <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>
                            <i className="bi bi-plus-lg"></i> Select Form Element
                          </button>
                          <ul className="dropdown-menu">
                            {formElement.map((data, i) => (
                              <li key={i}>
                                <Link to="#" className="dropdown-item" onClick={(e) => addElement(data.data.caption)}>
                                  {data.data.caption}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={8}>
                      <div className="d-grid gap-2">
                        <Link to="#" role="button" className="btn btn-primary" onClick={addFields}>
                          <i className="bi bi-plus-lg"></i> Add Form Manually
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button className="btn btn-primary" type="submit">
              <i className="bi bi-save2"></i> Save
            </button>
          </form>
        </div>
        <AppFooter />
      </div>

      {/* Modal Select Form Element */}
      <Modal show={showModal} centered size="lg">
        <Modal.Header>
          <Modal.Title>Form Element</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={(e) => setSelectElement(e.target.value)}>
              <option>Select Form Element</option>
              {formElement.map((data, i) => (
                <option key={i} value={data.data.caption}>
                  {/* <Link to="#" className="dropdown-item" onClick={(e) => addElement(data.data.caption)}>
                                  {data.data.caption}
                                </Link> */}
                  {data.data.caption}
                </option>
              ))}
            </select>
            <label htmlFor="floatingSelect">Form Element</label>
          </div>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={onCloseModal}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={selectHandler}>
              Select
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      {/* End Modal Select Form Element */}
    </>
  );
}

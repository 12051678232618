// import logo from "./logo.svg";
import React from "react";
import Path from "./Routes/Routes";

function App() {
  return (
    <div className="App">
      <Path />
    </div>
  );
}

export default App;

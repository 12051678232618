import React, { useState } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

export default function TableState({ columns, data }) {
  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("data.name", value);
    setFilterInput(value);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex flex-row align-items-center align-self-center">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Data Filter:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder={"Search State"}
              aria-label={"Search State"}
              aria-describedby="basic-addon1"
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <table {...getTableProps()} className="table table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((columns) => {
                  return columns.hideHeader === false ? null : (
                    <th
                      {...columns.getHeaderProps(
                        columns.getSortByToggleProps()
                      )}
                    >
                      {columns.render("Header")}
                      <span>
                        {columns.isSorted ? (
                          columns.isSortedDesc ? (
                            <i
                              className="bi bi-arrow-down text-muted ms-1"
                              style={{ fontSize: "0.8rem" }}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-arrow-up text-muted ms-1"
                              style={{ fontSize: "0.8rem" }}
                            ></i>
                          )
                        ) : columns.disableSortBy === true ? null : (
                          <i
                            className="bi bi-arrow-down-up text-muted ms-1"
                            style={{ fontSize: "0.8rem" }}
                          ></i>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table-group-divider">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="col-md-3 fixed">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-center flex-wrap mb-5">
          <div className="px-2">
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-sm">
                <li
                  className="page-item"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <span className="page-link px-3">First</span>
                </li>
                <li
                  className="page-item"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <span className="page-link px-2">&laquo;</span>
                </li>
                <li className="page-item px-1">
                  <span className="page-link px-3 text-wrap">
                    Page {pageIndex + 1} of {pageOptions.length}{" "}
                  </span>
                </li>
                <li
                  className="page-item"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <span className="page-link px-2">&raquo;</span>
                </li>
                <li
                  className="page-item me-3"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <span className="page-link px-3">Last</span>
                </li>
              </ul>
            </nav>
          </div>
          <div className="px-2">
            <select
              className="form-select form-select-sm"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import AppHeader from "../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
import DataTablePermission from "./DataTablePermission";
import AppBreadcrumb from "../Components/AppBreadcrumb";

export default function IndexPermission() {
  const [success, setSuccess] = useState(true);

  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Permissions", slug: "/permission", status: "active" },
  ];

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
      // navigate("../login", { replace: true });
    }
  }, [token]);

  return (
    <>
      <AppHeader />
      <div className="d-flex border-bottom mt-3 mb-3">
        <div className="p-2 flex-grow-1 ms-3">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
        <div>
          <a className="btn btn-primary mb-3 me-4" href="/permission/create" role="button">
            <span className="me-2">
              <i className="bi bi-plus-circle"></i>
            </span>
            Add New
          </a>
        </div>
      </div>
      <div className="containter-fluid p-0 m-0">
        <DataTablePermission />
      </div>
      <AppFooter />
    </>
  );
}

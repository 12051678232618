import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import { Navigate, useParams, Link } from "react-router-dom";
import { API } from "../../Config/Config";

export default function CreateFormModules() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);
  const api = API;

  const { service, module } = useParams();

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Services", slug: "/services", status: "" },
    { id: "3", title: "Modules", slug: `/services/${service}/modules`, status: "" },
    { id: "4", title: "Form Modules", slug: `/services/${service}/modules/${module}/form`, status: "" },
    { id: "5", title: "Create Form Modules", slug: "/modules/form/create", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const [formElement, setFormElement] = useState([]);

  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [slug, setSlug] = useState("");
  const [required, setRequired] = useState("");
  const [order, setOrder] = useState("");
  const [show, setShow] = useState("");
  const [formData, setFormData] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    setFormData(new FormData());
  }, []);

  useEffect(() => {
    axios.get(api + `/services/${service}/modules/${module}/form/form-elements`, header).then((response) => {
      setFormElement(response.data.data.data);
    });
  }, [api, header]);

  const handleRequired = () => {
    setRequired(!required);
    var data = !required ? 1 : 0;
    formData.append("required", data);
  };

  const handleShow = () => {
    setShow(!show);
    var data = !show ? 1 : 0;
    formData.append("show", data);
  };

  const saveFormModules = async (e) => {
    e.preventDefault();

    formData.append("caption", caption);
    formData.append("description", description);
    formData.append("type", type);
    formData.append("slug", slug);
    formData.append("order", order);

    // console.log(...formData);
  };

  return (
    <>
      <AppHeader />

      {success ? (
        <>
          <div className="d-flex flex-row align-items-center align-self-center ps-4 pe-4 pt-2 pb-3 border-bottom mb-3">
            <div className="flex-grow-1">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>
              <Link to={`/services/${service}/modules/${module}/form`} className="btn btn-primary">
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Back
              </Link>
            </div>
          </div>
          <div className="container-fluid mb-5">
            <div className="table-responsive">
              <form onSubmit={saveFormModules}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Caption</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Slug</th>
                      <th>Order</th>
                      <th>Show</th>
                      <th>Required</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formElement.map((data, id) => (
                      <tr key={id}>
                        <td>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={data.id} id="flexCheckDefault" />
                            {data.id}
                          </div>
                        </td>
                        <td>
                          <div className="form-floating mb-3">
                            <input type="text" onChange={(e) => setCaption(e.target.value)} defaultValue={data.caption} className="form-control" id="floatingCaption" />
                            <label htmlFor="floatingCaption">Caption</label>
                          </div>
                        </td>
                        <td>
                          <div className="form-floating">
                            <textarea className="form-control" defaultValue={data.description} id="floatingTextarea"></textarea>
                            <label htmlFor="floatingTextarea">Description</label>
                          </div>
                        </td>
                        <td>
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" defaultValue={data.type} id="floatingType" disabled />
                            <label htmlFor="floatingType">Type</label>
                          </div>
                        </td>
                        <td>
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" defaultValue={data.slug} id="floatingSlug" />
                            <label htmlFor="floatingSlug">Slug</label>
                          </div>
                        </td>
                        <td>
                          <div className="form-floating mb-3">
                            <input type="number" className="form-control" id="floatingOrder" />
                            <label htmlFor="floatingOrder">Order</label>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" value={show} onChange={handleShow} role="switch" id="flexSwitchCheckDefault" />
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" defaultChecked={data.required} onChange={handleRequired} id="flexSwitchCheckDefault" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-2">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <Navigate to={`/services/${service}/modules/${module}/form`} replace={true} />
      )}

      <AppFooter />
    </>
  );
}

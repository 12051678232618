import React, { useMemo, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import { API } from ".././Config/Config";

import Table from "./TableOfficeLocation";

export default function DataTableOfficeLocation() {
  const api = API;
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [office, setOffice] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchOffice = useCallback(async () => {
    await axios.get(api + "/office/list", header).then((response) => {
      setOffice(response.data.data);
      setIsLoaded(true);
    });
  }, [api, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(api + `/office/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchOffice();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, fetchOffice, header]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/office/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "Mobile Phone",
            accessor: "mobile",
          },
          {
            Header: "Telephone",
            accessor: "telephone",
          },
          {
            Header: "Address Name",
            accessor: "address_name",
          },
          {
            Header: "Address Street",
            accessor: "address_street",
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchOffice();
  }, [token, fetchOffice]);

  if (!isLoaded) {
    return (
      <>
        <AppSpinner title="Loading data office . . ." />
      </>
    );
  }

  if (office.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-5 fw-bold text-body-emphasis">Data not found</h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">Office data not found, to start filling in new Office data, please click the add new button at the top right of your screen.</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={office} />
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

const AppBreadcrumb = (props) => {
  const arrBreadcrumbs = props.breadcrumbs;
  // console.log(arrBreadcrumbs);
  // arrBreadcrumbs.map((item) => console.log(item.slug + " " + item.title));

  return (
    <div aria-label="breadcrumb">
      <ol className="breadcrumb p-0 m-0">
        {arrBreadcrumbs.map((item) => {
          return (
            <li className={"breadcrumb-item " + item.status} key={item.id}>
              {item.status === "" ? (
                <Link to={item.slug} className="text-decoration-none">
                  {item.title}
                </Link>
              ) : (
                item.title
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default AppBreadcrumb;

import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import empty from "../Components/empty.jpg";
import { API, Storage } from ".././Config/Config";

import Table from "./TableCustomer";

export default function DataTableCustomer() {
  const apiUrl = API;

  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [customer, setCustomer] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchCustomer = useCallback(async () => {
    await axios.get(apiUrl + "/customers/list", header).then((response) => {
      setCustomer(response.data.data);
      // console.log(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(apiUrl + `/customers/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchCustomer();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, fetchCustomer, header]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: (d) => `${d.data.id}`,
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link
                    to={`/customers/edit/${value}`}
                    className="btn btn-sm btn-secondary m-1"
                  >
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-sm btn-danger m-1"
                    onClick={() => handleDelete(value)}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Photo",
            disableSortBy: true,
            Cell: ({ row }) => {
              return row.original.data.photo === "empty.jpg" ? (
                <img
                  alt="Profile"
                  style={{ width: "4rem", height: "4rem", borderRadius: "50%" }}
                  src={empty}
                />
              ) : (
                <img
                  className="img-fluid img-thumbnail"
                  alt="Profile"
                  style={{ height: "5rem", borderRadius: "1 0%" }}
                  src={
                    Storage +
                    `/user_profile/${row.original.data.id}/${row.original.data.photo}`
                  }
                />
              );
            },
          },
          {
            Header: "Customer Lists",
            accessor: (d) => `${d.data.first_name} ${d.data.last_name}`,
            Cell: ({ row }) => {
              return (
                <div className="card mb-3 border border-0">
                  <div className="row g-0">
                    <div className="col-3 p-2 ps-3 justify-content-center align-self-center">
                      {row.original.data.photo === "empty.jpg" ? (
                        <img
                          className="img-fluid img-thumbnail rounded"
                          alt="Empty"
                          style={{ maxHeight: "6.5rem" }}
                          src={empty}
                        />
                      ) : (
                        <img
                          className="img-fluid img-thumbnail rounded"
                          alt={row.original.data.photo}
                          style={{ maxHeight: "6.5rem" }}
                          src={
                            Storage +
                            `/user_profile/${row.original.data.id}/${row.original.data.photo}`
                          }
                        />
                      )}
                    </div>
                    <div className="col-9">
                      <div className="card-body small">
                        <p className="fw-bold p-0 m-0">
                          {row.original.data.first_name}{" "}
                          {row.original.data.last_name}
                        </p>
                        <p className="fw-light p-0 m-0">
                          {row.original.data.email}
                        </p>
                        <p className="fw-light p-0 m-0">
                          {row.original.data.mobile}
                        </p>
                        <p className="fw-light p-0 m-0">
                          {row.original.data.param_nationality === null ? (
                            <span className="fst-italic text-danger">
                              (Undefined)
                            </span>
                          ) : (
                            row.original.data.param_nationality.name
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            Header: "Full Name",
            accessor: (d) => `${d.data.first_name} ${d.data.last_name}`,
            Cell: ({ row }) => {
              return (
                <p>
                  <span className="fw-bold">
                    {row.original.data.first_name} {row.original.data.last_name}{" "}
                  </span>
                </p>
              );
            },
          },
          {
            Header: "Email",
            accessor: "data.email",
          },
          {
            Header: "Nationality",
            accessor: "data.param_nationality.name",
          },
          {
            Header: "Mobile",
            accessor: "data.mobile",
            Cell: ({ cell: { value } }) => {
              return value === null ? <p>-</p> : <p>{value}</p>;
            },
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    fetchCustomer();
  }, [token, fetchCustomer]);

  if (!isLoaded) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <AppSpinner title="Loading data customers ..." />
        </div>
      </>
    );
  }

  return (
    <>
      {customer.length < 1 ? (
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-5 fw-bold text-body-emphasis">
              Data not found
            </h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">
                Customer data not found, to start filling in new customer data,
                please click the add new button at the top right of your screen.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid table-responsive p-0 mb-5">
          <Table columns={columns} data={customer} />
        </div>
      )}
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { API } from ".././Config/Config";

export default function AppHeader() {
  const apiUrl = API;
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const roles = useMemo(() => {
    return localStorage.getItem("roles");
  }, []);

  const [getFullName, setFullName] = useState("");
  const [menuMaster, setMenuMaster] = useState([]);
  const [menuSettings, setMenuSettings] = useState([]);
  const [menuTransaction, setMenuTransaction] = useState([]);
  const [menuDatabase, setMenuDatabase] = useState([]);
  const [menuTools, setMenuTools] = useState([]);
  const [success, setSuccess] = useState(true);

  // const getIP = useCallback(async () => {
  //   await axios
  //     .get("https://geolocation-db.com/json/")
  //     .then(function (response) {
  //       setIP(JSON.stringify(response.data));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  // const moveToPage = (destination) => {
  //   let nav = useNavigate();
  //   nav(destination);
  // };

  // useEffect(() => {
  // axios
  //   .get(apiUrl + "/users/get_user/", header)
  //   .then(function (response) {
  //     let users = response.data.data.data;
  //     setFullName(users.first_name + " " + users.last_name);
  //   })
  //   .catch(function (error) {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("roles");
  //     Swal.fire({
  //       icon: "error",
  //       title: "An Error Occured!",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     setSuccess(false);
  //   });
  // }, []);

  const fetchUserLogged = useCallback(async () => {
    await axios
      .get(apiUrl + "/users/get", header)
      .then(function (response) {
        let users = response.data.data.data;
        setFullName(users.first_name + " " + users.last_name);
      })
      .catch(function (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        localStorage.removeItem("expires_at");
        // localStorage.removeItem("isLogin");
        Swal.fire({
          icon: "error",
          // title: error.response.data.message,
          title: "Session expired, please login again.",
          showConfirmButton: false,
          timer: 1500,
        });
        setSuccess(false);
        // console.log(error);
      });
  }, [apiUrl, header]);

  const fetchAllMenu = useCallback(async () => {
    await axios.get(apiUrl + "/url-permissions/list_all_menu/" + roles, header).then((response) => {
      let menuMaster = response.data.data;
      let menuSettings = response.data.data;
      let menuTransaction = response.data.data;
      let menuDatabase = response.data.data;
      let menuTools = response.data.data;

      setMenuMaster(menuMaster.master);
      setMenuSettings(menuSettings.settings);
      setMenuTransaction(menuTransaction.transaction);
      setMenuDatabase(menuDatabase.database);
      setMenuTools(menuTools.tools);
    });
  }, [apiUrl, roles, header]);

  const logoutHandler = useCallback(async () => {
    Swal.fire({
      title: "Logged Out ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .get("https://geolocation-db.com/json/")
      .then(function (response) {
        let ip = JSON.stringify(response.data);

        //fetch Rest API
        axios
          // .get(apiUrl + "/users/logout", header)
          .post(apiUrl + "/users/logout", JSON.stringify({ geolocation: ip }), header)
          .then((response) => {
            //remove token from localStorage
            localStorage.removeItem("token");
            localStorage.removeItem("roles");
            localStorage.removeItem("expires_at");

            //redirect halaman login
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });
            setSuccess(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [apiUrl, header]);

  useEffect(() => {
    //check token empty
    fetchUserLogged();
    if (!token) {
      //redirect login page
      setSuccess(false);
    }
    fetchAllMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, fetchAllMenu, fetchUserLogged]);

  return (
    <>
      {success ? (
        <nav className="navbar navbar-expand-lg bg-light pt-3 border-bottom mb-2 sticky-top shadow-sm z-3">
          <div className="container-fluid">
            <Link to="/" className="btn btn-link text-decoration-none navbar-brand">
              <img src="/assets/brand/maxxs-co-logo-boa.png" alt="maxx's co" height="50" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link active" aria-current="page">
                    Dashboard
                  </Link>
                </li>
                {menuDatabase.length > 0 ? (
                  <li className="nav-item dropdown">
                    <Link to="/#" className="nav-link dropdown-toggle" aria-expanded="false" role="button" data-bs-toggle="dropdown">
                      Database
                    </Link>
                    <ul className="dropdown-menu">
                      {menuDatabase.map((data, index) => {
                        return (
                          <li key={index}>
                            <Link to={`${data[0].url}`} className="dropdown-item py-2 px-4">
                              {data[0].name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                {menuMaster.length > 0 ? (
                  <li className="nav-item dropdown">
                    <Link to="/#" className="nav-link dropdown-toggle" aria-expanded="false" role="button" data-bs-toggle="dropdown">
                      Master
                    </Link>
                    <ul className="dropdown-menu">
                      {menuMaster.map((data, index) => {
                        return (
                          <li key={index}>
                            <Link to={`${data[0].url}`} className="dropdown-item py-2 px-4">
                              {data[0].name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                {menuSettings.length > 0 ? (
                  <li className="nav-item dropdown">
                    <Link to="/#" className="nav-link dropdown-toggle" aria-expanded="false" role="button" data-bs-toggle="dropdown">
                      Settings
                    </Link>
                    <ul className="dropdown-menu">
                      {menuSettings.map((data, index) => {
                        return (
                          <li key={index}>
                            <Link to={`${data[0].url}`} className="dropdown-item py-2 px-4">
                              {data[0].name}
                            </Link>
                          </li>
                        );
                      })}
                      <li>
                        {roles === "1" ? (
                          <>
                            <Link to="/roles" className="dropdown-item py-2 px-4">
                              Roles
                            </Link>
                            <Link to="/url" className="dropdown-item py-2 px-4">
                              URL
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>
                  </li>
                ) : (
                  <>
                    {roles === "1" ? (
                      <li className="nav-item dropdown">
                        <Link to="/#" className="nav-link dropdown-toggle" aria-expanded="false" role="button" data-bs-toggle="dropdown">
                          Settings
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/roles" className="dropdown-item py-2 px-4">
                              Roles
                            </Link>
                            <Link to="/url" className="dropdown-item py-2 px-4">
                              URL
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {menuTransaction.length > 0 ? (
                  <li className="nav-item dropdown">
                    <Link to="/#" className="nav-link dropdown-toggle" aria-expanded="false" role="button" data-bs-toggle="dropdown">
                      Transaction
                    </Link>
                    <ul className="dropdown-menu">
                      {menuTransaction.map((data, index) => {
                        return (
                          <li key={index}>
                            <Link to={`${data[0].url}`} className="dropdown-item py-2 px-4">
                              {data[0].name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                {menuTools.length > 0 ? (
                  <li className="nav-item dropdown">
                    <Link to="/#" className="nav-link dropdown-toggle" aria-expanded="false" role="button" data-bs-toggle="dropdown">
                      Tools
                    </Link>
                    <ul className="dropdown-menu">
                      {menuTools.map((data, index) => {
                        return (
                          <li key={index}>
                            <Link to={`${data[0].url}`} className="dropdown-item py-2 px-4">
                              {data[0].name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <div className="d-flex row me-1 align-items-center">
                <div className="col col-sm-auto">
                  <p className="small p-0 m-0">
                    Welcome,
                    <br />
                    <strong>{getFullName}</strong>
                  </p>
                </div>
                <div className="col text-end">
                  <button type="button" onClick={logoutHandler} className="btn btn-danger btn-sm">
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      ) : (
        <Navigate to="/login" replace={true} />
      )}
    </>
  );
}

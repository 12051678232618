import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import { Navigate, Link } from "react-router-dom";
import { API } from "../../Config/Config";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicExtended from "ckeditor5-build-classic-extended";

export default function CreateNewsUpdates() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "News Updates", slug: "/news", status: "" },
    { id: "3", title: "Create News Updates", slug: "/news/create", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const [formData, setFormData] = useState("");

  useEffect(() => {
    setFormData(new FormData());
  }, []);

  const [title, setTitle] = useState("");
  const [image, setImage] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);
  const [content, setContent] = useState("");
  const [show, setShow] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    if (image.length < 1) return;
    const newImageUrls = [];
    image.forEach((images) => newImageUrls.push(URL.createObjectURL(images)));
    setImgUrl(newImageUrls);
  }, [image]);

  function onImageChange(e) {
    setImage([...e.target.files]);
  }

  const handleToggle = () => {
    setShow(!show);
    var data = !show ? 1 : 0;
    formData.append("show", data);
  };

  const saveNews = async (e) => {
    e.preventDefault();

    formData.append("title", title);
    formData.append("image", ...image);
    formData.append("content", content);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // console.log(...formData);

    const response = await axios.post(api + `/news/create`, formData, header);
    if (response.data.status == "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <AppHeader />
      <div className="container-fluid p-0 m-0 mb-5">
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/news" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form encType="multipart/form-data" onSubmit={saveNews}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                <div className="mb-3">
                  {imgUrl.map((imageSrc, i) => (
                    <img
                      alt="Icon"
                      key={i}
                      src={imageSrc}
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "25%",
                        maxHeight: "25%",
                        // borderRadius: "50%",
                        marginBottom: "2rem",
                      }}
                    />
                  ))}
                  <label htmlFor="formFile" className="form-label">
                    Image <sup className="text-danger">*</sup>
                  </label>
                  <input type="file" multiple accept="image/*" onChange={onImageChange} className="form-control" required />
                  {validation.image && <div className="alert alert-danger">{validation.image[0]}</div>}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className={`form-control ${validation.title ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Title <sup className="text-danger">*</sup>
                  </label>
                  {validation.title && (
                    <div className={`invalid-feedback ${validation.title ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.title[0]}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="floatingTextarea2" className="mb-3">
                    Description <sup className="text-danger">*</sup>
                  </label>
                  {validation.name && (
                    <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.name[0]}
                    </div>
                  )}
                  <CKEditor
                    editor={ClassicExtended}
                    data={content ? content : ""}
                    config={{
                      toolbar: ["heading", "|", "bold", "italic", "underline", "link", "bulletedList", "numberedList", "|", "alignment", "|", "pageBreak", "|", "blockQuote", "insertTable", "|", "undo", "redo"],
                    }}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input" onChange={handleToggle} value={show} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Show
                  </label>
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-2">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/news" replace={true} />
        )}
      </div>
      <AppFooter />
    </>
  );
}

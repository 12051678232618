import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../Config/Config";
import AppSpinner from "../Components/AppSpinner";

export default function ModalCustomerCard(props) {
  // -- General Setting -- //
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }, [token]);

  const [isLoading, setIsLoading] = useState(props.method === "EDIT" ? false : true);

  const [cardType, setCardType] = useState("");
  const [fileName, setFileName] = useState([]);
  const [remark, setRemark] = useState("");
  const [validation, setValidation] = useState([]);
  const [listCardType, setListCardType] = useState([]);
  // -- End General Setting -- //

  // -- Fetch List Card Type -- //
  const fetchCardType = useCallback(async () => {
    await axios
      .get(api + `/customers/card/card-type`, header)
      .then(function (response) {
        // console.log(response.data.data);
        setListCardType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [header, api]);
  // -- End Fetch List Card Type -- //
  // -- Load Data Customer Card ID -- //
  const fetchDataCard = useCallback(async () => {
    if (props.method === "EDIT" && props.cardID !== "0") {
      await axios
        .get(api + `/customers/card/data/${props.cardID}`, header)
        .then(function (response) {
          setIsLoading(true);
          // console.log(response.data.data.data);
          let items = response.data.data.data;
          setCardType(items.card_type);
          setFileName(items.filename);
          setRemark(items.remark);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [header, api, props]);

  useEffect(() => {
    fetchCardType();
    fetchDataCard();
  }, [fetchCardType, fetchDataCard]);

  // -- End Load Data Customer Card ID -- //

  // -- Save Data Customer Card -- //
  const saveCard = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("card_type", cardType);
    formData.append("filename", fileName);
    formData.append("remark", remark);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (props.method === "ADD") {
      formData.append("customer_id", props.customerID);

      await axios
        .post(api + `/customers/card/create`, formData, header)
        .then(function (response) {
          if (response.data.status === "error") {
            setValidation(response.data.data);
            Swal.fire({
              icon: "error",
              text: response.data.message,
              timer: 2000,
            });
          } else if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });

            setValidation([]);
            setCardType("");
            setFileName([]);
            setRemark("");

            props.close();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (props.method === "EDIT" && props.cardID !== "0") {
      await axios
        .post(api + `/customers/card/update/${props.cardID}`)
        .then(function (response) {
          if (response.data.status === "error") {
            setValidation(response.data.data);
            Swal.fire({
              icon: "error",
              text: response.data.message,
              timer: 2000,
            });
          } else if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });

            setValidation([]);
            setCardType("");
            setFileName([]);
            setRemark("");

            props.close();
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };
  // -- End Save Data Customer Card -- //

  return (
    <>
      <Modal show={props.show} centered size="lg">
        {isLoading === false ? (
          <div className="d-flex justify-content-center align-items-center align-self-center">
            <AppSpinner title="Loading data customer Card(s) ..." />
          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="px-3 py-1" onSubmit={saveCard}>
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.card_type ? "is-invalid" : ""}`} value={cardType} onChange={(e) => setCardType(e.target.value)}>
                    <option>Select Card ID Type</option>
                    {listCardType.map((data, id) => (
                      <option key={id} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Card ID Type</label>
                  {validation.card_type && <div className="alert alert-danger mt-2">{validation.card_type[0]}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="formFile" className="form-label">
                    Card ID File
                  </label>
                  <input className={`form-control ${validation.filename ? "is-invalid" : ""}`} type="file" id="formFile" onChange={(e) => setFileName(e.target.files[0])} />
                  {validation.filename && <div className="alert alert-danger mt-2">{validation.filename[0]}</div>}
                </div>
                <div className="form-floating mb-3">
                  <textarea className={`form-control ${validation.remark ? "is-invalid" : ""}`} value={remark} onChange={(e) => setRemark(e.target.value)} id="floatingInput" placeholder="remark" rows={3} />
                  <label htmlFor="floatingInput">Remark</label>
                  {validation.remark && <div className="alert alert-danger mt-2">{validation.remark[0]}</div>}
                </div>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      props.close();
                    }}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import styled from "styled-components";
import pict1 from "./img/pict1.jpg";
import pict2 from "./img/pict2.jpg";

export default function DetaiNewTransaction() {
  const Wizard = styled.div`
    margin-left: 30px;
    position: relative;
  `;
  const Line = styled.div`
    width: 2px;
    background-color: #ccc;
    position: absolute;
    margin-left: -15px;
    margin-top: 20px;
    height: 410px;
  `;
  const Step = styled.div`
  padding: 10px 0;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      border-radius: 20px;
      background-color: #00cc66;
      position: absolute;
      margin-left: -20px;
      top: 17px;
  `;
  return (
    <>
      <AppHeader />
      <div className="container mb-5">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="row justify-content-around">
                <div className="col-md-6">
                  <Link to="/newTransaction" className="text-muted" style={{ textDecoration: "none", fontSize: "0.9rem" }}>
                    <i class="bi bi-chevron-left"></i> Back
                  </Link>
                </div>
                <div className="col-md-6 mb-3">
                  <a data-bs-toggle="collapse" href="#collapseExample" className="float-end" style={{ textDecoration: "none", fontSize: "0.9rem" }}>
                    <strong>
                      See Details <i className="bi bi-chevron-down"></i>
                    </strong>
                  </a>
                </div>
                <div className="collapse mb-3" id="collapseExample">
                  <div className="card card-body">
                    <Wizard>
                      <Line />
                      <Step>
                        <span>Documents Reviewed</span>
                        <span className="float-end text-muted" style={{ fontSize: "0.9rem" }}>
                          20 August 2022
                        </span>
                        <br />
                        <div className="col-md-5">
                          <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                            REQ29382938, please remove your photo background and use solid color
                          </span>
                        </div>
                      </Step>
                      <Step>
                        <span>Review Documents</span>
                        <span className="float-end text-muted" style={{ fontSize: "0.9rem" }}>
                          20 August 2022
                        </span>
                        <br />
                        <div className="col-md-5">
                          <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                            Documents must be review
                          </span>
                        </div>
                      </Step>
                      <Step>
                        <span>Payment Complete</span>
                        <span className="float-end text-muted" style={{ fontSize: "0.9rem" }}>
                          19 August 2022
                        </span>
                        <br />
                        <div className="col-md-5">
                          <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                            Payment has been completed
                          </span>
                        </div>
                      </Step>
                      <Step>
                        <span>Waiting for payment</span>
                        <span className="float-end text-muted" style={{ fontSize: "0.9rem" }}>
                          19 August 2022
                        </span>
                        <br />
                        <div className="col-md-5">
                          <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                            Invoice has been generated
                          </span>
                        </div>
                      </Step>
                      <Step>
                        <span>MOU Signed</span>
                        <span className="float-end text-muted" style={{ fontSize: "0.9rem" }}>
                          18 August 2022
                        </span>
                        <br />
                        <span style={{ fontSize: "0.9rem" }}>
                          <Link to="#">Download MOU</Link>
                        </span>
                      </Step>
                      <Step>
                        <span>Waiting For MOU Approval</span>
                        <span className="float-end text-muted" style={{ fontSize: "0.9rem" }}>
                          18 August 2022
                        </span>
                        {/* <br />
                        <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                          Text desc
                        </span> */}
                      </Step>
                      <Step>
                        <span>New Request Submitted</span>
                        <span className="float-end text-muted" style={{ fontSize: "0.9rem" }}>
                          18 August 2022
                        </span>
                        {/* <br />
                        <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                          Text desc
                        </span> */}
                      </Step>
                    </Wizard>
                  </div>
                </div>
                <hr style={{ width: "98%" }} />
                <div className="col-md-6">
                  <p className="text-muted">No. Invoice</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">
                    <strong>INV#9283029</strong>
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Date</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">20 August 2022</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Status</p>
                </div>
                <div className="col-md-6">
                  <span className="badge text-bg-primary float-end">Waiting For Review</span>
                </div>
              </div>
              <hr />
              <strong>List Request</strong>
              <div className="card card-body mt-3">
                <div className="row row-cols-md-auto g-3 align-items-center">
                  <div className="col-12 text-center">
                    <img src={pict1} alt="" style={{ width: "3.5rem", height: "3.5rem", borderRadius: "50%" }} />
                  </div>
                  <div className="col-md-4" style={{ fontSize: "0.9rem" }}>
                    <div className="mt-3">
                      <strong>John Doe</strong>
                      <p>REQ29382938</p>
                      <span className="badge text-bg-warning">Need to be revised</span>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <p className="float-end text-end mt-3" style={{ fontSize: "0.9rem" }}>
                      <strong>IDR 2.500.000</strong>
                      <br />
                      <Link to="/detailRequest" style={{ textDecoration: "none", fontSize: "0.9rem" }}>
                        Check Document <i class="bi bi-chevron-right" style={{ fontSize: "0.8rem" }}></i>
                      </Link>
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row row-cols-md-auto g-3 align-items-center">
                  <div className="col-12">
                    <img src={pict2} alt="" style={{ width: "3.5rem", height: "3.5rem", borderRadius: "50%" }} />
                  </div>
                  <div className="col-md-4" style={{ fontSize: "0.9rem" }}>
                    <div className="mt-3">
                      <strong>Laura Haimburg</strong>
                      <p>REQ293822038</p>
                      <span className="badge text-bg-primary">Waiting for review</span>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <p className="float-end text-end mt-3" style={{ fontSize: "0.9rem" }}>
                      <strong>IDR 2.500.000</strong>
                      <br />
                      <Link to="/detailRequest" style={{ textDecoration: "none", fontSize: "0.9rem" }}>
                        Check Document <i class="bi bi-chevron-right" style={{ fontSize: "0.8rem" }}></i>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <strong>Payment Details</strong>
              <div className="row justify-content-around mt-3">
                <div className="col-md-6">
                  <p className="text-muted" style={{ fontSize: "0.9rem" }}>
                    Payment Menthod
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="float-end" style={{ fontSize: "0.9rem" }}>
                    Credit Cards
                  </p>
                </div>
                <hr style={{ borderTop: "1px dashed", width: "98%" }} />
                <div className="col-md-6">
                  <p className="text-muted" style={{ fontSize: "0.9rem" }}>
                    Sub Total
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="float-end" style={{ fontSize: "0.9rem" }}>
                    IDR 5.000.000
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted" style={{ fontSize: "0.9rem" }}>
                    VAT (11%)
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="float-end" style={{ fontSize: "0.9rem" }}>
                    IDR 550.000
                  </p>
                </div>
                <hr style={{ borderTop: "1px dashed", width: "98%" }} />
                <div className="col-md-6">
                  <p style={{ fontSize: "0.9rem" }}>
                    <strong>Total</strong>
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="float-end" style={{ fontSize: "0.9rem" }}>
                    <strong>IDR 5.550.000</strong>
                  </p>
                </div>
                <div className="col-12 mt-2">
                  <Link to="#" className="btn btn-success float-end">
                    Transaction Complete
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
}

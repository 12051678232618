import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AppSpinner from "../../Components/AppSpinner";
import Table from "./TableModules";
import { API, Storage } from "../../Config/Config";

export default function DataTableModules() {
  const apiUrl = API;

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [appModules, setAppModules] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { service } = useParams();

  const fetchAppModules = useCallback(async () => {
    await axios.get(apiUrl + `/services/${service}/modules/list`, header).then((response) => {
      setAppModules(response.data.data);
      // console.log(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header, service]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(apiUrl + `/services/${service}/modules/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchAppModules();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, fetchAppModules, header, service]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/services/${service}/modules/${value}/form`} className="btn btn-sm btn-primary m-1">
                    <i className="bi bi-layers"></i>
                  </Link>
                  <Link to={`/services/${service}/modules/${value}/edit`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Parent",
            accessor: "data.parent[name]",
            Cell: ({ cell: { value } }) => {
              return value === null ? <p>-</p> : <p>{value}</p>;
            },
          },
          {
            Header: "Icon",
            accessor: "data.icon",
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <img
                    alt="Icon"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "50%",
                    }}
                    src={Storage + `/icon/modules/${value}`}
                  />
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "data.name",
          },
          {
            Header: "Slug",
            accessor: "data.slug",
          },
          {
            Header: "Description",
            accessor: "data.description",
          },
          {
            Header: "Status",
            accessor: "data.status",
            Cell: ({ cell: { value } }) => {
              return <div>{value === 1 ? <p>Active</p> : <p>Not Active</p>}</div>;
            },
          },
        ],
      },
    ],
    [handleDelete, service]
  );

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchAppModules();
  }, [fetchAppModules, token]);

  if (!isLoaded) {
    return (
      <>
        <AppSpinner title="Loading data Modules . . ." />
      </>
    );
  }

  if (appModules.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center min-vh-100">
          <button className="btn btn-secondary px-5 py-3" type="button" disabled>
            Data Kosong . . .
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={appModules} />
    </div>
  );
}

import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import { API } from ".././Config/Config";

import Table from "./TableRoles";

export default function DataTableRoles() {
  const apiUrl = API;

  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [roles, setRoles] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchRoles = useCallback(async () => {
    await axios.get(apiUrl + "/roles/list", header).then((response) => {
      setRoles(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(apiUrl + `/roles/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchRoles();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, fetchRoles, header]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/roles/url-access/${value}`} className="btn btn-sm btn-primary m-1">
                    <i className="bi bi-link-45deg"></i>
                  </Link>
                  <Link to={`/roles/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "data.name",
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchRoles();
  }, [token, fetchRoles]);

  if (!isLoaded) {
    return <AppSpinner title="Loading data roles . . ." />;
  }

  return (
    <>
      <div className="container-fluid table-responsive">
        <Table columns={columns} data={roles} />
      </div>
    </>
  );
}

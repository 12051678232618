import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import AppSpinner from "../../Components/AppSpinner";
import Swal from "sweetalert2";
import { API, Storage } from "../../Config/Config";

export default function DataTransVisa() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //api
  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const { visa, id } = useParams();
  const [success, setSuccess] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [listNationality, setListNationality] = useState([]);
  const [listTransport, setListTransport] = useState([]);
  const [listAirport, setListAirport] = useState([]);
  const [validation, setValidation] = useState([]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Transaction Legal", slug: "/transaction-legal", status: "" },
    { id: "3", title: "Detail Transaction", slug: `/transaction-legal/detail/${visa}`, status: "" },
    { id: "4", title: "Data Detail Document Request", slug: "/transaction-legal/detail/data", status: "active" },
  ];

  const [fullName, setFullName] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [nationality, setNationality] = useState("");
  const [addressID, setAddressID] = useState("");
  const [addressDomicile, setAddressDomicile] = useState("");
  const [filePhoto, setFilePhoto] = useState([]);
  const [fileIDCard, setFileIDCard] = useState([]);
  const [filePassport, setFilePassport] = useState([]);
  const [fileSavingAccount, setFileSavingAccount] = useState([]);
  const [fileVaccine, setFileVaccine] = useState([]);
  const [estArrival, setEstArrival] = useState("");
  const [transportType, setTransportType] = useState("");
  const [airportDestination, setAirportDestination] = useState("");
  const [fileTicket, setFileTicket] = useState([]);
  const [vvipEscort, setVvipEscort] = useState("");

  const [filePreviewIDCard, setFilePreviewIDCard] = useState([]);
  const [filePreviewPassport, setFilePreviewPassport] = useState([]);
  const [filePreviewSavingAccount, setFilePreviewSavingAccount] = useState([]);
  const [filePreviewVaccine, setFilePreviewVaccine] = useState([]);
  const [filePreviewBoardingTicket, setFilePreviewBoardingTicket] = useState([]);

  const fetchParameter = useCallback(async () => {
    await axios.get(api + `/transaction-legal/visa/listParameter`, header).then((response) => {
      let nationality = response.data.data.nationality;
      let transport = response.data.data.transport_type;
      let airport = response.data.data.airport_destination;

      setListNationality(nationality);
      setListTransport(transport);
      setListAirport(airport);
    });
  }, [api, header]);

  const fetchDataTransVisa = useCallback(async () => {
    await axios
      .get(api + `/transaction-legal/visa/data/${id}`, header)
      .then(function (response) {
        let value = response.data.data.data;
        setFullName(value.full_name);
        setPassportNumber(value.passport_number);
        setNationality(value.nationality);
        setAddressID(value.address_card_id);
        setAddressDomicile(value.address_domicile);
        setFilePhoto(value.file_photo);
        setFilePreviewIDCard(value.file_id_card);
        // setFilePassport(value.file_passport);
        setFilePreviewPassport(value.file_passport);
        // setFileSavingAccount(value.file_saving_account);
        setFilePreviewSavingAccount(value.file_saving_account);
        // setFileVaccine(value.file_vaccine);
        setFilePreviewVaccine(value.file_vaccine);
        setEstArrival(value.est_arrival);
        setTransportType(value.transport_type);
        setAirportDestination(value.airport_destination);
        // setFileTicket(value.file_ticket);
        setFilePreviewBoardingTicket(value.file_ticket);
        setVvipEscort(value.vvip_escort);
        setIsLoaded(true);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [api, header, id]);

  useEffect(() => {
    fetchDataTransVisa();
  }, [fetchDataTransVisa]);

  /**
   * Handle input null
   */
  if (filePhoto === null) {
    setFilePhoto("");
  }
  if (filePassport === null) {
    setFilePassport("");
  }
  if (fileSavingAccount === null) {
    setFileSavingAccount("");
  }
  if (fileVaccine === null) {
    setFileVaccine("");
  }
  if (estArrival === null) {
    setEstArrival("");
  }
  if (transportType === null) {
    setTransportType("");
  }
  if (airportDestination === null) {
    setAirportDestination("");
  }
  if (fileTicket === null) {
    setFileTicket("");
  }
  if (vvipEscort === null) {
    setVvipEscort("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Updating Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();

    formData.append("tx_full_name", fullName);
    formData.append("tx_passport_number", passportNumber);
    formData.append("select_nationality", nationality);
    formData.append("tx_area_address_id", addressID);
    formData.append("tx_area_address_domicile", addressDomicile);
    formData.append("file_id_card", fileIDCard);
    formData.append("file_upload_photo", filePhoto);
    formData.append("file_upload_passport", filePassport);
    formData.append("file_saving_account", fileSavingAccount);
    formData.append("file_vaccine", fileVaccine);
    formData.append("date_est_arrival", estArrival);
    formData.append("select_transport_type", transportType);
    formData.append("select_airport_destination", airportDestination);
    formData.append("file_boarding_ticket", fileTicket);
    formData.append("check_vvip_escort", vvipEscort);

    // console.log(...formData);

    await axios
      .post(api + `/transaction-legal/visa/${visa}/update/${id}`, formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          // console.log(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2500,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const previewIDCard = useCallback(async () => {
    await axios
      .get(api + `/transaction-legal/visa/${visa}/preview-id-card/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        // console.log(response);
        const file = URL.createObjectURL(response.data);
        const iframe = document.querySelector("iframe");
        if (iframe?.src) window.open((iframe.src = file));
        URL.revokeObjectURL(file);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [api, token, visa, id]);

  const previewPassport = useCallback(async () => {
    await axios
      .get(api + `/transaction-legal/visa/${visa}/preview-passport/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        // console.log(response);
        const file = URL.createObjectURL(response.data);
        const iframe = document.querySelector("iframe");
        if (iframe?.src) window.open((iframe.src = file));
        URL.revokeObjectURL(file);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [api, token, visa, id]);

  const previewSavingAccount = useCallback(async () => {
    await axios
      .get(api + `/transaction-legal/visa/${visa}/preview-saving-account/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        // console.log(response);
        const file = URL.createObjectURL(response.data);
        const iframe = document.querySelector("iframe");
        if (iframe?.src) window.open((iframe.src = file));
        URL.revokeObjectURL(file);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [api, token, visa, id]);

  const previewVaccine = useCallback(async () => {
    await axios
      .get(api + `/transaction-legal/visa/${visa}/preview-vaccine/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = URL.createObjectURL(response.data);
        const iframe = document.querySelector("iframe");
        if (iframe?.src) window.open((iframe.src = file));
        URL.revokeObjectURL(file);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [api, token, visa, id]);

  const previewBoardingTicket = useCallback(async () => {
    await axios
      .get(api + `/transaction-legal/visa/${visa}/preview-boarding-ticket/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = URL.createObjectURL(response.data);
        const iframe = document.querySelector("iframe");
        if (iframe?.src) window.open((iframe.src = file));
        URL.revokeObjectURL(file);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [api, token, visa, id]);

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchParameter();
  }, [token, fetchParameter]);

  if (!isLoaded) {
    return (
      <>
        <div className="container-fluid p-0 m-0 mb-5">
          <AppHeader />
          <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
            <div className="flex-grow-1 py-2">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
          </div>
          <AppSpinner title="Loading Detail Transaction . . ." />
        </div>
      </>
    );
  }

  return (
    <>
      <AppHeader />
      {success ? (
        <>
          <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
            <div className="flex-grow-1 py-2">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="row justify-content-around">
                      <div className="col-12">
                        <h6 className="card-title text-center mb-3">
                          <strong>Detail Document Request</strong>
                        </h6>
                      </div>
                      {/* <hr className="mt-2" style={{ width: "98%" }} /> */}
                    </div>
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>Photo</td>
                            <td>
                              <img src={Storage + `/Services/Legal/Modules/VISA/Transaction/${visa}/${filePhoto}`} alt="" className="img-thumbnail" style={{ maxWidth: "6.5rem", maxHeight: "6.5rem" }} />

                              <input type="file" name="file_upload_photo" className={`form-control mt-3 ${validation.file_upload_photo ? "is-invalid" : ""}`} onChange={(e) => setFilePhoto(e.target.files[0])} />
                              {validation.file_upload_photo && (
                                <div className={`invalid-feedback ${validation.file_upload_photo ? "d-block mb-3" : "d-none"}`}>
                                  <i className="bi bi-exclamation-circle me-2"></i>
                                  {validation.file_upload_photo[0]}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Full Name</td>
                            <td>
                              <input type="text" className={`form-control ${validation.tx_full_name ? "is-invalid" : ""}`} name="tx_full_name" defaultValue={fullName} onChange={(e) => setFullName(e.target.value)} />
                              {validation.tx_full_name && (
                                <div className={`invalid-feedback ${validation.tx_full_name ? "d-block mb-3" : "d-none"}`}>
                                  <i className="bi bi-exclamation-circle me-2"></i>
                                  {validation.tx_full_name[0]}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>No Passport</td>
                            <td>
                              <input type="text" className={`form-control ${validation.tx_passport_number ? "is-invalid" : ""}`} name="tx_passport_number" defaultValue={passportNumber} onChange={(e) => setPassportNumber(e.target.value)} />
                              {validation.tx_passport_number && (
                                <div className={`invalid-feedback ${validation.tx_passport_number ? "d-block mb-3" : "d-none"}`}>
                                  <i className="bi bi-exclamation-circle me-2"></i>
                                  {validation.tx_passport_number[0]}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Nationality</td>
                            <td>
                              <select
                                className={`form-select ${validation.select_nationality ? "is-invalid" : ""}`}
                                name="select_nationality"
                                aria-label="Default select example"
                                value={nationality}
                                onChange={(e) => setNationality(e.target.value)}
                              >
                                <option value="">Select Nationality</option>
                                {listNationality.map((value, index) => (
                                  <option key={index} value={value.id}>
                                    {value.name}
                                  </option>
                                ))}
                              </select>
                              {validation.select_nationality && (
                                <div className={`invalid-feedback ${validation.select_nationality ? "d-block mb-3" : "d-none"}`}>
                                  <i className="bi bi-exclamation-circle me-2"></i>
                                  {validation.select_nationality[0]}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Address (According to ID)</td>
                            <td>
                              <textarea
                                className={`form-control ${validation.tx_area_address_id ? "is-invalid" : ""}`}
                                name="tx_area_address_id"
                                defaultValue={addressID}
                                onChange={(e) => setAddressID(e.target.value)}
                                id="floatingTextarea"
                              ></textarea>
                              {validation.tx_area_address_id && (
                                <div className={`invalid-feedback ${validation.tx_area_address_id ? "d-block mb-3" : "d-none"}`}>
                                  <i className="bi bi-exclamation-circle me-2"></i>
                                  {validation.tx_area_address_id[0]}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Current Address</td>
                            <td>
                              <textarea
                                className={`form-control ${validation.tx_area_address_domicile ? "is-invalid" : ""}`}
                                name="tx_area_address_domicile"
                                defaultValue={addressDomicile}
                                onChange={(e) => setAddressDomicile(e.target.value)}
                                id="floatingTextarea"
                              ></textarea>
                              {validation.tx_area_address_domicile && (
                                <div className={`invalid-feedback ${validation.tx_area_address_domicile ? "d-block mb-3" : "d-none"}`}>
                                  <i className="bi bi-exclamation-circle me-2"></i>
                                  {validation.tx_area_address_domicile[0]}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>ID Card</td>
                            <td>
                              <div>
                                <p className="float-start">File : {fileIDCard.length < 1 ? filePreviewIDCard : fileIDCard.name}</p>
                                {fileIDCard.length < 1 ? (
                                  <Link className="btn btn-sm btn-primary ms-3 float-end" onClick={previewIDCard}>
                                    <i className="bi bi-file-earmark-text-fill"></i> Preview ID Card
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                              <input type="file" name="file_upload_id_card" className={`form-control mt-3 ${validation.file_id_card ? "is-invalid" : ""}`} onChange={(e) => setFileIDCard(e.target.files[0])} />
                              {validation.file_upload_id_card && (
                                <div className={`invalid-feedback ${validation.file_upload_id_card ? "d-block mb-3" : "d-none"}`}>
                                  <i className="bi bi-exclamation-circle me-2"></i>
                                  {validation.file_upload_id_card[0]}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Passport</td>
                            <td>
                              {/* <img src={Storage + `/Services/Legal/Modules/VISA/Transaction/${visa}/${filePassport}`} alt="" className="mt-1" style={{ maxWidth: "10rem", maxHeight: "10rem" }} /> */}
                              <div>
                                <p className="float-start">File : {filePassport.length < 1 ? filePreviewPassport : filePassport.name}</p>
                                {filePassport.length < 1 ? (
                                  <Link className="btn btn-sm btn-primary ms-3 float-end" onClick={previewPassport}>
                                    <i className="bi bi-file-earmark-text-fill"></i> Preview Passport
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                              <input type="file" name="file_upload_passport" className={`form-control mt-3 ${validation.file_upload_passport ? "is-invalid" : ""}`} onChange={(e) => setFilePassport(e.target.files[0])} />
                              {validation.file_upload_passport && (
                                <div className={`invalid-feedback ${validation.file_upload_passport ? "d-block mb-3" : "d-none"}`}>
                                  <i className="bi bi-exclamation-circle me-2"></i>
                                  {validation.file_upload_passport[0]}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Saving Bank Account</td>
                            <td>
                              {filePreviewSavingAccount === null || filePreviewSavingAccount === "" ? (
                                <>
                                  <p>-</p>
                                  <input type="file" name="file_saving_account" className={`form-control mt-3 ${validation.file_saving_account ? "is-invalid" : ""}`} onChange={(e) => setFileSavingAccount(e.target.files[0])} />
                                  {validation.file_saving_account && (
                                    <div className={`invalid-feedback ${validation.file_saving_account ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.file_saving_account[0]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* <img src={Storage + `/Servic  es/Legal/Modules/VISA/Transaction/${visa}/${fileSavingAccount}`} alt="" className="mt-1 rounded" style={{ maxWidth: "10rem", maxHeight: "10rem" }} /> */}
                                  <p className="float-start">File : {fileSavingAccount.length < 1 ? filePreviewSavingAccount : fileSavingAccount.name}</p>
                                  {fileSavingAccount.length < 1 ? (
                                    <Link className="btn btn-sm btn-primary ms-3 float-end" onClick={previewSavingAccount}>
                                      <i className="bi bi-file-earmark-text-fill"></i> Preview Saving Account
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                  <input type="file" name="file_saving_account" className={`form-control mt-3 ${validation.file_saving_account ? "is-invalid" : ""}`} onChange={(e) => setFileSavingAccount(e.target.files[0])} />
                                  {validation.file_saving_account && (
                                    <div className={`invalid-feedback ${validation.file_saving_account ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.file_saving_account[0]}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Vaccine Sertificate</td>
                            <td>
                              {filePreviewVaccine === "" || filePreviewVaccine === null ? (
                                <>
                                  <p>-</p>
                                  <input type="file" name="file_vaccine" className={`form-control mt-3 ${validation.file_vaccine ? "is-invalid" : ""}`} onChange={(e) => setFileVaccine(e.target.files[0])} />
                                  {validation.file_vaccine && (
                                    <div className={`invalid-feedback ${validation.file_vaccine ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.file_vaccine[0]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* <img src={Storage + `/Services/Legal/Modules/VISA/Transaction/${visa}/${fileVaccine}`} alt="" className="mt-1 rounded" style={{ maxWidth: "10rem", maxHeight: "10rem" }} /> */}
                                  <p className="float-start">File : {fileVaccine.length < 1 ? filePreviewVaccine : fileVaccine.name}</p>
                                  {fileVaccine.length < 1 ? (
                                    <Link className="btn btn-sm btn-primary ms-3 float-end" onClick={previewVaccine}>
                                      <i className="bi bi-file-earmark-text-fill"></i> Preview Vaccine
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                  <input type="file" name="file_vaccine" className={`form-control mt-3 ${validation.file_vaccine ? "is-invalid" : ""}`} onChange={(e) => setFileVaccine(e.target.files[0])} />
                                  {validation.file_vaccine && (
                                    <div className={`invalid-feedback ${validation.file_vaccine ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.file_vaccine[0]}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Estimated Arrival</td>
                            <td>
                              {estArrival === null ? (
                                <>
                                  <input
                                    type="text"
                                    name="date_est_arrival"
                                    className={`form-control ${validation.date_est_arrival ? "is-invalid" : ""}`}
                                    placeholder="Input Estimated Arrival"
                                    onFocus={(e) => (e.target.type = "date")}
                                    onBlur={(e) => (e.target.type = "text")}
                                    onChange={(e) => setEstArrival(e.target.value)}
                                  />
                                  {validation.date_est_arrival && (
                                    <div className={`invalid-feedback ${validation.date_est_arrival ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.date_est_arrival[0]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    name="date_est_arrival"
                                    className={`form-control ${validation.date_est_arrival ? "is-invalid" : ""}`}
                                    placeholder="Input Estimated Arrival"
                                    onFocus={(e) => (e.target.type = "date")}
                                    onBlur={(e) => (e.target.type = "text")}
                                    defaultValue={estArrival}
                                    onChange={(e) => setEstArrival(e.target.value)}
                                  />
                                  {validation.date_est_arrival && (
                                    <div className={`invalid-feedback ${validation.date_est_arrival ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.date_est_arrival[0]}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Transportation Type</td>
                            <td>
                              {transportType === null ? (
                                <>
                                  <select className={`form-select ${validation.select_transport_type ? "is-invalid" : ""}`} name="select_transport_type" aria-label="Default select example" onChange={(e) => setTransportType(e.target.value)}>
                                    <option value="">Select Transport Type</option>
                                    {listTransport.map((value, index) => (
                                      <option key={index} value={value.id}>
                                        {value.name}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.select_transport_type && (
                                    <div className={`invalid-feedback ${validation.select_transport_type ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.select_transport_type[0]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <select
                                    className={`form-select ${validation.select_transport_type ? "is-invalid" : ""}`}
                                    name="select_transport_type"
                                    value={transportType}
                                    aria-label="Default select example"
                                    onChange={(e) => setTransportType(e.target.value)}
                                  >
                                    <option value="">Select Transportation Type</option>
                                    {listTransport.map((value, index) => (
                                      <option key={index} value={value.id}>
                                        {value.name}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.select_transport_type && (
                                    <div className={`invalid-feedback ${validation.select_transport_type ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.select_transport_type[0]}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Airport Destination</td>
                            <td>
                              {airportDestination === null ? (
                                <>
                                  <select
                                    className={`form-select ${validation.select_airport_destination ? "is-invalid" : ""}`}
                                    name="select_airport_destination"
                                    aria-label="Default select example"
                                    onChange={(e) => setAirportDestination(e.target.value)}
                                  >
                                    <option value="">Select Airport Destination</option>
                                    {listAirport.map((value, index) => (
                                      <option key={index} value={value.id}>
                                        {value.name}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.select_airport_destination && (
                                    <div className={`invalid-feedback ${validation.select_airport_destination ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.select_airport_destination[0]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <select
                                    className={`form-select ${validation.select_airport_destination ? "is-invalid" : ""}`}
                                    name="select_airport_destination"
                                    aria-label="Default select example"
                                    value={airportDestination}
                                    onChange={(e) => setAirportDestination(e.target.value)}
                                  >
                                    <option value="">Select Airport Destination</option>
                                    {listAirport.map((value, index) => (
                                      <option key={index} value={value.id}>
                                        {value.name}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.select_airport_destination && (
                                    <div className={`invalid-feedback ${validation.select_airport_destination ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.select_airport_destination[0]}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Boarding Ticket</td>
                            <td>
                              {filePreviewBoardingTicket === "" || filePreviewBoardingTicket === null ? (
                                <>
                                  <p>-</p>
                                  <input type="file" name="file_boarding_ticket" className={`form-control mt-3 ${validation.file_boarding_ticket ? "is-invalid" : ""}`} onChange={(e) => setFileTicket(e.target.files[0])} />
                                  {validation.file_boarding_ticket && (
                                    <div className={`invalid-feedback ${validation.file_boarding_ticket ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.file_boarding_ticket[0]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* <img src={Storage + `/Services/Legal/Modules/VISA/Transaction/${visa}/${fileTicket}`} alt="" className="mt-1 rounded" style={{ maxWidth: "10rem", maxHeight: "10rem" }} /> */}
                                  <p className="float-start">File : {fileTicket.length < 1 ? filePreviewBoardingTicket : fileTicket.name}</p>
                                  {fileTicket.length < 1 ? (
                                    <Link className="btn btn-sm btn-primary ms-3 float-end" onClick={previewBoardingTicket}>
                                      <i className="bi bi-file-earmark-text-fill"></i> Preview Vaccine
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                  <input type="file" name="file_boarding_ticket" className={`form-control mt-3 ${validation.file_boarding_ticket ? "is-invalid" : ""}`} onChange={(e) => setFileTicket(e.target.files[0])} />
                                  {validation.file_boarding_ticket && (
                                    <div className={`invalid-feedback ${validation.file_boarding_ticket ? "d-block mb-3" : "d-none"}`}>
                                      <i className="bi bi-exclamation-circle me-2"></i>
                                      {validation.file_boarding_ticket[0]}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>VVIP Escort</td>
                            <td>
                              <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" value={!vvipEscort ? 1 : 0} defaultChecked={vvipEscort ? 1 : 0} onChange={(e) => setVvipEscort(e.target.value)} role="switch" id="flexSwitchCheckDefault" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </form>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Navigate to={`/transaction-legal/detail/${visa}`} replace={true} />
      )}
      <AppFooter />
    </>
  );
}

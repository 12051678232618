import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
import Swal from "sweetalert2";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API, Storage } from ".././Config/Config";

export default function EditServices() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //get api
  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Services", slug: "/services", status: "" },
    { id: "3", title: "Edit Services", slug: "/services/edit", status: "active" },
  ];

  const [success, setSuccess] = useState(true);

  const { id } = useParams();

  const [icon, setIcon] = useState([]);
  const [pict, setPict] = useState([]);
  const [iconUrls, setIconUrls] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [status, setStatus] = useState("");
  const [formData, setFormData] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    if (icon.length < 1) return;
    const newIconUrls = [];
    icon.forEach((icons) => newIconUrls.push(URL.createObjectURL(icons)));
    setIconUrls(newIconUrls);
  }, [icon]);

  function onIconChange(e) {
    setIcon([...e.target.files]);
  }

  useEffect(() => {
    axios
      .get(api + `/services/data/${id}`, header)
      .then(function (response) {
        let service = response.data.data.data;
        setPict(service.icon);
        setName(service.name);
        setDescription(service.description);
        setSlug(service.slug);
        setStatus(service.status);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [api, header, id]);

  useEffect(() => {
    setFormData(new FormData());
  }, []);

  const handleToggle = () => {
    setStatus(!status);
    var data = !status ? 1 : 0;
    // console.log(data);
    formData.append("status", data);
  };

  const updateService = async (e) => {
    e.preventDefault();

    formData.append("name", name);
    formData.append("description", description);
    formData.append("slug", slug);
    formData.append("icon", icon[0]);

    // console.log(...formData);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/services/update/${id}`, formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          console.log(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          // timer: 2000,
        });
      });
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <AppHeader />
      {success ? (
        <>
          <div className="d-flex flex-row align-items-center align-self-center ps-4 pe-4 pt-2 pb-3 border-bottom mb-3">
            <div className="flex-grow-1">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>
              <a className="btn btn-primary" href="/services" role="button">
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Back
              </a>
            </div>
          </div>
          <div className="container-fluid">
            <form className="p-4" encType="multipart/form-data" onSubmit={updateService}>
              <div className="alert alert-info" role="alert">
                <p className="small p-0 m-0">
                  <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                  Sign <span className="text-danger">*</span> is a mandatory data
                </p>
              </div>
              {validation.errMessage && (
                <div className={`alert alert-danger ${validation.errMessage ? "d-block" : "d-none"}`}>
                  <i className="bi bi-exclamation-circle"></i>
                  {validation.errMessage[0]}
                </div>
              )}
              <div className="mb-3">
                <img
                  src={Storage + `/icon/services/${pict}`}
                  alt="Icons"
                  style={{ display: icon.length < 1 ? "block" : "none", marginLeft: "auto", marginRight: "auto", width: "125px", height: "125px", borderRadius: "50%", marginBottom: "2rem" }}
                />
                {iconUrls.map((imageSrc) => (
                  <img src={imageSrc} alt="Icons" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "125px", height: "125px", borderRadius: "50%", marginBottom: "2rem" }} />
                ))}
                <label htmlFor="formFile" className="form-label">
                  *Icon Services
                </label>
                <input type="file" multiple accept="image/*" onChange={onIconChange} name="icon" className="form-control" />
                {validation.photo && <div className="alert alert-danger">{validation.photo[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">*Name</label>
                {validation.name && <div className="alert alert-danger">{validation.name[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                <label htmlFor="floatingTextarea2">Description</label>
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">*Slug</label>
                {validation.slug && <div className="alert alert-danger">{validation.slug[0]}</div>}
              </div>
              <div className="form-check form-switch">
                <input className="form-check-input" onChange={handleToggle} checked={status} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  *Status
                </label>
              </div>
              <div className="form-floating mb-3 text-end">
                <button type="submit" className="btn btn-primary px-3">
                  <span className="me-2">
                    <i className="bi bi-save"></i>
                  </span>
                  Save
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <Navigate to="/services" replace={true} />
      )}
      <AppFooter />
    </>
  );
}

import styled from "styled-components";

export const Wizard = styled.div`
  margin-left: 30px;
  position: relative;
`;
export const Line = styled.div`
  width: 2px;
  background-color: #ccc;
  position: absolute;
  margin-left: -15px;
  margin-top: 20px;
  height: 75px;
`;
export const Step = styled.div`
  padding: 10px 0;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      border-radius: 20px;
      background-color: #00cc66;
      position: absolute;
      margin-left: -20px;
      top: 17px;
  `;

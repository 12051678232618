import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import { API } from ".././Config/Config";

import Table from "./TablePermission";

export default function DataTablePermission() {
  const apiUrl = API;

  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);
  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [permission, setPermission] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchPermission = useCallback(async () => {
    await axios.get(apiUrl + "/permissions/list", header).then((response) => {
      setPermission(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(apiUrl + `/permissions/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchPermission();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, fetchPermission, header]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/permission/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "data.name",
          },
          {
            Header: "Slug",
            accessor: "data.slug",
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchPermission();
  }, [token, fetchPermission]);

  if (!isLoaded) {
    return <AppSpinner title="Loading data permissions ..." />;
  }
  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={permission} />
    </div>
  );
}

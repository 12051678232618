// import { CountertopsOutlined } from "@mui/icons-material";
import React, { useState, useMemo } from "react";
import { useTable, useFilters, useSortBy, usePagination, useAsyncDebounce, useGlobalFilter } from "react-table";

export default function TableTrack({ columns, data }) {
  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 800);

    return (
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records ...`}
      />
    );
  }

  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        placeholder={`Search ${count} records ...`}
      />
    );
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    // setFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex flex-row align-items-center align-self-center">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              Search :
            </span>
            <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
          </div>
        </div>
        <div className="table-responsive">
          <table {...getTableProps()} className="table table-striped table-borderless">
            <thead className="table-primary">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((columns) => {
                    return columns.hideHeader === false ? null : (
                      <th {...columns.getHeaderProps(columns.getSortByToggleProps())}>
                        {columns.render("Header")}
                        <span>
                          {columns.isSorted ? (
                            columns.isSortedDesc ? (
                              <i className="bi bi-arrow-down text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                            ) : (
                              <i className="bi bi-arrow-up text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                            )
                          ) : columns.disableSortBy === true ? null : (
                            <i className="bi bi-arrow-down-up text-muted ms-1" style={{ fontSize: "0.8rem" }}></i>
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="table-group-divider">
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="fixed">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-center flex-wrap mb-5">
          <div className="px-2">
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-sm">
                <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  <span className="page-link px-3">First</span>
                </li>
                <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                  <span className="page-link px-2">&laquo;</span>
                </li>
                <li className="page-item px-1">
                  <span className="page-link px-3 text-wrap">
                    Page {pageIndex + 1} of {pageOptions.length}{" "}
                  </span>
                </li>
                <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                  <span className="page-link px-2">&raquo;</span>
                </li>
                <li className="page-item me-3" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  <span className="page-link px-3">Last</span>
                </li>
              </ul>
            </nav>
          </div>
          <div className="px-2">
            <select
              className="form-select form-select-sm"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../Config/Config";
import AppSpinner from "../Components/AppSpinner";

export default function ModalCustomerNotes(props) {
  // -- General Setting -- //
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }, [token]);

  const [isLoading, setIsLoading] = useState(
    props.method === "EDIT" ? false : true
  );
  const [validation, setValidation] = useState([]);
  const [notes, setNotes] = useState("");

  // -- End General Setting -- //

  // -- Load Data Customer Notes -- //
  const dataCustomerNotes = useCallback(async () => {
    if (props.method === "EDIT" && props.notesID !== "0") {
      await axios
        .get(api + `/customers/notes/data/${props.notesID}`, header)
        .then(function (response) {
          setIsLoading(true);
          let value = response.data.data.data;
          setNotes(value.notes);
        })
        .catch(function (error) {
          // console.log(error)
        });
    }
  }, [api, header, props]);

  useEffect(() => {
    dataCustomerNotes();
  }, [dataCustomerNotes]);
  // -- End Load Data Customer Notes -- //

  // -- Save Data Customer Notes -- //
  const saveNotes = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("notes", notes);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (props.method === "ADD") {
      formData.append("customer_id", props.customerID);

      await axios
        .post(api + `/customers/notes/create`, formData, header)
        .then(function (response) {
          if (response.data.status === "error") {
            setValidation(response.data.data);
            Swal.fire({
              icon: "error",
              text: response.data.message,
              timer: 2000,
            });
          } else if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });

            setValidation([]);
            setNotes("");

            props.close();
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else if (props.method === "EDIT" && props.notesID !== "0") {
      await axios
        .post(
          api + `/customers/notes/update/${props.notesID}`,
          formData,
          header
        )
        .then(function (response) {
          if (response.data.status === "error") {
            setValidation(response.data.data);
            Swal.fire({
              icon: "error",
              text: response.data.message,
              timer: 2000,
            });
          } else if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });

            setValidation([]);
            setNotes("");

            props.close();
          }
        })
        .catch(function (error) {
          // console.log(error)
        });
    }
  };
  // -- End Save Data Customer Notes -- //

  return (
    <>
      <Modal show={props.show} centered size="lg">
        {isLoading === false ? (
          <div className="d-flex justify-content-center align-items-center align-self-center">
            <AppSpinner title="Loading data customer note(s) ..." />
          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>
                <div className="fs-5 text-center">{props.title}</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="px-3 py-1" onSubmit={saveNotes}>
                <div className="form-floating mb-3">
                  <textarea
                    className={`form-control ${
                      validation.notes ? "is-invalid" : ""
                    }`}
                    placeholder="Notes"
                    id="floatingTextarea"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  ></textarea>
                  <label htmlFor="floatingTextarea">Notes</label>
                  {validation.notes && (
                    <div className="alert alert-danger small p-1 px-2 mt-1">
                      {validation.notes[0]}
                    </div>
                  )}
                </div>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary px-2"
                    onClick={() => {
                      setValidation([]);
                      props.close();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary px-3">
                    Save
                  </button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import { API, Storage } from "../Config/Config";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function ViewCustomerMemberClient() {
  const { code } = useParams();

  const header = useMemo(() => {
    return {
      headers: { "Content-type": "application/json" },
    };
  }, []);

  const [idCust, setIDCust] = useState("");
  const [photo, setPhoto] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationality, setNationality] = useState("");
  const [gender, setGender] = useState("");
  const [passport, setPassport] = useState("");
  const [services, setServices] = useState([]);
  const [expDate, setExpDate] = useState("");

  const getData = async () => {
    await axios
      .get(API + `/member/${code}`, header)
      .then(function (response) {
        if (response.data.status === "success") {
          let customers = response.data.data.data;
          // console.log(customers);
          setIDCust(customers.customer.id);
          setPhoto(customers.customer.photo);
          setFirstName(customers.customer.first_name);
          setLastName(customers.customer.last_name);
          setNationality(customers.customer.param_nationality.name);
          setGender(customers.customer.param_gender.name);
          setExpDate(customers.expired_date);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const newDate = new Date();

  const currentDate = newDate
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="d-flex h-100">
      <div className="d-flex w-100 h-100 pt-3 pb-5 px-3 mx-auto flex-column layout-container">
        <header className="mb-auto">
          <div className="text-center">
            <img src="/assets/brand/maxxs-logo-h-text-black.png" alt="Maxx's Group International" height="65" />
          </div>
        </header>
        <main className="py-5 ">
          <div>
            <p className="lead text-center">Customer Profile</p>
            <img src={Storage + `/user_profile/${idCust}/${photo}`} width={200} height={200} className="rounded mx-auto d-block" alt="..." />
            <p className="p-1 text-center">
              <strong>
                {firstName} {lastName}
              </strong>
            </p>
            <table className="table table-sm">
              <tbody>
                <tr className="align-middle small">
                  <td>Nationality</td>
                  <td scope="row">{nationality}</td>
                </tr>
                <tr className="align-middle small">
                  <td>Gender</td>
                  <td scope="row">{gender}</td>
                </tr>
                {/* <tr className="align-middle small">
                  <td>Passport</td>
                  <td scope="row">311911811</td>
                </tr> */}
                {/* <tr className="align-middle small">
                  <td rowSpan="2">Services</td>
                  <td>
                    <sub>Branch: Ubud, Bali</sub>
                    <br />
                    INVESTOR KITAS
                    <br />
                    <sup>Expired: December 27, 2023</sup>
                  </td>
                </tr>
                <tr className="align-middle small">
                  <td>
                    <sub>Branch: Ubud, Bali</sub>
                    <br />
                    PMA
                    <br />
                    <sup>Expired: -</sup>
                  </td>
                </tr> */}
                <tr className="align-middle small">
                  <td>Status</td>
                  <td>
                    {currentDate.toString() <= expDate.toString() ? (
                      <span className="badge align-items-center py-2 px-4 my-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill">Active</span>
                    ) : (
                      <span className="badge align-items-center py-2 px-4 my-1 text-success-emphasis bg-danger-subtle border border-danger-subtle rounded-pill">Inactive</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
        <footer className="mt-auto text-center" style={{ color: "#00000090" }}>
          <p className="small mb-4">
            &copy; 2022 - 2023
            <br />
            <strong>
              <a href="https://maxxsgroup.com/" target="_blank" style={{ color: "#00000090" }} className="link-offset-2 link-underline link-underline-opacity-0">
                Maxx's Group International
              </a>
            </strong>
            <br />
            All rights reserved
          </p>
          <p className="small mb-0">
            Lippo Kuningan Building 19<sup>th</sup> Floor, Unit B<br />
            Jl. HR. Rasuna Said, Kav. B12, Kuningan, Setiabudi
            <br />
            Jakarta Selatan, DKI Jakarta, Indonesia 12940
          </p>
          <p className="small">
            Telp. +62 21 5081 3027 | Email.{" "}
            <a href="mailto:hello@maxxsgroup.com" target="_blank" style={{ color: "#00000090" }} className="link-offset-2 link-underline link-underline-opacity-0">
              hello@maxxsgroup.com
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
}

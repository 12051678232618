import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
// import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API } from ".././Config/Config";

export default function EditUrl() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //get api from local storage
  const apiUrl = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "URL", slug: "/url", status: "" },
    { id: "3", title: "Edit URL", slug: "/url/edit", status: "active" },
  ];

  const [success, setSuccess] = useState(true);

  const { id } = useParams();

  const [parent, setParent] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [icon, setIcon] = useState("");
  const [order, setOrder] = useState("");
  const [position, setPosition] = useState("");
  const [validation, setValidation] = useState([]);

  const [listParent, setListParent] = useState([]);

  const handleParent = useCallback(
    (e) => {
      setPosition(e.target.value);
      axios.get(apiUrl + "/urls/list_parent_by_position?position=" + e.target.value, header).then((response) => {
        setListParent(response.data.data);
      });
    },
    [apiUrl, header]
  );

  useEffect(() => {
    axios
      .get(apiUrl + `/urls/data/${id}`, header)
      .then(function(response) {
        let urls = response.data.data.data;
        setParent(urls.parent_id);
        setName(urls.name);
        setUrl(urls.url);
        setIcon(urls.icon);
        setOrder(urls.order);
        setPosition(urls.position);
      })
      .catch(function(error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [apiUrl, header, id]);

  //handle input null
  if (parent === null) {
    setParent("");
  }

  const updateUrl = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("parent", parent);
    formData.append("name", name);
    formData.append("url", url);
    formData.append("icon", icon);
    formData.append("order", order);
    formData.append("position", position);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await axios.post(apiUrl + `/urls/update/${id}`, formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/url" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form onSubmit={updateUrl}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                {validation.errMessage && (
                  <div className={`alert alert-danger ${validation.errMessage ? "d-block" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle"></i>
                    {validation.errMessage[0]}
                  </div>
                )}
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.position ? "is-invalid" : ""}`} value={position} onChange={handleParent} required>
                    <option disabled>Select Position</option>
                    <option value="master">Master</option>
                    <option value="database">Database</option>
                    <option value="settings">Settings</option>
                    <option value="transaction">Transaction</option>
                    <option value="tools">Tools</option>
                  </select>
                  <label htmlFor="floatingSelect">
                    Position <sup className="text-danger">*</sup>
                  </label>
                  {validation.position && (
                    <div className={`invalid-feedback ${validation.position ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.position[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <select className="form-select" onChange={(e) => setParent(e.target.value)} value={parent || ""} id="floatingSelect" aria-label="Floating label select example">
                    <option value="">Select Parent</option>
                    {listParent.map((data, id) => (
                      <option key={id} value={data.data.id}>
                        {data.data.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Parent</label>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} className={`form-control ${validation.name ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Name <sup className="text-danger">*</sup>
                  </label>
                  {validation.name && (
                    <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.name[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} className={`form-control ${validation.url ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Url <sup className="text-danger">*</sup>
                  </label>
                  {validation.url && (
                    <div className={`invalid-feedback ${validation.url ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.url[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={icon} onChange={(e) => setIcon(e.target.value)} className={`form-control ${validation.icon ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Icon <sup className="text-danger">*</sup>
                  </label>
                  {validation.icon && (
                    <div className={`invalid-feedback ${validation.icon ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.icon[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={order} onChange={(e) => setOrder(e.target.value)} className={`form-control ${validation.order ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Order <sup className="text-danger">*</sup>
                  </label>
                  {validation.order && (
                    <div className={`invalid-feedback ${validation.order ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.order[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-3">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/url" replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { API, Storage } from "../../Config/Config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicExtended from "ckeditor5-build-classic-extended";
import empty from "./../../Components/empty.jpg";

export default function EditNews() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "News Updates", slug: "/news", status: "" },
    { id: "3", title: "Edit News Updates", slug: "/news/edit", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [image, setImage] = useState([]);
  const [pict, setPict] = useState([]);
  const [pictUrl, setPictUrl] = useState([]);
  const [content, setContent] = useState([]);
  const [show, setShow] = useState("");
  const [validation, setValidation] = useState([]);
  const [formData, setFormData] = useState("");

  useEffect(() => {
    setFormData(new FormData());

    axios
      .get(api + `/news/data/${id}`, header)
      .then(function(response) {
        let news = response.data.data;
        // console.log(news);
        setTitle(news.title);
        setPict(news.image);
        setContent(news.content);
        setShow(news.show);
      })
      .catch(function(error) {
        Swal.fire({
          icon: "success",
          text: error.response.data.message,
          timer: 2000,
        });
      });
  }, [api, header]);

  const handleToggle = () => {
    setShow(!show);
    var data = !show ? 1 : 0;
    formData.append("show", data);
  };

  useEffect(() => {
    if (image.length < 1) return;
    const newImageUrls = [];
    image.forEach((images) => newImageUrls.push(URL.createObjectURL(images)));
    setPictUrl(newImageUrls);
  }, [image]);

  function onImageChange(e) {
    setImage([...e.target.files]);
  }

  const updateNews = async (e) => {
    e.preventDefault();

    formData.append("title", title);
    formData.append("image", image[0]);
    formData.append("content", content);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // console.log(...formData);

    const response = await axios.post(api + `/news/update/${id}`, formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <AppHeader />
      {success ? (
        <>
          <div className="d-flex flex-row align-items-center align-self-center ps-4 pe-4 pt-2 pb-3 border-bottom mb-3">
            <div className="flex-grow-1">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>
              <a className="btn btn-primary" href="/news" role="button">
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Back
              </a>
            </div>
          </div>
          <div className="px-4 pb-4">
            <form encType="multipart/form-data" onSubmit={updateNews}>
              <div className="alert alert-secondary p-2" role="alert">
                <p className="small p-0 m-0">
                  <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                  Sign <span className="text-danger">*</span> is a mandatory data
                </p>
              </div>
              <div className="mb-3">
                {pict != "empty.jpg" ? (
                  <img src={Storage + `/news_updates/${id}/${pict}`} style={{ display: image.length < 1 ? "block" : "none", marginLeft: "auto", marginRight: "auto", maxWidth: "25%", maxHeight: "10%", marginBottom: "2rem" }} />
                ) : (
                  <img src={empty} style={{ display: image.length < 1 ? "block" : "none", marginLeft: "auto", marginRight: "auto", maxWidth: "25%", maxHeight: "10%", marginBottom: "2rem" }} />
                )}
                {pictUrl.map((imageSrc, index) => (
                  <img key={index} src={imageSrc} style={{ display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "25%", maxHeight: "10%", marginBottom: "2rem" }} />
                ))}
                <label htmlFor="formFile" className="form-label">
                  *Image
                </label>
                <input type="file" multiple accept="image/*" onChange={onImageChange} className={`form-control ${validation.image ? "is-invalid" : ""}`} />
                {validation.image && <div className="alert alert-danger">{validation.image[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className={`form-control ${validation.title ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">
                  Title <sup className="text-danger">*</sup>
                </label>
                {validation.title && (
                  <div className={`invalid-feedback ${validation.title ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.title[0]}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="floatingTextarea2" className="mb-3">
                  Description <sup className="text-danger">*</sup>
                </label>
                {validation.name && (
                  <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.name[0]}
                  </div>
                )}
                <CKEditor
                  editor={ClassicExtended}
                  data={content ? content : ""}
                  config={{
                    toolbar: ["heading", "|", "bold", "italic", "underline", "link", "bulletedList", "numberedList", "|", "alignment", "|", "pageBreak", "|", "blockQuote", "insertTable", "|", "undo", "redo"],
                  }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                    // console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    // console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log("Focus.", editor);
                  }}
                />
              </div>
              <div className="form-check form-switch">
                <input className="form-check-input" onChange={handleToggle} checked={show} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  Show
                </label>
              </div>
              <div className="form-floating mb-3 text-end">
                <button type="submit" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-save"></i>
                  </span>
                  Save
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <Navigate to="/news" replace={true} />
      )}
      <AppFooter />
    </>
  );
}

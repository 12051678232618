import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
// import LoadingSpinner from "../Dashboard/Components/LoadingSpinner";
import AppSpinner from "../Components/AppSpinner";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { API } from "../Config/Config";

import Table from "./TableUrl";

export default function DataTableUrl() {
  const apiUrl = API;

  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [urls, setUrl] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchUrl = useCallback(async () => {
    await axios.get(apiUrl + "/urls/list", header).then((response) => {
      setUrl(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(apiUrl + `/urls/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchUrl();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, header, fetchUrl]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/url/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Position",
            accessor: "data.position",
          },
          {
            Header: "Parent",
            accessor: "data.parent[name]",
            Cell: ({ cell: { value } }) => {
              return value === 0 ? (
                <>
                  <p>-</p>
                </>
              ) : (
                <>
                  <p>{value}</p>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "data.name",
          },
          {
            Header: "Url",
            accessor: "data.url",
          },
          {
            Header: "Icon",
            accessor: "data.icon",
          },
          {
            Header: "Order",
            accessor: "data.order",
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchUrl();
  }, [token, fetchUrl]);

  if (!isLoaded) {
    return <AppSpinner title="Loading data URL's . . ." />;
  }
  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={urls} />
    </div>
  );
}

import React from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useMemo } from "react";
import AppFormater from "../../Components/AppFormater";
import { API } from "../../Config/Config";
import DashboardDataSummary from "./DashboardDataSummary";
import DashboardCustomerProfile from "./DashboardCustomerProfile";

export default function AppBody() {
  //get api from local storage
  const apiUrl = API;
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  // const [customer, setCustomer] = useState("");
  const [newReq, setNewReq] = useState("");
  const [progress, setProgress] = useState("");
  const [complete, setComplete] = useState("");
  const [cancel, setCancel] = useState("");

  useEffect(() => {
    axios
      .get(apiUrl + "/dashboard/dashboardAPI", header)
      .then(function (response) {
        // setCustomer(response.data.data.customer);
        setNewReq(response.data.data.new);
        setProgress(response.data.data.progress);
        setComplete(response.data.data.complete);
        setCancel(response.data.data.cancel);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [apiUrl, header]);

  return (
    <>
      <div className="container-fluid px-4">
        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-12 row-cols-lg-9">
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-2">
            <DashboardCustomerProfile />
            <div className="fs-5 mt-5 mt-sm-5">
              <i className="bi bi-graph-up me-2"></i>Transactions
              <div className="float-end">
                <i className="bi bi-three-dots me-2"></i>
              </div>
            </div>
            <hr />
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-info-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-info-subtle p-2 rounded">
                    <i className="bi bi-plus-circle me-2"></i>New Request
                  </h6>
                  <div className="table-responsive">
                    <table className="table table-sm align-middle">
                      <tbody>
                        <tr>
                          <td className="fw-light">VISA</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">KITAS</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">KITAP</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">PMA</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(newReq)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-primary-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-primary-subtle p-2 rounded">
                    <i className="bi bi-gear me-2"></i>On Process
                  </h6>
                  <div className="table-responsive">
                    <table className="table table-sm align-middle">
                      <tbody>
                        <tr>
                          <td className="fw-light">VISA</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">KITAS</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(progress)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">KITAP</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">PMA</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-success-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-success-subtle p-2 rounded">
                    <i className="bi bi-check2-circle me-2"></i>Completed
                  </h6>
                  <div className="table-responsive">
                    <table className="table table-sm align-middle">
                      <tbody>
                        <tr>
                          <td className="fw-light">VISA</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">KITAS</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">KITAP</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">PMA</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(complete)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-12 p-1">
                <div className="card h-100 rounded shadow p-3 border-danger-subtle">
                  <h6 className="card-title fw-bold text-nowrap bg-danger-subtle p-2 rounded">
                    <i className="bi bi-x-circle me-2"></i>Cancel
                  </h6>
                  <div className="table-responsive">
                    <table className="table table-sm align-middle">
                      <tbody>
                        <tr>
                          <td className="fw-light">VISA</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">KITAS</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">KITAP</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-light">PMA</td>
                          <td className="text-end font-monospace">
                            {AppFormater.formatNumber(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bolder">Total</td>
                          <td className="text-end font-monospace lead fw-bolder">
                            {AppFormater.formatNumber(cancel)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-2 mt-5 mt-sm-0">
            <DashboardDataSummary />
          </div>
        </div>
        {/* <div className="row row-cols-2 row-cols-sm-2 row-cols-md-12">
          <div className="col-12 col-sm-12 col-md-9 p-1">
            <div className="fs-5">Row 1 | Column 1</div>
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-9">
              <div className="col-12 col-md-4 p-1"></div>
              <div className="col-12 col-md-4 p-1"></div>
              <div className="col-12 col-md-4 p-1"></div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 p-1 ps-md-4">
            <div className="fs-5">Row 1 | Column 2</div>
          </div>
        </div> */}
      </div>
      <div className="container-fluid mb-5">&nbsp;</div>
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate, Link } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { numberFormat } from "../../TransactionLegal/Visa/NumberFormat";
import { API } from "../../Config/Config";

export default function CreateProductPrice() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Product Price Visa", slug: "/product-price", status: "" },
    { id: "3", title: "Create Product Price Visa", slug: "/product-price/create", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const [formData, setFormData] = useState("");

  useEffect(() => {
    setFormData(new FormData());
  }, []);

  const [country, setCountry] = useState("");
  const [price, setPrice] = useState("");
  const [show, setShow] = useState("");
  const [listCountry, setListCountry] = useState([]);
  const [validation, setValidation] = useState([]);

  const fetchCountry = useCallback(async () => {
    await axios.get(api + "/tools/product-price/visa/list/country", header).then((response) => {
      setListCountry(response.data.data);
    });
  }, [api, header]);

  const handleToggle = () => {
    setShow(!show);
    var data = !show ? 1 : 0;
    formData.append("show", data);
    // console.log(data);
  };

  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const handlePrice = (event) => setPrice(addCommas(removeNonNumeric(event.target.value)));

  const saveProduct = async (e) => {
    e.preventDefault();

    let prices = price.replaceAll(",", "");
    // console.log(prices);

    formData.append("country", country);
    formData.append("price", prices);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + "/tools/product-price/visa/create", formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchCountry();
  }, [token, fetchCountry]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />

        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/product-price" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form onSubmit={saveProduct}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.country ? "is-invalid" : ""}`} id="floatingSelect" aria-label="Floating label select example" onChange={(e) => setCountry(e.target.value)}>
                    <option value="">Select Country</option>
                    {listCountry.map((data, index) => (
                      <option key={index} value={data.data.id}>
                        {data.data.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Select Country</label>
                  {validation.country && (
                    <div className={`invalid-feedback ${validation.country ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.country[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className={`form-control ${validation.price ? "is-invalid" : ""}`} id="floatingInput" value={price} onChange={handlePrice} />
                  <label htmlFor="floatingInput">Price</label>
                  {validation.price && (
                    <div className={`invalid-feedback ${validation.price ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.price[0]}
                    </div>
                  )}
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input" onChange={handleToggle} value={show} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Show
                  </label>
                  {validation.show && (
                    <div className={`invalid-feedback ${validation.show ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.show[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-2">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/product-price" replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Navigate, useParams, Link } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppSpinner from "../../Components/AppSpinner";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { API } from "../../Config/Config";

export default function EditTrack() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Tracking Transactions", slug: "/track", status: "" },
    {
      id: "3",
      title: "Edit Track Transaction",
      slug: "/track/edit",
      status: "active",
    },
  ];

  const { id } = useParams();

  const [success, setSuccess] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [listCustomer, setListCustomer] = useState([]);

  // const fetchCustomer = useCallback(async () => {
  //   await axios.get(api + "/transaction-legal/tracking/getCustomer", header).then((response) => {
  //     setListCustomer(response.data.data);
  //   });
  // }, [api, header]);

  // const options = listCustomer.map(function (customers) {
  //   return {
  //     value: customers.data.id,
  //     label: customers.data.first_name + " " + customers.data.last_name,
  //   };
  // });

  // const handleChange = (selected: string[]) => {
  //   setCustomer(selected.value);
  // };
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [date, setDate] = useState("");
  // const [customer, setCustomer] = useState("");
  const [type, setType] = useState("");
  const [address, setAddress] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  // const [password, setPassword] = useState("");
  const [description, setDescription] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    axios
      .get(api + `/transaction-legal/tracking/data/${id}`, header)
      .then(function (response) {
        let data = response.data.data;
        // setFirstName(data["customer"].first_name);
        // setLastName(data["customer"].last_name);
        setDate(data.date);
        // setCustomer(data.customer_id);
        setType(data.type);
        setReceiptNumber(data.receipt_number);
        setAddress(data.address);
        setDescription(data.description);

        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [id, api, header]);

  // const handlePassword = () => {
  //   let generate = Math.random().toString(36).substring(7);
  //   setPassword(generate);
  // };

  const updateTrack = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Updating Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();

    formData.append("date", date);
    // formData.append("customer", customer);
    formData.append("type", type);
    formData.append("address", address);
    formData.append("receipt_number", receiptNumber);
    // formData.append("password", password);
    formData.append("description", description);

    const response = await axios.post(api + `/transaction-legal/tracking/update/${id}`, formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }
  }, [token]);

  if (!isLoaded) {
    return (
      <>
        <div className="container-fluid p-0 m-0 mb-5">
          <AppHeader />
          <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
            <div className="flex-grow-1 py-2">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div className="py-1">
              <Link to="/track" className="btn btn-sm btn-primary px-2">
                <span className="me-2">
                  <i className="bi bi-arrow-left-circle"></i>
                </span>
                Back
              </Link>
            </div>
          </div>
          <div className="px-4 pb-4">
            <div className="d-flex justify-content-center align-items-center align-self-center">
              <AppSpinner title="Loading data ..." />
            </div>
          </div>
          <AppFooter />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/track" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form onSubmit={updateTrack}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                <div className="form-floating mb-3">
                  <input type="date" id="floatingInput" placeholder="Input Date" defaultValue={date} className={`form-control ${validation.date ? "is-invalid" : ""}`} onChange={(e) => setDate(e.target.value)} />
                  <label htmlFor="floatingInput">*Date</label>
                  {validation.date && (
                    <div className={`invalid-feedback ${validation.date ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.date[0]}
                    </div>
                  )}
                </div>
                {/* <div className="mb-3">
                  <Select className={`${validation.customer ? "is-invalid" : ""}`} defaultValue={{ label: `${firstName} ${lastName}`, value: customer }} options={options} onChange={handleChange} placeholder="Select Customer" />
                  {validation.customer && (
                    <div className={`invalid-feedback ${validation.customer ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.customer[0]}
                    </div>
                  )}
                </div> */}
                {/* <div className="form-floating mb-3">
          <input type="text" id="floatingInput" placeholder="Input Customer" className={`form-control ${validation.customer ? "is-invalid" : ""}`} onChange={(e) => setCustomer(e.target.value)} />
          <label htmlFor="floatingInput">*Customer</label>
          {validation.customer && (
            <div className={`invalid-feedback ${validation.customer ? "d-block mb-3" : "d-none"}`}>
              <i className="bi bi-exclamation-circle me-2"></i>
              {validation.customer[0]}
            </div>
          )}
        </div> */}
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.type ? "is-invalid" : ""}`} value={type} onChange={(e) => setType(e.target.value)} id="floatingSelect" aria-label="Floating label select example">
                    <option>Select Type</option>
                    <option value="VISA">VISA</option>
                    <option value="PMA">PMA</option>
                    <option value="KITAS">KITAS</option>
                    <option value="KITAP">KITAP</option>
                  </select>
                  <label htmlFor="floatingSelect">*Select Type</label>
                  {validation.type && (
                    <div className={`invalid-feedback ${validation.type ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.type[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className={`form-control ${validation.address ? "is-invalid" : ""}`}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "100px" }}
                  ></textarea>
                  <label htmlFor="floatingTextarea2">*Address</label>
                  {validation.address && (
                    <div className={`invalid-feedback ${validation.address ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.address[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className={`form-control ${validation.receipt_number ? "is-invalid" : ""}`} value={receiptNumber} onChange={(e) => setReceiptNumber(e.target.value)} id="floatingReceipt" placeholder="Receipt Number" />
                  <label htmlFor="floatingReceipt">
                    <span className="text-danger">*</span> Receipt Number
                  </label>
                  {validation.receipt_number && (
                    <div className={`invalid-feedback ${validation.receipt_number ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.receipt_number[0]}
                    </div>
                  )}
                </div>
                {/* <div className="form-floating mb-3">
          <select className={`form-select ${validation.status ? "is-invalid" : ""}`} onChange={(e) => setStatus(e.target.value)} id="floatingSelect" aria-label="Floating label select example">
            <option selected disabled>
              Select Status
            </option>
            <option value="new_request">New Request</option>
            <option value="on_progress">On Progress</option>
            <option value="complete">Complete</option>
            <option value="cancel">Cancel</option>
          </select>
          <label htmlFor="floatingSelect">Select Status</label>
          {validation.status && (
            <div className={`invalid-feedback ${validation.status ? "d-block mb-3" : "d-none"}`}>
              <i className="bi bi-exclamation-circle me-2"></i>
              {validation.status[0]}
            </div>
          )}
        </div> */}
                {/* <div className="row">
                  <div className="col-md-10">
                    <div className="form-floating mb-3">
                      <input type="text" className={`form-control ${validation.password ? "is-invalid" : ""}`} value={password} onChange={(e) => setPassword(e.target.value)} id="floatingPassword" placeholder="Password" />
                      <label htmlFor="floatingPassword">New Password</label>
                      {validation.password && (
                        <div className={`invalid-feedback ${validation.password ? "d-block mb-3" : "d-none"}`}>
                          <i className="bi bi-exclamation-circle me-2"></i>
                          {validation.password[0]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="d-grid gap-2">
                      <button className="btn btn-primary p-3" type="button" onClick={handlePassword}>
                        Generate Password
                      </button>
                    </div>
                  </div>
                </div> */}
                <div className="form-floating mb-3">
                  <textarea
                    className={`form-control ${validation.description ? "is-invalid" : ""}`}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "100px" }}
                  ></textarea>
                  <label htmlFor="floatingTextarea2">*Description</label>
                  {validation.description && (
                    <div className={`invalid-feedback ${validation.description ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.description[0]}
                    </div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary mb-5">
                  <i className="bi bi-save2"></i> Save
                </button>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/track" replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

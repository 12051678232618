import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../Config/Config";
import AppSpinner from "../Components/AppSpinner";

export default function ModalCustomerMember(props) {
  // -- General Setting -- //
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [isLoading, setIsLoading] = useState(props.method === "EDIT" ? false : true);
  const [validation, setValidation] = useState([]);
  const [listMember, setListMember] = useState([]);

  const [expiredDate, setExpiredDate] = useState("");
  // -- End General Setting -- //

  // -- Save Data Customer Member -- //
  const saveCustomerMember = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("customer_id", props.customerID);
    formData.append("expired_date", expiredDate);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/customers/member/create`, formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });

          setValidation([]);
          setExpiredDate("");

          props.close();
        }
      })
      .catch(function (error) {
        // console.log(error)
      });
  };
  // -- End Save Data Customer Member -- //

  return (
    <>
      <Modal show={props.show}>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={saveCustomerMember}>
            <div className="form-floating mb-3">
              <input type="date" className={`form-control ${validation.expired_date ? "is-invalid" : ""}`} value={expiredDate} onChange={(e) => setExpiredDate(e.target.value)} id="floatingInput" placeholder="Expired Date" />
              <label htmlFor="floatingInput">Expired Date</label>
              {validation.expired_date && <div className="alert alert-danger mt-2">{validation.expired_date[0]}</div>}
            </div>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setValidation([]);
                  props.close();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Save changes
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import AppSpinner from "../Components/AppSpinner";
import Formater from "../Components/AppDateFormatter";
import { API, Storage } from "../Config/Config";
import axios from "axios";
import empty from "../Components/empty.jpg";
import Swal from "sweetalert2";
import DataTableCustomerTransaction from "./DataTableCustomerTransaction";
import DataTableCustomerAddress from "./DataTableCustomerAddress";
import DataTableCustomerNotes from "./DataTableCustomerNotes";
import DataTableCustomerCard from "./DataTableCustomerCard";
import DataTableCustomerMember from "./DataTableCustomerMember";
import { Modal, Button } from "react-bootstrap";

export default function DetailsCustomer() {
  // --- BEGIN | General --- //
  const { id } = useParams();
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const apiUrl = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Customers", slug: "/customers", status: "" },
    {
      id: "3",
      title: "View",
      slug: "/customer/details",
      status: "active",
    },
  ];
  // --- END | General --- //

  const [success, setSuccess] = useState(true);

  const [photo, setPhoto] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [religion, setReligion] = useState("");
  const [marital, setMarital] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [bloodType, setBloodType] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(apiUrl + `/customers/data/${id}`, header)
      .then(function (response) {
        // console.log(response.data.data.data);
        let customers = response.data.data.data;
        setPhoto(customers.photo);
        setFirstName(customers.first_name);
        setLastName(customers.last_name);
        setBirthPlace(customers.birth_place);
        setBirthDate(customers.birth_date);
        setGender(customers.param_gender?.name);
        setNationality(customers.param_nationality?.name);
        setReligion(customers.param_religion?.name);
        setMarital(customers.param_marital?.name);
        setEmail(customers.email);
        setMobile(customers.mobile);
        setBloodType(customers.param_blood_type?.name);
        setAddressName(customers.address_name);
        setAddressStreet(customers.address_street);
        setAddressCity(customers.city?.name);
        setAddressState(customers.state?.name);
        setAddressCountry(customers.country?.name);
        setAddressZipCode(customers?.address_zip_code);
        setCreatedAt(customers?.created_at);
        setCreatedBy(customers.created_user?.first_name + " " + customers.created_user?.last_name);
        setUpdatedAt(customers?.updated_at);
        setUpdatedBy(customers.updated_user?.first_name + " " + customers.updated_user?.last_name);
        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [apiUrl, header, id]);

  // ------------------------------ Customer Address Section ------------------------------------------- //

  const [listCountry, setListCountry] = useState([]);
  const [listState, setListState] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [newAddressName, setNewAddressName] = useState("");
  const [newAddressStreet, setNewAddressStreet] = useState("");
  const [newAddressCity, setNewAddressCity] = useState("");
  const [newAddressState, setNewAddressState] = useState("");
  const [newAddressCountry, setNewAddressCountry] = useState("");
  const [newAddressZipCode, setNewAddressZipCode] = useState("");
  const [validationAddress, setValidationAddress] = useState("");

  const [refreshAddress, setRefreshAddress] = useState(false);
  const [showModalAddress, setShowModalAddress] = useState(false);

  const handleCloseModalAddress = () => {
    setShowModalAddress(false);
    setNewAddressName("");
    setNewAddressStreet("");
    setNewAddressCity("");
    setNewAddressState("");
    setNewAddressCountry("");
    setNewAddressZipCode("");
    setListState([]);
    setListCity([]);
    setValidationAddress([]);
  };
  const handleShowModalAddress = () => setShowModalAddress(true);

  const fetchCountry = useCallback(async () => {
    await axios.get(apiUrl + "/customers/listCountry", header).then((response) => {
      setListCountry(response.data.data);
    });
  }, [apiUrl, header]);

  const changeNewState = useCallback(
    (e) => {
      setNewAddressCountry(e.target.value);
      axios.get(apiUrl + "/customers/listState?country=" + e.target.value, header).then((response) => {
        setListState(response.data.data);
        // console.log(response.data.data);
      });
    },
    [apiUrl, header]
  );

  const changeNewCity = useCallback(
    (e) => {
      setNewAddressState(e.target.value);
      axios.get(apiUrl + "/customers/listCity?state=" + e.target.value, header).then((response) => {
        setListCity(response.data.data);
        // console.log(response.data.data);
      });
    },
    [apiUrl, header]
  );

  const saveNewAddress = async (e) => {
    e.preventDefault();
    setRefreshAddress(false);

    const formData = new FormData();

    formData.append("customer_id", id);
    formData.append("address_name", newAddressName);
    formData.append("address_street", newAddressStreet);
    formData.append("address_city", newAddressCity);
    formData.append("address_state", newAddressState);
    formData.append("address_country", newAddressCountry);
    formData.append("address_zip_code", newAddressZipCode);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios.post(apiUrl + `/customers/address/create`, formData, header).then(function (response) {
      if (response.data.status === "error") {
        setValidationAddress(response.data.data);

        Swal.fire({
          icon: "error",
          text: response.data.message,
          timer: 2000,
        });
      } else if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          text: response.data.message,
          timer: 2000,
        });
        handleCloseModalAddress();
        setRefreshAddress(true);
      }
    });
  };

  // ------------------------------ End Customer Address Section ---------------------------------------- //

  // ------------------------------ Customer Card ID Section --------------------------------------------//

  const [cardType, setCardType] = useState("");
  const [fileName, setFileName] = useState([]);
  const [remark, setRemark] = useState("");
  const [validationCard, setValidationCard] = useState([]);

  const [refreshCard, setRefreshCard] = useState(false);
  const [showModalCard, setShowModalCard] = useState(false);

  const handleCloseModalCard = () => setShowModalCard(false);
  const handleShowModalCard = () => setShowModalCard(true);

  const [listCardType, setListCardType] = useState([]);

  const fetchCardType = useCallback(async () => {
    await axios.get(apiUrl + `/customers/card/card-type`, header).then(function (response) {
      setListCardType(response.data.data);
    });
  }, [apiUrl, header]);

  useEffect(() => {
    fetchCardType();
  }, [fetchCardType]);

  const saveCustomerCard = async (e) => {
    e.preventDefault();
    setRefreshCard(false);

    const formData = new FormData();

    formData.append("customer_id", id);
    formData.append("card_type", cardType);
    formData.append("filename", fileName);
    formData.append("remark", remark);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios.post(apiUrl + `/customers/card/create`, formData, header).then(function (response) {
      if (response.data.status === "error") {
        setValidationCard(response.data.data);
        // console.log(response.data.data);
        Swal.fire({
          icon: "error",
          text: response.data.message,
          timer: 2000,
        });
      } else if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          text: response.data.message,
          timer: 2000,
        });

        handleCloseModalCard();
        setRefreshCard(true);
        setCardType("");
        setFileName("");
        setRemark("");
      }
    });
  };

  // ------------------------------  End Customer Card ID Section --------------------------------------------//
  // ------------------------------  Customer Member Section --------------------------------------------//
  const [expiredDateMember, setExpiredDateMember] = useState("");
  const [validationMember, setValidationMember] = useState([]);

  const [refreshMember, setRefreshMember] = useState(false);
  const [showModalMember, setShowModalMember] = useState(false);

  const handleCloseModalMember = () => setShowModalMember(false);
  const handleShowModalMember = () => setShowModalMember(true);

  const saveMember = async (e) => {
    e.preventDefault();
    setRefreshMember(false);

    const formData = new FormData();

    formData.append("customer_id", id);
    formData.append("expired_date", expiredDateMember);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios.post(apiUrl + `/customers/member/create`, formData, header).then(function (response) {
      if (response.data.status === "error") {
        setValidationMember(response.data.data);

        Swal.fire({
          icon: "error",
          text: response.data.message,
          timer: 2000,
        });
      } else if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          text: response.data.message,
          timer: 2000,
        });

        handleCloseModalMember();
        setRefreshMember(true);
        setExpiredDateMember("");
      }
    });
  };
  // ------------------------------  End Customer Member Section --------------------------------------------//

  useEffect(() => {
    fetchCountry();
    if (!token) {
      setSuccess(false);
    }
  }, [token, fetchCountry, success]);

  return (
    <>
      <AppHeader />
      <div className="d-flex flex-row border-bottom p-2 px-3">
        <div className="flex-grow-1 mt-1 small">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
        <div>
          <a className="btn btn-sm btn-primary" href="/customers" role="button">
            <i className="bi bi-arrow-left me-2"></i>
            <span className="">Back</span>
          </a>
        </div>
      </div>
      {isLoaded === false ? (
        <>
          <div className="d-flex justify-content-center align-items-center align-self-center">
            <AppSpinner title="Loading data detail customer ..." />
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid pt-3 m-0 mb-5 px-1">
            <div className="row row-cols-1 border-bottom">
              <div className="col">
                <div className="row row-cols-1 bg-primary text-bg-primary">
                  <div className="col p-2 ps-4">
                    <div className="fs-2">Customer Profile</div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2">
                  <div className="col col-md-4">
                    <div className="row justify-content-start">
                      <div className="col p-3 text-center">
                        {photo !== "empty.jpg" ? (
                          <img
                            alt={`${photo}`}
                            className="img-fluid img-thumbnail"
                            src={Storage + `/user_profile/${id}/${photo}`}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              maxHeight: "120px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <img
                            alt="default"
                            className="img-fluid img-thumbnail"
                            src={empty}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              maxHeight: "120px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-start">
                      <div className="col px-4 py-2 text-center">
                        <p className="fw-bold border-bottom">Basic Information</p>
                        <p className="fw-semibold p-0 m-0">
                          {firstName} {lastName}
                        </p>
                        <p className="fw-light p-0 m-0">{mobile}</p>
                        <p className="fw-light p-0 m-0">{email}</p>
                        <div className="table-responsive fw-light mt-3">
                          <table className="table table-sm align-middle text-start">
                            <tbody>
                              <tr>
                                <td>Birth Info</td>
                                <td>
                                  {birthPlace}, {Formater.beautyDate(birthDate)}
                                </td>
                              </tr>
                              <tr>
                                <td>Nationality</td>
                                <td>{nationality ? nationality : "-"}</td>
                              </tr>
                              <tr>
                                <td>Gender</td>
                                <td>{gender ? gender : "-"}</td>
                              </tr>
                              <tr>
                                <td>Religion</td>
                                <td>{religion ? religion : "-"}</td>
                              </tr>
                              <tr>
                                <td>Marital</td>
                                <td>{marital ? marital : "-"}</td>
                              </tr>
                              <tr>
                                <td>Blood Type</td>
                                <td>{bloodType ? bloodType : "-"}</td>
                              </tr>
                              <tr>
                                <td>Address</td>
                                <td>
                                  <span className="fst-italic">{addressName ? addressName : "-"}</span>
                                  <br />
                                  {addressStreet ? addressStreet : "-"}, {addressCity ? addressCity : "-"}, {addressState ? addressState : "-"}, {addressCountry ? addressCountry : "-"}, {addressZipCode ? addressZipCode : "-"}
                                </td>
                              </tr>
                              <tr className="small fw-light text-center">
                                <td colSpan={2}>
                                  {"Created at "}
                                  {createdAt ? Formater.beautyDateTime(createdAt) : "-"} {" | By "}
                                  {createdBy ? createdBy : ""}
                                </td>
                              </tr>
                              <tr className="small fw-light text-center">
                                <td colSpan={2}>
                                  {"Last updated at "}
                                  {updatedAt ? Formater.beautyDateTime(updatedAt) : "-"} {" | By "}
                                  {updatedBy ? updatedBy : ""}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center text-center mb-4">
                      <div className="col">
                        <Link to={`/customers/details/edit/${id}`} className="btn btn-sm btn-primary px-3">
                          <i className="bi bi-pencil-square"></i> Edit
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="#" className="btn btn-sm btn-danger px-3">
                          <i className="bi bi-trash-fill"></i> Delete
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-8 border-secondary border-start border-opacity-25 p-4">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="notes-tab" data-bs-toggle="tab" data-bs-target="#notes-tab-pane" type="button" role="tab" aria-controls="notes-tab-pane" aria-selected="true">
                          Notes
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="addresses-tab" data-bs-toggle="tab" data-bs-target="#addresses-tab-pane" type="button" role="tab" aria-controls="addresses-tab-pane" aria-selected="false">
                          Addresses
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="cards-tab" data-bs-toggle="tab" data-bs-target="#cards-tab-pane" type="button" role="tab" aria-controls="cards-tab-pane" aria-selected="false">
                          Cards ID
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="memberships-tab" data-bs-toggle="tab" data-bs-target="#memberships-tab-pane" type="button" role="tab" aria-controls="memberships-tab-pane" aria-selected="false">
                          Memberships
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="transactions-tab" data-bs-toggle="tab" data-bs-target="#transactions-tab-pane" type="button" role="tab" aria-controls="transactions-tab-pane" aria-selected="false">
                          Transactions
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content py-2" id="myTabContent">
                      <div className="tab-pane fade show active" id="notes-tab-pane" role="tabpanel" aria-labelledby="notes-tab" tabIndex="0">
                        <div className="container-fluid p-0 pt-2">
                          <DataTableCustomerNotes />
                        </div>
                      </div>
                      <div className="tab-pane fade" id="addresses-tab-pane" role="tabpanel" aria-labelledby="addresses-tab" tabIndex="0">
                        <div className="container-fluid p-0 pt-2">
                          <DataTableCustomerAddress />
                        </div>
                      </div>
                      <div className="tab-pane fade" id="cards-tab-pane" role="tabpanel" aria-labelledby="cards-tab" tabIndex="0">
                        <div className="container-fluid p-0 pt-2">
                          <DataTableCustomerCard />
                        </div>
                      </div>
                      <div className="tab-pane fade" id="memberships-tab-pane" role="tabpanel" aria-labelledby="memberships-tab" tabIndex="0">
                        <div className="container-fluid p-0 pt-2">
                          <DataTableCustomerMember />
                        </div>
                      </div>
                      <div className="tab-pane fade" id="transactions-tab-pane" role="tabpanel" aria-labelledby="transactions-tab" tabIndex="0">
                        <div className="container-fluid p-0 pt-2">
                          <DataTableCustomerTransaction />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <AppFooter />
    </>
  );
}

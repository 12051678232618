import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import AppSpinner from "../../Components/AppSpinner";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Table from "./TableFormElement";
import { API } from "../../Config/Config";

export default function DataTableFormElement() {
  const apiUrl = API;

  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [formElement, setFormElement] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchFormElement = useCallback(async () => {
    await axios.get(apiUrl + "/tools/form-element/list", header).then((response) => {
      setFormElement(response.data.data);
      console.log(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(apiUrl + `/tools/form-element/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchFormElement();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, header, fetchFormElement]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/form-element/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Caption",
            accessor: "data.caption",
          },
          {
            Header: "Description",
            accessor: "data.description",
            Cell: ({ cell: { value } }) => {
              return value === null ? (
                <>
                  <p>-</p>
                </>
              ) : (
                <>
                  <p style={{ whiteSpace: "pre-line" }}>{value}</p>
                </>
              );
            },
          },
          {
            Header: "Type",
            accessor: "data.type",
          },
          {
            Header: "Slug",
            accessor: "data.slug",
          },
          {
            Header: "Required",
            accessor: "data.required",
            Cell: ({ cell: { value } }) => {
              return value === 1 ? <p>Yes</p> : <p>No</p>;
            },
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchFormElement();
  }, [token, fetchFormElement]);

  if (!isLoaded) {
    return <AppSpinner title="Loading data Form Element . . ." />;
  }

  if (formElement.length < 1) {
    return (
      <div className="d-flex justify-content-center align-items-center align-self-center">
        <div className="px-4 py-5 my-5 text-center">
          <div className="fs-1">
            <i className="bi bi-info-circle"></i>
          </div>
          <h1 className="display-5 fw-bold text-body-emphasis">Data not found</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">Form data not found, to start filling in new Form data, please click the add new button at the top right of your screen.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={formElement} />
    </div>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import AppSpinner from "../../Components/AppSpinner";
import Swal from "sweetalert2";
import Formatter from "./Formatter";
import { API, Storage } from "../../Config/Config";
import { useCallback } from "react";
import "./Wizard.css";
import { Modal } from "react-bootstrap";

export default function UpdateTrack() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
        // "Access-Control-Allow-Origin": "https://maxxsco-api.maxxsgroup.com",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Track Transactions", slug: "/track", status: "" },
    {
      id: "3",
      title: "Update Track Transactions",
      slug: "/track/update",
      status: "active",
    },
  ];

  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [success, setSuccess] = useState(true);
  const [transMaster, setTransMaster] = useState([]);
  const [transCustomer, setTransCustomer] = useState([]);
  const [transLog, setTransLog] = useState([]);

  const [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  const getTransLog = useCallback(async () => {
    axios
      .get(api + `/transaction-legal/tracking/data/${id}`, header)
      .then(function (response) {
        let master = response.data.data;
        let customer = response.data.data.customer;
        let log = response.data.data.log;

        setTransMaster(master);
        setTransLog(log);
        setTransCustomer(customer);
        setIsLoaded(true);
        // console.log(customer);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  }, [api, header, id]);

  const data = new Date();
  const futureDate = data.getDate();
  data.setDate(futureDate);
  const getDate = data.toLocaleDateString() + "";

  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [statusDate, setStatusDate] = useState("");
  const [validation, setValidation] = useState([]);

  const updateStatus = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Updating Status ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();

    formData.append("status", status);
    formData.append("description", description);
    formData.append("status_date", statusDate);

    await axios
      .post(api + `/transaction-legal/tracking/updateStatus/${id}`, formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            title: response.data.message,
            timer: 2500,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: response.data.message,
            timer: 2000,
          });

          setStatus("");
          setDescription("");
          setStatusDate("");

          getTransLog();
          // window.location.reload(false);
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured",
          timer: 2500,
        });
      });
  };

  const updateStatusNewRequest = useCallback(
    (id) => {
      Swal.fire({
        title: "On Progress Tracking",
        html: `Are you sure to <strong>On Progress</strong> this Tracking ? You won't be able to revert this.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Updating Status ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .get(api + `/transaction-legal/tracking/updateStatusNewRequest/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              window.location.reload(false);
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header]
  );

  const updateStatusOnProgress = useCallback(
    (id) => {
      Swal.fire({
        title: "Sent By Maxx's",
        html: `
      Are you sure to <strong>Sent By Maxx's</strong> this Tracking ? You won't be able to revert this.
      <form encytype="multipart/form-data">
      <div class="form-floating mt-3">
        <textarea class="form-control" placeholder="Leave a description here" id="descriptionLog" style="height: 100px"></textarea>
      <label htmlFor="descriptionLog">Description</label>
      </div>
      <div class="mt-3">
        <label for="formFile" class="form-label float-start">Photo <span class="text-muted">(Optional)</span></label>
        <input class="form-control" id="formFile" type="file">
      </div>
      </form>
      `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        preConfirm: () => {
          const descriptionLog = Swal.getPopup().querySelector("#descriptionLog").value;
          const photo = Swal.getPopup().querySelector("#formFile").files[0];
          if (!descriptionLog) {
            Swal.showValidationMessage("Please enter Description and try again.");
          }
          return { descriptionLog: descriptionLog, photo: photo };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();

          formData.append("description", result.value.descriptionLog);
          formData.append("photo", result.value.photo);

          Swal.fire({
            title: "Updating Status ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .post(api + `/transaction-legal/tracking/updateStatusOnProgress/${id}`, formData, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              window.location.reload(false);
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header]
  );

  const updateStatusCancel = useCallback(
    (id) => {
      Swal.fire({
        title: "Cancel Tracking",
        html: `
      Are your sure want to <strong>Cancel</strong> this Tracking ? You won't be able to revert this.
      <form encytype="multipart/form-data">
      <div class="form-floating mt-3">
        <textarea class="form-control" placeholder="Leave a description here" id="descriptionLog" style="height: 100px"></textarea>
      <label htmlFor="descriptionLog">Description</label>
      </div>
      <div class="mt-3">
        <label for="formFile" class="form-label float-start">Photo <span class="text-muted">(Optional)</span></label>
        <input class="form-control" id="formFile" type="file">
      </div>
      </form>
      `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        preConfirm: () => {
          const descriptionLog = Swal.getPopup().querySelector("#descriptionLog").value;
          const photo = Swal.getPopup().querySelector("#formFile").files[0];
          if (!descriptionLog) {
            Swal.showValidationMessage("Pleas enter Description and try again.");
          }
          return { descriptionLog: descriptionLog, photo: photo };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();

          formData.append("description", result.value.descriptionLog);
          formData.append("photo", result.value.photo);

          Swal.fire({
            title: "Updating Status ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .post(api + `/transaction-legal/tracking/updateStatusCancel/${id}`, formData, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              window.location.reload(false);
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header]
  );

  useEffect(() => {
    getTransLog();
    if (!token) {
      setSuccess(false);
    }
  }, [token, getTransLog]);

  if (!isLoaded) {
    return (
      <>
        <div className="container-fluid p-0 m-0 mb-5">
          <AppHeader />
          <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
            <div className="flex-grow-1 py-2">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div className="py-1">
              <Link to="/track" className="btn btn-sm btn-primary px-2">
                <span className="me-2">
                  <i className="bi bi-arrow-left-circle"></i>
                </span>
                Back
              </Link>
            </div>
          </div>
          <AppSpinner title="Loading Detail Transaction . . ." />
        </div>
      </>
    );
  }

  return (
    <>
      <AppHeader />
      <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
        <div className="flex-grow-1 py-2">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
        <div className="py-1">
          <Link to="/track" className="btn btn-sm btn-primary px-2">
            <span className="me-2">
              <i className="bi bi-arrow-left-circle"></i>
            </span>
            Back
          </Link>
        </div>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="card border-0">
            <div className="card-body">
              <div className="row justify-content-around">
                <div className="col-md-6">
                  <p className="text-muted">Customer</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">
                    <strong>{transCustomer === null ? "Guest" : transCustomer.full_name}</strong>
                  </p>
                </div>
                <div className="col-12 mb-3">
                  <button type="button" className="btn btn-sm btn-primary float-end" onClick={openModal}>
                    Customer Details
                  </button>
                </div>

                <div className="col-md-6">
                  <p className="text-muted">Receipt Number</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{transMaster.receipt_number}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Date</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{transMaster.date}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Type Transaction</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{transMaster.type}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Address</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{transMaster.address}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Description</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{transMaster.description}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Status</p>
                </div>
                <div className="col-md-6">
                  {transMaster.status === "new_request" ? (
                    <span className="badge text-bg-primary float-end">New Request</span>
                  ) : transMaster.status === "on_progress" ? (
                    <span className="badge text-bg-primary float-end">On Progress</span>
                  ) : transMaster.status === "sent_by_maxxs" ? (
                    <span className="badge text-bg-primary float-end">Sent By Maxx's</span>
                  ) : transMaster.status === "received_by_customer" ? (
                    <span className="badge text-bg-primary float-end">Received By Customer</span>
                  ) : transMaster.status === "completed_by_system" ? (
                    <span className="badge text-bg-primary float-end">Completed By System</span>
                  ) : transMaster.status === "completed/close" ? (
                    <span className="badge text-bg-success float-end">Transaction Completed</span>
                  ) : transMaster.status === "cancel" ? (
                    <span className="badge text-bg-danger float-end">Cancel</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 mb-3">
                  {transMaster.status === "new_request" ? (
                    <button className="btn float-end btn-sm btn-primary" onClick={() => updateStatusNewRequest(transMaster.id)}>
                      Update Status to On Progress
                    </button>
                  ) : transMaster.status === "on_progress" ? (
                    <button className="btn float-end btn-sm btn-primary" onClick={() => updateStatusOnProgress(transMaster.id)}>
                      Update Status to Sent By Maxx's
                    </button>
                  ) : (
                    ""
                  )}
                  {transMaster.status === "on_progress" ? (
                    <Link to="#" className="btn btn-sm float-end me-3 btn-danger" onClick={() => updateStatusCancel(transMaster.id)}>
                      Cancel This Tracking
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <hr />
              <strong>Log Status</strong>
              <div className="col-12 mb-3 mt-5">
                <ol>
                  {transLog.map((data, index) => {
                    return (
                      <li key={index} className="c-timeline__item">
                        <div className="c-timeline__content">
                          <h5 className="c-timeline__title">{data.status === "new_request" ? "New Request" : data.status}</h5>
                          <p className="c-timeline__desc">{data.description}</p>
                        </div>
                        <p className="c-timeline__time text-secondary">{data.status_date === null ? "-" : Formatter.beautyDateTime(data.status_date)}</p>
                      </li>
                    );
                  })}
                </ol>
              </div>
              {transMaster.status !== "on_progress" ? (
                ""
              ) : (
                <div className="col-12">
                  <strong>Update Status</strong>
                  <form onSubmit={updateStatus}>
                    <div className="form-floating mb-3 mt-3">
                      <input type="text" className={`form-control ${validation.status ? "is-invalid" : ""}`} value={status} onChange={(e) => setStatus(e.target.value)} id="floatingInput" placeholder="name@example.com" />
                      <label htmlFor="floatingInput">Status</label>
                      {validation.status && (
                        <div className={`invalid-feedback ${validation.status ? "d-block mb-3" : "d-none"}`}>
                          <i className="bi bi-exclamation-circle me-2"></i>
                          {validation.status[0]}
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <textarea className={`form-control ${validation.description ? "is-invalid" : ""}`} value={description} onChange={(e) => setDescription(e.target.value)} id="floatingTextarea" placeholder="Description"></textarea>
                      <label htmlFor="floationgTextarea">Description</label>
                      {validation.description && (
                        <div className={`invalid-feedback ${validation.description ? "d-block mb-3" : "d-none"}`}>
                          <i className="bi bi-exclamation-circle me-2"></i>
                          {validation.description[0]}
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="datetime-local"
                        id="floatingInput"
                        placeholder="Input Status Date"
                        defaultValue={getDate}
                        className={`form-control ${validation.status_date ? "is-invalid" : ""}`}
                        value={statusDate}
                        onChange={(e) => setStatusDate(e.target.value)}
                      />

                      <label htmlFor="floatingInput">Status Date</label>
                      {validation.status_date && (
                        <div className={`invalid-feedback ${validation.status_date ? "d-block mb-3" : "d-none"}`}>
                          <i className="bi bi-exclamation-circle me-2"></i>
                          {validation.status_date[0]}
                        </div>
                      )}
                    </div>
                    <button type="submit" className="btn btn-primary mb-5">
                      <i className="bi bi-save2"></i> Save
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal Show Data Customer */}
      <Modal show={showModal} onHide={closeModal} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {transCustomer !== null ? (
              <>
                <p className="fs-4 mb-4">Personal Data</p>
                <div className="row d-flex justify-content-between">
                  <div className="col-6 mb-3">
                    <p className="fs-6 text-secondary">Full Name</p>
                    <p className="fs-6">{transCustomer.full_name}</p>
                  </div>
                  <div className="col-6">
                    <p className="fs-6 text-secondary">Phone</p>
                    <p className="fs-6">{transCustomer.phone}</p>
                  </div>
                  <div className="col-6 mb-3">
                    <p className="fs-6 text-secondary">Email</p>
                    <p className="fs-6">{transCustomer.email}</p>
                  </div>
                  <div className="col-6">
                    <p className="fs-6 text-secondary">Gender</p>
                    <p className="fs-6">{transCustomer.param_gender.name}</p>
                  </div>
                  <div className="col-6 mb-3">
                    <p className="fs-6 text-secondary">Nationality</p>
                    <p className="fs-6">{transCustomer.param_nationality.name}</p>
                  </div>
                  <div className="col-6">
                    <p className="fs-6 text-secondary">Original Address</p>
                    <p className="fs-6">{transCustomer.original_address}</p>
                  </div>
                  <div className="col-6 mb-3">
                    <p className="fs-6 text-secondary">Address in Indonesia</p>
                    <p className="fs-6">{transCustomer.domicile_address}</p>
                  </div>
                  <div className="col-6">
                    <p className="fs-6 text-secondary">Social Media</p>
                    <p className="fs-6">{transCustomer.social_media}</p>
                  </div>
                  <div className="col-6 mb-3">
                    <p className="fs-6 text-secondary">Job</p>
                    <p className="fs-6">{transCustomer.job}</p>
                  </div>
                  <div className="col-6">
                    <p className="fs-6 text-secondary">Company</p>
                    <p className="fs-6">{transCustomer.company}</p>
                  </div>
                </div>
                <p className="fs-4 mb-4">Documents</p>
                <div className="row d-flex justify-content-between">
                  <div className="col-12 mb-3">
                    <p className="fs-6 text-secondary">Passport Number</p>
                    <p className="fs-6">{transCustomer.passport_number}</p>
                  </div>
                  <div className="col-6 mb-3">
                    <p className="fs-6 text-secondary">Passport Issued Date</p>
                    <p className="fs-6">{Formatter.beautyDate(transCustomer.passport_date_issued)}</p>
                  </div>
                  <div className="col-6">
                    <p className="fs-6 text-secondary">Passport Expired Date</p>
                    <p className="fs-6">{Formatter.beautyDate(transCustomer.passport_date_expired)}</p>
                  </div>
                  {/* <div className="col-12">
                    <p className="fs-6 text-secondary">Files</p>
                    {transCustomer.customer_file.map((data) => {
                      const url = Storage + `/Services/Tracking/Customer/${transCustomer.id}/`;
                      let type = "";

                      if (data.file_name.slice(0, 8) === "passport") {
                        type = true;
                      } else {
                        type = false;
                      }

                      return (
                        <>
                          <div className="col-12 mb-3">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                if (type === true) {
                                  window.open(url + data.file_name, "_blank");
                                } else {
                                  window.open(url + data.file_name, "_blank");
                                }
                              }}
                            >
                              {type === true ? "Preview Passport" : "Preview Other Files"}
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </div> */}
                </div>
              </>
            ) : (
              <div className="text-center">
                <div className="fs-2">
                  <i className="bi bi-info-circle"></i>
                </div>
                <p className="fs-3">Data Empty</p>
                <p className="fs-6 text-secondary">Make sure the customer has filled in the data.</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <AppFooter />
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { Navigate, Link, useParams } from "react-router-dom";
import { API } from "../../Config/Config";

export default function CreateModules() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const { service } = useParams();

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Services", slug: "/services", status: "" },
    { id: "3", title: "Modules", slug: `/services/${service}/modules`, status: "" },
    { id: "4", title: "Create Modules", slug: "/modules/create", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const [listParent, setListParent] = useState([]);

  const [name, setName] = useState("");
  const [icon, setIcon] = useState([]);
  const [parent, setParent] = useState("");
  const [iconUrls, setIconUrls] = useState([]);
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [status, setStatus] = useState("");
  const [validation, setValidation] = useState([]);
  const [formData, setFormData] = useState("");

  const fetchParent = useCallback(async () => {
    await axios.get(api + `/services/${service}/modules/parent`, header).then((response) => {
      setListParent(response.data.data);
    });
  }, [api, header, service]);

  useEffect(() => {
    fetchParent();
    setFormData(new FormData());

    if (icon.length < 1) return;
    const newIconUrls = [];
    icon.forEach((icons) => newIconUrls.push(URL.createObjectURL(icons)));
    setIconUrls(newIconUrls);
  }, [icon, fetchParent]);

  function onIconChange(e) {
    setIcon([...e.target.files]);
  }

  const handleToggle = () => {
    setStatus(!status);
    var data = !status ? 1 : 0;
    formData.append("status", data);
    // console.log(data);
  };

  const saveModules = async (e) => {
    e.preventDefault();

    formData.append("name", name);
    formData.append("description", description);
    formData.append("icon", ...icon);
    formData.append("slug", slug);
    formData.append("parent", parent);

    // console.log(...formData);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/services/${service}/modules/create`, formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          test: error.response.data.message,
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/services" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <form encType="multipart/form-data" onSubmit={saveModules}>
                <div className="alert alert-secondary p-2" role="alert">
                  <p className="small p-0 m-0">
                    <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                    Sign <span className="text-danger">*</span> is a mandatory data
                  </p>
                </div>
                {validation.errMessage && (
                  <div className={`alert alert-danger ${validation.errMessage ? "d-block" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle"></i>
                    {validation.errMessage[0]}
                  </div>
                )}
                <div className="mb-3">
                  {iconUrls.map((imageSrc, i) => (
                    <img
                      alt="Icon"
                      key={i}
                      src={imageSrc}
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "125px",
                        height: "125px",
                        borderRadius: "50%",
                        marginBottom: "2rem",
                      }}
                    />
                  ))}
                  <label htmlFor="formFile" className="form-label">
                    *Icon Services
                  </label>
                  <input type="file" multiple accept="image/*" onChange={onIconChange} name="icon" className="form-control" required />
                  {validation.icon && <div className="alert alert-danger">{validation.icon[0]}</div>}
                </div>
                <div className="form-floating mb-3">
                  <select className="form-select" value={parent} onChange={(e) => setParent(e.target.value)} id="floatingSelect" aria-label="Floating label select example">
                    <option>Select Parent</option>
                    {listParent.map((data, id) => {
                      return (
                        <option key={id} value={data.data.id}>
                          {data.data.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="floatingSelect">Parent</label>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} className={`form-control ${validation.name ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Name <sup className="text-danger">*</sup>
                  </label>
                  {validation.name && (
                    <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.name[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <textarea className={`form-control ${validation.description ? "is-invalid" : ""}`} value={description} onChange={(e) => setDescription(e.target.value)} id="textarea" style={{ height: "100px" }} />
                  <label htmlFor="textarea">Description</label>
                  {validation.name && (
                    <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.name[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} className={`form-control ${validation.slug ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">
                    Slug <sup className="text-danger">*</sup>
                  </label>
                  {validation.slug && (
                    <div className={`invalid-feedback ${validation.slug ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.slug[0]}
                    </div>
                  )}
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input" onChange={handleToggle} value={status} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Status
                  </label>
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-2">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to={`/services/${service}/modules`} replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { API, Storage } from "../../Config/Config";

export default function EditModules() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //get api
  const api = API;

  const { service } = useParams();
  const { module } = useParams();

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Services", slug: "/services", status: "" },
    {
      id: "3",
      title: "Modules",
      slug: `/services/${service}/modules`,
      status: "",
    },
    { id: "4", title: "Edit Modules", slug: "/modules/edit", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const [formData, setFormData] = useState("");
  const [listParent, setListParent] = useState([]);
  const [validation, setValidation] = useState([]);

  const [name, setName] = useState("");
  const [parent, setParent] = useState("");
  const [image, setImage] = useState("");
  const [icon, setIcon] = useState([]);
  const [iconUrls, setIconUrls] = useState([]);
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [status, setStatus] = useState("");

  const fetchParent = useCallback(async () => {
    await axios
      .get(api + `/services/${service}/modules/parent`, header)
      .then((response) => {
        setListParent(response.data.data);
      });
  }, [api, header, service]);

  useEffect(() => {
    if (icon.length < 1) return;
    const newIconUrls = [];
    icon.forEach((icons) => newIconUrls.push(URL.createObjectURL(icons)));
    setIconUrls(newIconUrls);
  }, [icon]);

  function onIconChange(e) {
    setIcon([...e.target.files]);
  }

  const fetchDataModules = useCallback(async () => {
    await axios
      .get(api + `/services/${service}/modules/data/${module}`, header)
      .then(function (response) {
        let modules = response.data.data.data;
        // console.log(response.data.data);
        setName(modules.name);
        setParent(modules.parent);
        setImage(modules.icon);
        setDescription(modules.description);
        setSlug(modules.slug);
        setStatus(modules.status);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Erro Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [api, header, module, service]);

  const handleToggle = () => {
    setStatus(!status);
    var data = !status ? 1 : 0;
    // formData.append("status", data);
  };

  if (parent === null) {
    setParent("");
  }

  const updateModules = async (e) => {
    e.preventDefault();

    formData.append("name", name);
    formData.append("description", description);
    formData.append("slug", slug);
    formData.append("parent", parent);
    formData.append("icon", icon[0]);
    formData.append("status", status ? 1 : 0);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // console.log(...formData);

    await axios
      .post(
        api + `/services/${service}/modules/update/${module}`,
        formData,
        header
      )
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    setFormData(new FormData());
    fetchParent();
    fetchDataModules();
  }, [fetchParent, fetchDataModules, token]);

  return (
    <>
      <AppHeader />
      {success ? (
        <>
          <div className="d-flex flex-row align-items-center align-self-center ps-4 pe-4 pt-2 pb-3 border-bottom mb-3">
            <div className="flex-grow-1">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>
              <Link
                to={`/services/${service}/modules`}
                className="btn btn-primary"
                role="button"
              >
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Back
              </Link>
            </div>
          </div>
          <div className="container-fluid mb-5">
            <form
              className="p-4"
              encType="multipart/form-data"
              onSubmit={updateModules}
            >
              <div className="alert alert-info" role="alert">
                <p className="small p-0 m-0">
                  <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                  Sign <span className="text-danger">*</span> is a mandatory
                  data
                </p>
              </div>
              {validation.errMessage && (
                <div
                  className={`alert alert-danger ${
                    validation.errMessage ? "d-block" : "d-none"
                  }`}
                >
                  <i className="bi bi-exclamation-circle"></i>
                  {validation.errMessage[0]}
                </div>
              )}
              <div className="mb-3">
                <img
                  alt="Icon"
                  src={Storage + `/icon/modules/${image}`}
                  style={{
                    display: icon.length < 1 ? "block" : "none",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "125px",
                    height: "125px",
                    borderRadius: "50%",
                    marginBottom: "2rem",
                  }}
                />
                {iconUrls.map((imageSrc, i) => (
                  <img
                    key={i}
                    alt="Icon"
                    src={imageSrc}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                      marginBottom: "2rem",
                    }}
                  />
                ))}
                <label htmlFor="formFile" className="form-label">
                  *Icon Services
                </label>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={onIconChange}
                  name="icon"
                  className="form-control"
                />
                {validation.icon && (
                  <div className="alert alert-danger">{validation.icon[0]}</div>
                )}
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  value={parent || ""}
                  onChange={(e) => setParent(e.target.value) || ""}
                  id="floatingSelect"
                  aria-label="Floating label select example"
                >
                  <option value="">Select Parent</option>
                  {listParent.map((data, id) => {
                    return (
                      <option key={id} value={data.data.id}>
                        {data.data.name}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="floatingSelect">Parent</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput">*Name</label>
                {validation.name && (
                  <div className="alert alert-danger">{validation.name[0]}</div>
                )}
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                ></textarea>
                <label htmlFor="floatingTextarea2">Description</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput">*Slug</label>
                {validation.slug && (
                  <div className="alert alert-danger">{validation.slug[0]}</div>
                )}
              </div>
              <div className="form-check form-switch mb-3">
                <input
                  className="form-check-input"
                  onChange={handleToggle}
                  checked={status}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  *Status
                </label>
              </div>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div>
        </>
      ) : (
        <Navigate to={`/services/${service}/modules`} replace={true} />
      )}

      <AppFooter />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../Config/Config";
// import { CButton, CCard, CCardBody, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CRow } from "@coreui/react";

export default function Register() {
  let navigate = useNavigate();
  let location = useLocation();

  const [success, setSuccess] = useState(false);

  const api = API;

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [validation, setValidation] = useState([]);

  const signUp = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();

    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("confirm_password", confirm_password);
    formData.append("check_eula", 1);
    formData.append("mobile", "0819283923");

    await axios
      .post(api + "/users/register", formData, {
        headers: { "Content-type": "application/json" },
      })
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          console.log(response.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2500,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setSuccess(true);
    }
  }, []);

  return (
    <>
      {success ? (
        <Navigate to="/login" replace={true} />
      ) : (
        <div className="d-flex min-vh-100 min-vw-100 text-left bg-light">
          <form className="w-30 m-auto">
            <div className="logo px-5 text-center">
              <img
                className="mb-4"
                src="/assets/brand/maxxs-logo-h-text-black.png"
                alt="Maxx's Group International"
                height="65"
              />
              <h1 className="h3 mb-3">Create account</h1>
            </div>
            {validation.errMessage && (
              <div
                className={`alert alert-danger ${
                  validation.errMessage ? "d-block" : "d-none"
                }`}
              >
                <i className="bi bi-exclamation-circle me-2"></i>
                {validation.errMessage[0]}
              </div>
            )}
            <div className="form-floating mb-1">
              <input
                type="text"
                className={`form-control ${
                  validation.first_name ? "is-invalid" : ""
                }`}
                id="floatingFirstName"
                placeholder="First Name"
                required
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <label htmlFor="floatingFirstName">First Name</label>
              {validation.first_name && (
                <div
                  className={`invalid-feedback ${
                    validation.first_name ? "d-block mb-3" : "d-none"
                  }`}
                >
                  <i className="bi bi-exclamation-circle me-2"></i>
                  {validation.first_name[0]}
                </div>
              )}
            </div>
            <div className="form-floating mb-1">
              <input
                type="text"
                className={`form-control ${
                  validation.last_name ? "is-invalid" : ""
                }`}
                id="floatingLastName"
                placeholder="Last Name"
                required
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
              <label htmlFor="floatingLastName">Last Name</label>
              {validation.last_name && (
                <div
                  className={`invalid-feedback ${
                    validation.last_name ? "d-block mb-3" : "d-none"
                  }`}
                >
                  <i className="bi bi-exclamation-circle me-2"></i>
                  {validation.last_name[0]}
                </div>
              )}
            </div>
            <div className="form-floating mb-1">
              <input
                type="email"
                className={`form-control ${
                  validation.email ? "is-invalid" : ""
                }`}
                id="floatingEmail"
                required
                placeholder="youremail@yourdomain.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="floatingEmail">Email</label>
              {validation.email && (
                <div
                  className={`invalid-feedback ${
                    validation.email ? "d-block mb-3" : "d-none"
                  }`}
                >
                  <i className="bi bi-exclamation-circle me-2"></i>
                  {validation.email[0]}
                </div>
              )}
            </div>
            <div className="form-floating mb-1">
              <input
                type="password"
                className={`form-control ${
                  validation.password ? "is-invalid" : ""
                }`}
                id="floatingPassword"
                required
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="floatingPassword">Password</label>
              {validation.password && (
                <div
                  className={`invalid-feedback ${
                    validation.password ? "d-block mb-3" : "d-none"
                  }`}
                >
                  <i className="bi bi-exclamation-circle me-2"></i>
                  {validation.password[0]}
                </div>
              )}
            </div>
            <div className="form-floating mb-4">
              <input
                type="password"
                className={`form-control ${
                  validation.confirm_password ? "is-invalid" : ""
                }`}
                id="floatingConfirmPassword"
                required
                placeholder="Confirm Password"
                value={confirm_password}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <label htmlFor="floatingConfirmPassword">Confirm Password</label>
              {validation.confirm_password && (
                <div
                  className={`invalid-feedback ${
                    validation.confirm_password ? "d-block" : "d-none"
                  }`}
                >
                  <i className="bi bi-exclamation-circle me-2"></i>
                  {validation.confirm_password[0]}
                </div>
              )}
            </div>
            <button className="w-100 btn btn-lg btn-primary" onClick={signUp}>
              Sign Up
            </button>
            <div className="mt-3 text-center">
              <button
                type="button"
                className="btn btn-link link-primary text-decoration-none"
                onClick={() => {
                  navigate("/login" + location.search);
                }}
              >
                Sign In instead
              </button>
            </div>
            <p className="mt-3 mb-2 text-muted small text-center">
              &copy; 2022 - Maxx's Group International.
              <br />
              All rights reserved.
            </p>
          </form>
        </div>
      )}
    </>
  );
}

import React, { useState, useMemo } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
} from "react-table";

export default function TableCustomerAddress({ columns, data }) {
  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 800);

    return (
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records ...`}
      ></input>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        placeholder={`Search ${count} records ...`}
      ></input>
    );
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    // state: { pageIndex, pageSize },
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        // hiddenColumns: ["Action"],
        pageSize: 5,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            Data Filter:
          </span>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <br />
        <table {...getTableProps()} className="table table-sm table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((columns) => {
                  return columns.hideHeader === false ? null : (
                    <th
                      className="small"
                      {...columns.getHeaderProps(
                        columns.getSortByToggleProps()
                      )}
                    >
                      {columns.render("Header")}
                      <span>
                        {columns.isSorted ? (
                          columns.isSortedDesc ? (
                            <i className="bi bi-arrow-down text-muted ms-1"></i>
                          ) : (
                            <i className="bi bi-arrow-up text-muted ms-1"></i>
                          )
                        ) : columns.disableSortBy === true ? null : (
                          <i className="bi bi-arrow-down-up text-muted ms-1"></i>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="align-middle" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex p-0 m-0 align-items-center justify-content-center mb-5">
          <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm p-0 m-0">
              <li
                className="page-item"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <a className="page-link px-3" href="#!">
                  &laquo;&laquo;
                </a>
              </li>
              <li
                className="page-item"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <a className="page-link px-3" href="#!">
                  &laquo;
                </a>
              </li>
              <li className="page-item">
                <a className="page-link px-3" href="#!">
                  Page {pageIndex + 1} of {pageOptions.length}
                </a>
              </li>
              <li
                className="page-item"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <a className="page-link px-3" href="#!">
                  &raquo;
                </a>
              </li>
              <li
                className="page-item"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <a className="page-link px-3" href="#!">
                  &raquo;&raquo;
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

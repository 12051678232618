import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { API } from "../../Config/Config";
import AppFormater from "../../Components/AppFormater";
import AppSpinner from "../../Components/AppSpinner";

export default function DashboardDataSummary() {
  //get api from local storage
  const apiUrl = API;
  //get token from local storage
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [getLoadingStatus, setLoadingStatus] = useState(true);

  const [getBranches, setBranches] = useState("");
  const [getCities, setCities] = useState("");
  const [getCustomers, setCustomers] = useState("");
  const [getCountries, setCountries] = useState("");
  const [getDepartments, setDepartments] = useState("");
  const [getEmployees, setEmployees] = useState("");
  const [getParameters, setParameters] = useState("");
  const [getStates, setStates] = useState("");
  const [getServices, setServices] = useState("");
  const [getUsers, setUsers] = useState("");

  useEffect(() => {
    axios
      .get(apiUrl + "/dashboard/datasummary", header)
      .then(function (response) {
        // console.log(response)
        setUsers(response.data.data.users);
        setBranches(response.data.data.branches);
        setCustomers(response.data.data.customers);
        setCountries(response.data.data.countries);
        setCities(response.data.data.cities);
        setDepartments(response.data.data.departments);
        setEmployees(response.data.data.employees);
        setParameters(response.data.data.parameters);
        setStates(response.data.data.states);
        setServices(response.data.data.services);
        setLoadingStatus(false);
      })
      .catch(function (error) {
        // console.log(error);
        setLoadingStatus(false);
      });
  }, [apiUrl, header, getLoadingStatus]);

  return (
    <>
      <div>
        <div className="fs-5">
          <i className="bi bi-activity me-2"></i>Data Summary
        </div>
        <hr />

        {getLoadingStatus ? (
          <AppSpinner title="Loading data summary..." />
        ) : (
          <div>
            <div className="row row-cols-2">
              <div className="col-12 px-3">
                <div className="fs-6 text-center bg-secondary-subtle p-2 rounded">
                  <i className="bi bi-database me-2"></i>Database
                </div>
                <div className="table-responsive">
                  <table className="table table-sm align-middle">
                    <tbody>
                      <tr>
                        <td className="fw-light">Customers</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getCustomers)}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td className="fw-light">Users</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getUsers)}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td className="fw-light">Employees</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getEmployees)}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td className="fw-light">Departments</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getDepartments)}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td className="fw-light">Branches</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getBranches)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row row-cols-2">
              <div className="col-12 px-3">
                <div className="fs-6 text-center bg-secondary-subtle p-2 rounded">
                  <i className="bi bi-sliders2-vertical me-2"></i>Master
                </div>
                <div className="table-responsive">
                  <table className="table table-sm align-middle">
                    <tbody>
                      <tr>
                        <td className="fw-light">Countries</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getCountries)}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td className="fw-light">States</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getStates)}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td className="fw-light">Cities</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getCities)}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td className="fw-light">Parameters</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getParameters)}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td className="fw-light">Services</td>
                        <td className="text-end font-monospace">
                          {AppFormater.formatNumber(getServices)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

import React, { useEffect, useMemo } from "react";
import DataTableVendor from "./DataTableVendor";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { Link, Navigate } from "react-router-dom";

export default function IndexVendor() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Vendors", slug: "/vendor", status: "active" },
  ];

  useEffect(() => {
    if (!token) {
      Navigate({ to: "/login", replace: true });
    }
  });

  return (
    <>
      <AppHeader />
      <div className="d-flex flex-row border-bottom p-2 px-3">
        <div className="flex-grow-1 mt-1 small">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
        <div>
          <Link to="/vendor/create" className="btn btn-sm btn-primary">
            <div>
              <i className="bi bi-plus-circle me-2"></i>
              <span className="">Add New</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="containter-fluid pt-3 m-0 mb-2 px-1">
        <DataTableVendor />
      </div>
      <AppFooter />
    </>
  );
}

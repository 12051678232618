import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { API } from ".././Config/Config";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import ModalCustomerCard from "./ModalCustomerCard";

import Table from "./TableCustomerCard";

export default function DataTableCustomerCard() {
  // -- General Setting -- //
  const apiUrl = API;
  const { id } = useParams();

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [listCard, setListCard] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cardID, setCardID] = useState("");
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const onShowModalEdit = (items) => {
    setShowModalEdit(true);
    setCardID(items);
    console.log(items);
  };

  const onCloseModalEdit = () => {
    setShowModalEdit(false);
    setCardID("");
  };

  const fetchCard = useCallback(async () => {
    setIsLoaded(false);
    await axios.get(apiUrl + `/customers/card/list/${id}`, header).then(function (response) {
      setListCard(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header, id]);

  // -- End General Setting -- //

  // ------------------------------- SECTION DELETE DATA CARD --------------------------- //
  const handleDelete = useCallback(
    async (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(apiUrl + `/customers/card/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchCard();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [apiUrl, fetchCard, header]
  );
  // ------------------------------- END SECTION DELETE DATA CARD --------------------------- //

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortby: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to="#" className="btn btn-sm btn-primary m-1" onClick={() => onShowModalEdit(value)}>
                    <i className="bi bi-pencil-fill"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "ID Card Type",
            accessor: "data.type_card.name",
          },
          {
            Header: "Remark",
            accessor: "data.remark",
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    fetchCard();
  }, [fetchCard]);

  if (!isLoaded) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <AppSpinner title="Loading data customer card ..." />
        </div>
      </>
    );
  }

  if (listCard.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-6 fw-bold text-body-emphasis">Data not found</h1>
            <div className="col-lg-6 mx-auto">
              <p className="fw-light mb-4">Customer Card ID not found, to start filling in new customer Card ID, please click the add new button below.</p>
              <button className="btn btn-primary btn-sm px-3" onClick={() => setShowModalNew(true)}>
                <i className="bi bi-plus-circle"></i> Add New Cards ID
              </button>
            </div>
          </div>
        </div>

        <ModalCustomerCard
          method="ADD"
          title="New Card ID"
          cardID="0"
          customerID={id}
          show={showModalNew}
          close={() => {
            setShowModalNew(false);
            fetchCard();
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-start p-0 m-0 pb-2">
        <button className="btn btn-primary btn-sm px-3" onClick={() => setShowModalNew(true)}>
          <i className="bi bi-plus-circle"></i> Add New Card ID
        </button>
      </div>
      <div className="table-responsive">
        <Table columns={columns} data={listCard} />
      </div>

      <ModalCustomerCard
        method="ADD"
        title="New Card ID"
        cardID="0"
        customerID={id}
        show={showModalNew}
        close={() => {
          setShowModalNew(false);
          fetchCard();
        }}
      />
      <ModalCustomerCard
        method="EDIT"
        title="Edit Card ID"
        cardID={cardID}
        show={showModalEdit}
        close={() => {
          onCloseModalEdit();
          fetchCard();
        }}
      />
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate, useParams } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
import Swal from "sweetalert2";
// import empty from "../Components/empty.jpg";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API, Storage } from ".././Config/Config";

export default function EditCustomer() {
  // --- BEGIN | General --- //
  const { id } = useParams();
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const apiUrl = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);
  // --- END | General --- //

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Customers", slug: "/customers", status: "" },
    {
      id: "3",
      title: "Edit",
      slug: `/customers/details/${id}`,
      status: "active",
    },
  ];

  const [success, setSuccess] = useState(true);

  const [listCountry, setListCountry] = useState([]);
  const [listState, setListState] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listGender, setListGender] = useState([]);
  const [listNationality, setListNationality] = useState([]);
  const [listReligion, setListReligion] = useState([]);
  const [listMarital, setListMarital] = useState([]);
  const [listBloodType, setListBloodType] = useState([]);
  const [listStatus, setListStatus] = useState([]);

  const [custID, setCustID] = useState("");
  const [photo, setPhoto] = useState([]);
  const [picture, setPicture] = useState([]);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [religion, setReligion] = useState("");
  const [marital, setMarital] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [bloodType, setBloodType] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [statusCustomer, setStatusCustomer] = useState("");
  const [validation, setValidation] = useState([]);

  const fetchParameter = useCallback(async () => {
    await axios.get(apiUrl + "/customers/parameter", header).then((response) => {
      setListGender(response.data.data.gender);
      setListNationality(response.data.data.nationality);
      setListReligion(response.data.data.religion);
      setListMarital(response.data.data.marital);
      setListBloodType(response.data.data.blood_type);
      setListStatus(response.data.data.status);
    });
  }, [apiUrl, header]);

  const fetchCountry = useCallback(async () => {
    await axios.get(apiUrl + "/customers/listCountry", header).then((response) => {
      setListCountry(response.data.data);
    });
  }, [apiUrl, header]);

  const fetchStates = useCallback(
    async (country) => {
      await axios.get(apiUrl + "/customers/listState?country=" + country, header).then((response) => {
        setListState(response.data.data);
      });
    },
    [apiUrl, header]
  );

  const fetchCities = useCallback(
    async (state) => {
      await axios.get(apiUrl + "/customers/listCity?state=" + state, header).then((response) => {
        setListCity(response.data.data);
      });
    },
    [apiUrl, header]
  );

  // const changeStates = (event) => {
  //   console.log("list states " + event.target.value);
  //   setAddressCountry(event.target.value);
  //   axios
  //     .get(
  //       apiUrl + "/customers/listState?country=" + event.target.value,
  //       header
  //     )
  //     .then((response) => {
  //       setListState(response.data.data);
  //       // console.log(response.data.data);
  //     });
  // };

  // const changeCities = (event) => {
  //   console.log("list states " + event.target.value);
  //   setAddressCountry(event.target.value);
  //   axios
  //     .get(apiUrl + "/customers/listCity?state=" + event.target.value, header)
  //     .then((response) => {
  //       setListCity(response.data.data);
  //     });
  // };

  function onPhotoChange(e) {
    setPhoto([...e.target.files]);
  }

  //Handle input null when create before
  if (religion === null) setReligion("");
  if (birthDate === null) setBirthDate("");
  if (marital === null) setMarital("");
  if (bloodType === null) setBloodType("");
  if (addressName === null) setAddressName("");
  if (addressStreet === null) setAddressStreet("");
  if (addressCountry === null) setAddressCountry("");
  if (addressState === null) setAddressState("");
  if (addressCity === null) setAddressCity("");
  if (addressZipCode === null) setAddressZipCode("");

  const updateCustomer = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("birth_place", birthPlace);
    formData.append("birth_date", birthDate);
    formData.append("gender", gender);
    formData.append("nationality", nationality);
    formData.append("religion", religion);
    formData.append("marital", marital);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("blood_type", bloodType);
    formData.append("address_name", addressName);
    formData.append("address_street", addressStreet);
    formData.append("address_city", addressCity);
    formData.append("address_state", addressState);
    formData.append("address_country", addressCountry);
    formData.append("address_zip_code", addressZipCode);
    formData.append("status", statusCustomer);
    formData.append("photo", photo[0]);

    // console.log(...formData);

    Swal.fire({
      title: "Updating customer data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(apiUrl + `/customers/update/${id}`, formData, header)
      .then(function (response) {
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        } else if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    // fetch Customer
    axios
      .get(apiUrl + `/customers/data/${id}`, header)
      .then(function (response) {
        let customers = response.data.data.data;
        // console.log(customers);
        setCustID(customers.id);
        setPicture(customers.photo);
        setFirstName(customers.first_name);
        setLastName(customers.last_name);
        setBirthPlace(customers.birth_place);
        setBirthDate(customers.birth_date);
        setGender(customers.gender);
        setNationality(customers.nationality);
        setReligion(customers.religion);
        setMarital(customers.marital);
        setEmail(customers.email);
        setMobile(customers.mobile);
        setBloodType(customers.blood_type);
        setAddressName(customers.address_name);
        setAddressStreet(customers.address_street);
        setAddressCity(customers.address_city);
        setAddressState(customers.address_state);
        console.log("load: state {" + customers.address_state + "} | city {" + customers.address_city + "}");
        setAddressCountry(customers.address_country);
        setAddressZipCode(customers.address_zip_code);
        setStatusCustomer(customers.status);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });
      });

    // fetch country dropdown list
    console.log("list Country");
    fetchCountry().finally(function () {
      fetchStates().finally(function () {
        fetchCities();
      });
    });
    // fetch parameters dropdown list
    console.log("list Parameters");
    fetchParameter();

    // check for photo
    if (photo.length < 1) return;
    const newPhotoUrls = [];
    photo.forEach((photos) => newPhotoUrls.push(URL.createObjectURL(photos)));
    setPhotoUrls(newPhotoUrls);
  }, [token, fetchCountry, fetchParameter, fetchStates, fetchCities, photo, apiUrl, header, id]);

  return (
    <>
      <AppHeader />
      {success ? (
        <>
          <div className="d-flex flex-row border-bottom p-2 px-3">
            <div className="flex-grow-1 mt-1 small">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>
              <a className="btn btn-sm btn-primary" href={`/customers/details/${id}`} role="button">
                <i className="bi bi-arrow-left me-2"></i>
                <span className="">Back</span>
              </a>
            </div>
          </div>
          <div className="container-fluid pt-3 m-0 mb-5 px-1">
            <form className="p-2" encType="multipart/form-data" onSubmit={updateCustomer}>
              {/* 1st Row */}
              <div className="row row-cols-1">
                <div className="col">
                  <div className="alert alert-info alert-dismissible fade show small" role="alert">
                    <i className="bi bi-info-circle me-2"></i>Fields marked with <span className="text-danger fw-bold">*</span> are mandatory.
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                </div>
              </div>
              <div className="row row-cols-1">
                <div className="col">
                  <p className="fw-bold float-start me-2">Basic Information</p>
                  <hr />
                </div>
              </div>
              <div className="row row-cols-1 row-cols-md-2">
                <div className="col col-md-9">
                  {/* <div className="row row-cols-1 row-cols-md-3">
                    <div className="col col-md-4 bg-warning">&nbsp;</div>
                    <div className="col col-md-4 bg-success">&nbsp;</div>
                    <div className="col col-md-4 bg-info">&nbsp;</div>
                  </div> */}
                  {/* Row */}
                  <div className="row row-cols-1 row-cols-md-3">
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <input id="firstName" type="text" required placeholder="First Name" value={firstName || ""} onChange={(e) => setFirstName(e.target.value)} className={`form-control ${validation.first_name ? "is-invalid" : ""}`} />
                        <label htmlFor="firstName" className="form-label z-0">
                          First Name<span className="ms-1 text-danger">*</span>
                        </label>
                        {validation.first_name && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.first_name[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <input id="lastName" type="text" required placeholder="Last Name" value={lastName || ""} onChange={(e) => setLastName(e.target.value)} className={`form-control ${validation.first_name ? "is-invalid" : ""}`} />
                        <label htmlFor="lastName" className="form-label z-0">
                          Last Name<span className="ms-1 text-danger">*</span>
                        </label>
                        {validation.last_name && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.last_name[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <select id="statusCustomer" className={`form-select ${validation.status ? "is-invalid" : ""}`} value={statusCustomer} onChange={(e) => setStatusCustomer(e.target.value)}>
                          <option>- Select -</option>
                          {listStatus.map((data, id) => (
                            <option key={id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="statusCustomer" className="form-label z-0">
                          Status<span className="ms-1 text-danger">*</span>
                        </label>
                        {validation.status && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.status[0]}</div>}
                      </div>
                    </div>
                  </div>
                  {/* Row */}
                  <div className="row row-cols-1 row-cols-md-3">
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <input
                          id="birthPlace"
                          type="text"
                          required
                          placeholder="Birth Place"
                          value={birthPlace || ""}
                          onChange={(e) => setBirthPlace(e.target.value)}
                          className={`form-control ${validation.birth_place ? "is-invalid" : ""}`}
                        />
                        <label htmlFor="birthPlace" className="form-label z-0">
                          Birth Place<span className="ms-1 text-danger">*</span>
                        </label>
                        {validation.birth_place && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.birth_place[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <input id="birthDate" type="date" required placeholder="Birth Date" value={birthDate || ""} onChange={(e) => setBirthDate(e.target.value)} className={`form-control ${validation.birth_date ? "is-invalid" : ""}`} />
                        <label htmlFor="birthDate" className="form-label z-0">
                          Birth Date<span className="ms-1 text-danger">*</span>
                        </label>
                        {validation.birth_date && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.birth_date[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <select id="gender" className={`form-select ${validation.gender ? "is-invalid" : ""}`} value={gender || ""} onChange={(e) => setGender(e.target.value)}>
                          <option>- Select -</option>
                          {listGender.map((data, id) => (
                            <option key={id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="gender" className="form-label z-0">
                          Gender
                        </label>
                        {validation.gender && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.gender[0]}</div>}
                      </div>
                    </div>
                  </div>
                  {/* Row */}
                  <div className="row row-cols-1 row-cols-md-3">
                    <div className="col col-md-6">
                      <div className="form-floating mb-3">
                        <input id="email" type="email" required placeholder="Email" value={email || ""} onChange={(e) => setEmail(e.target.value)} className={`form-control ${validation.email ? "is-invalid" : ""}`} />
                        <label htmlFor="email" className="form-label z-0">
                          Email<span className="ms-1 text-danger">*</span>
                        </label>
                        {validation.email && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.email[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <input id="mobile" type="text" required placeholder="Mobile Number" value={mobile || ""} onChange={(e) => setMobile(e.target.value)} className={`form-control ${validation.mobile ? "is-invalid" : ""}`} />
                        <label htmlFor="mobile" className="form-label z-0">
                          Mobile Number
                          <span className="ms-1 text-danger">*</span>
                        </label>
                        {validation.mobile && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.mobile[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-2">
                      <div className="form-floating mb-3">
                        <select id="bloodType" className={`form-select ${validation.blood_type ? "is-invalid" : ""}`} value={bloodType || ""} onChange={(e) => setBloodType(e.target.value)}>
                          <option>- Select -</option>
                          {listBloodType.map((data, id) => (
                            <option key={id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="bloodType" className="form-label z-0">
                          Blood Type
                        </label>
                        {validation.blood_type && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.blood_type[0]}</div>}
                      </div>
                    </div>
                  </div>
                  {/* Row */}
                  <div className="row row-cols-1 row-cols-md-3">
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <input id="addressName" type="text" value={addressName || ""} placeholder="Address Name" onChange={(e) => setAddressName(e.target.value)} className={`form-control ${validation.addressName ? "is-invalid" : ""}`} />
                        <label htmlFor="addressName" className="form-label z-0">
                          Address Name
                        </label>
                        {validation.addressName && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.addressName[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-6">
                      <div className="form-floating mb-3">
                        <textarea
                          rows={3}
                          id="addressStreet"
                          placeholder="Address Street"
                          value={addressStreet || ""}
                          onChange={(e) => setAddressStreet(e.target.value)}
                          className={`form-control ${validation.addressStreet ? "is-invalid" : ""}`}
                        ></textarea>
                        <label htmlFor="addressStreet" className="form-label z-0">
                          Address Street
                        </label>
                        {validation.addressStreet && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.addressStreet[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-2">
                      <div className="form-floating mb-3">
                        <input
                          id="addressZipCode"
                          type="number"
                          maxLength={5}
                          placeholder="Zip Code"
                          value={addressZipCode || ""}
                          onChange={(e) => setAddressZipCode(e.target.value)}
                          className={`form-control ${validation.addressZipCode ? "is-invalid" : ""}`}
                        />
                        <label htmlFor="addressZipCode" className="form-label z-0">
                          Zip Code
                        </label>
                        {validation.addressZipCode && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.addressZipCode[0]}</div>}
                      </div>
                    </div>
                  </div>
                  {/* Row */}
                  <div className="row row-cols-1 row-cols-md-3">
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <select id="addressCountry" className={`form-select ${validation.address_country ? "is-invalid" : ""}`} value={addressCountry || ""} onChange={(e) => fetchStates(e.target.value)}>
                          <option>- Select -</option>
                          {listCountry.map((data, id) => (
                            <option key={id} value={data.data.id}>
                              {data.data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="addressCountry" className="form-label z-0">
                          Country
                        </label>
                        {validation.address_country && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_country[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <select id="addressState" className={`form-select ${validation.address_state ? "is-invalid" : ""}`} value={addressState || ""} onChange={(event) => fetchCities(event)}>
                          <option>- Select -</option>
                          {listState.map((data, id) => (
                            <option key={id} value={data.data.id}>
                              {data.data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="addressState" className="form-label z-0">
                          State
                        </label>
                        {validation.address_state && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_state[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <select id="addressCity" className={`form-select ${validation.address_city ? "is-invalid" : ""}`} value={addressCity || ""} onChange={(e) => setAddressCity(e.target.value)}>
                          <option>- Select -</option>
                          {listCity.map((data, id) => (
                            <option key={id} value={data.data.id}>
                              {data.data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="addressCity" className="form-label z-0">
                          City
                        </label>
                        {validation.address_city && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_city[0]}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-md-3">
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <select id="religion" className={`form-select ${validation.religion ? "is-invalid" : ""}`} value={religion || ""} onChange={(e) => setReligion(e.target.value)}>
                          <option>- Select -</option>
                          {listReligion.map((data, id) => (
                            <option key={id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="religion" className="form-label z-0">
                          Religion
                        </label>
                        {validation.religion && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.religion[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <select id="nationality" className={`form-select ${validation.nationality ? "is-invalid" : ""}`} value={nationality || ""} onChange={(e) => setNationality(e.target.value)}>
                          <option>- Select -</option>
                          {listNationality.map((data, id) => (
                            <option key={id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="nationality" className="form-label z-0">
                          Nationality
                        </label>
                        {validation.nationality && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.nationality[0]}</div>}
                      </div>
                    </div>
                    <div className="col col-md-4">
                      <div className="form-floating mb-3">
                        <select id="marital" className={`form-select ${validation.marital ? "is-invalid" : ""}`} value={marital || ""} onChange={(e) => setMarital(e.target.value)}>
                          <option>- Select -</option>
                          {listMarital.map((data, id) => (
                            <option key={id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="marital" className="form-label z-0">
                          Marital
                        </label>
                        {validation.marital && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.marital[0]}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-md-3 p-1">
                  <div className="row row-cols-1 p-0 m-0 align-items-end h-100">
                    <div className="col align-self-end">
                      <div className="row row-cols-1 p-0 m-0">
                        <div className="col">
                          <div className="mb-3">
                            {picture !== "empty.jpg" ? (
                              <img
                                alt="gambar"
                                src={Storage + `/user_profile/${custID}/${picture}`}
                                style={{
                                  display: photo.length < 1 ? "block" : "none",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  maxHeight: "7rem",
                                  borderRadius: "20%",
                                  marginBottom: "2rem",
                                }}
                              />
                            ) : (
                              <img
                                alt="default"
                                src={""}
                                style={{
                                  display: photo.length < 1 ? "block" : "none",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  maxHeight: "7rem",
                                  borderRadius: "20%",
                                  marginBottom: "2rem",
                                }}
                              />
                            )}
                            {photoUrls.map((imageSrc, id) => (
                              <img
                                className="img-fluid img-thumbnail"
                                alt="profile"
                                key={id}
                                src={imageSrc}
                                style={{
                                  display: "block",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  maxHeight: "7rem",
                                  borderRadius: "20%",
                                  marginBottom: "2rem",
                                }}
                              />
                            ))}
                            <label htmlFor="formFile" className="form-label z-0">
                              Upload Profile Picture
                            </label>
                            <input id="formFile" type="file" accept="image/*" onChange={onPhotoChange} name="photo" className={`form-control ${validation.photo ? "is-invalid" : ""}`} />
                            {validation.photo && <div className="alert alert-danger small p-1 px-2 mt-1">{validation.photo[0]}</div>}
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-grid mb-3">
                            <button type="submit" className="btn btn-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy me-2" viewBox="0 0 16 16">
                                <path d="M11 2H9v3h2V2Z" />
                                <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0ZM1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5Zm3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4v4.5ZM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V15Z" />
                              </svg>
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* <div className="container-fluid mb-5">
            <form
              className="p-4"
              encType="multipart/form-data"
              onSubmit={updateCustomer}
            >
              <div className="alert alert-info" role="alert">
                <FaIcons.FaInfoCircle size={20} /> Sign{" "}
                <span className="text-danger">*</span> is a mandatory data
              </div>
              <div className="mb-3">
                {picture !== "empty.jpg" ? (
                  <img
                    alt="gambar"
                    src={Storage + `/user_profile/${custID}/${picture}`}
                    style={{
                      display: photo.length < 1 ? "block" : "none",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                      marginBottom: "2rem",
                    }}
                  />
                ) : (
                  <img
                    alt="default"
                    src={empty}
                    style={{
                      display: photo.length < 1 ? "block" : "none",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                      marginBottom: "2rem",
                    }}
                  />
                )}
                {photoUrls.map((imageSrc, index) => (
                  <img
                    alt="picts"
                    key={index}
                    src={imageSrc}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                      marginBottom: "2rem",
                    }}
                  />
                ))}
                <label htmlFor="formFile" className="form-label">
                  *Photo Profile
                </label>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={onPhotoChange}
                  name="photo"
                  className={`form-control ${
                    validation.photo ? "is-invalid" : ""
                  }`}
                />
                {validation.photo && (
                  <div className="alert alert-danger">
                    {validation.photo[0]}
                  </div>
                )}
              </div>              
              
              <div className="form-floating mb-3">
                <select
                  className={`form-select ${
                    validation.address_country ? "is-invalid" : ""
                  }`}
                  value={addressCountry || ""}
                  onChange={changeState}
                >
                  <option value="">Select Address Country</option>
                  {listCountry.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Address Country</label>
                {validation.address_country && (
                  <div className="alert alert-danger">
                    {validation.address_country[0]}
                  </div>
                )}
              </div>
              <div className="form-floating mb-3">
                <select
                  className={`form-select ${
                    validation.address_state ? "is-invalid" : ""
                  }`}
                  value={addressState || ""}
                  onChange={changeCity}
                >
                  <option value="">Select Address State</option>
                  {listState.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Address State</label>
                {validation.address_state && (
                  <div className="alert alert-danger">
                    {validation.address_state[0]}
                  </div>
                )}
              </div>
              <div className="form-floating mb-3">
                <select
                  className={`form-select ${
                    validation.address_city ? "is-invalid" : ""
                  }`}
                  value={addressCity || ""}
                  onChange={(e) => setAddressCity(e.target.value)}
                >
                  <option value="">Select Address City</option>
                  {listCity.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Address City</label>
                {validation.address_city && (
                  <div className="alert alert-danger">
                    {validation.address_city[0]}
                  </div>
                )}
              </div>
              
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div> */}
        </>
      ) : (
        <Navigate to="/customers" replace={true} />
      )}
      <AppFooter />
    </>
  );
}

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../Config/Config";
import AppSpinner from "../Components/AppSpinner";

export default function ModalCustomerAddress(props) {
  // -- GENERAL -- //
  // const { id } = useParams();
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);
  // --  END GENERAL -- //

  // -- Module Variable -- //
  const [validation, setValidation] = useState([]);
  const [isLoading, setIsLoading] = useState(
    props.method === "EDIT" ? false : true
  );

  const [getListCity, setGetListCity] = useState([]);
  const [getListState, setGetListState] = useState([]);
  const [getListCountry, setGetListCountry] = useState([]);

  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  // -- End Module Variable -- //

  //-- Fetch List Country -- //
  const fetchListCountry = useCallback(async () => {
    await axios
      .get(api + "/customers/listCountry", header)
      .then(function (response) {
        setGetListCountry(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [api, header]);
  //-- End Fetch List Country -- //

  // -- Fetch List State | Edit Method -- //
  const fetchListState = useCallback(
    async (items) => {
      await axios
        .get(api + `/customers/listState?country=${items ? items : ""}`, header)
        .then(function (response) {
          setGetListState(response.data.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    [api, header]
  );
  // -- End Fetch List State -- //

  // -- Fetch List City | Edit Method -- //
  const fetchListCity = useCallback(
    async (items) => {
      await axios
        .get(api + `/customers/listCity?state=${items ? items : ""}`, header)
        .then(function (response) {
          setGetListCity(response.data.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    [api, header]
  );
  // -- End Fetch List City -- //

  // -- Load Customer Address -- //
  const dataCustomerAddress = useCallback(() => {
    if (props.method === "EDIT" && props.addressID !== "0") {
      axios
        .get(api + `/customers/address/data/${props.addressID}`, header)
        .then(function (response) {
          setIsLoading(true);
          let value = response.data.data.data;
          setAddressName(value.address_name);
          setAddressStreet(value.address_street);
          setAddressCity(value.address_city);
          setAddressState(value.address_state);
          setAddressCountry(value.address_country);
          setAddressZipCode(value.address_zip_code);

          fetchListState(value.address_country);
          fetchListCity(value.address_state);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [api, fetchListCity, fetchListState, header, props]);

  useEffect(() => {
    fetchListCountry();
    dataCustomerAddress();
  }, [fetchListCountry, dataCustomerAddress]);
  // -- End Load Customer Address -- //

  // -- Get List State -- //
  const selectState = async (items) => {
    setAddressCountry(items.target.value);

    await axios
      .get(api + `/customers/listState?country=${items.target.value}`, header)
      .then(function (response) {
        setGetListState(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // -- End Get List State -- //

  // -- Get List City -- //
  const selectCity = async (items) => {
    setAddressState(items.target.value);

    await axios
      .get(api + `/customers/listCity?state=${items.target.value}`, header)
      .then(function (response) {
        setGetListCity(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // -- End Get List City -- //

  //-- Save Data Customers Address -- //

  const saveAddress = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("address_name", addressName);
    formData.append("address_street", addressStreet);
    formData.append("address_city", addressCity);
    formData.append("address_state", addressState);
    formData.append("address_country", addressCountry);
    formData.append("address_zip_code", addressZipCode);

    Swal.fire({
      title: "Saving Address ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (props.method === "ADD") {
      formData.append("customer_id", props.customerID);

      await axios
        .post(api + `/customers/address/create`, formData, header)
        .then(function (response) {
          if (response.data.status === "error") {
            setValidation(response.data.data);
            Swal.fire({
              icon: "error",
              text: response.data.message,
              timer: 2000,
            });
          } else if (response.data.status === "success") {
            setValidation([]);
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });

            setAddressName("");
            setAddressStreet("");
            setAddressCity("");
            setAddressState("");
            setAddressCountry("");
            setAddressZipCode("");

            props.close();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (props.method === "EDIT" && props.addressID !== "0") {
      await axios
        .post(
          api + `/customers/address/update/${props.addressID}`,
          formData,
          header
        )
        .then(function (response) {
          if (response.data.status === "error") {
            setValidation(response.data.data);
            Swal.fire({
              icon: "error",
              text: response.data.message,
              timer: 2000,
            });
          } else if (response.data.status === "success") {
            setValidation([]);
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });

            setAddressName("");
            setAddressStreet("");
            setAddressCity("");
            setAddressState("");
            setAddressCountry("");
            setAddressZipCode("");

            props.close();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("Method not Found");
    }
  };

  // -- End Save Data Customer Address -- //

  return (
    <>
      <Modal show={props.show} centered size="lg">
        {isLoading === false ? (
          <div className="d-flex justify-content-center align-items-center align-self-center">
            <AppSpinner title="Loading data customer address(es) ..." />
          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>
                <div className="fs-5 text-center">{props.title}</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="row row-cols-1 px-3 py-1" onSubmit={saveAddress}>
                <div className="row row-cols-1 row-cols-md-3">
                  <div className="col col-md-4">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        value={addressName}
                        placeholder="Address Name"
                        id="addressName"
                        onChange={(e) => setAddressName(e.target.value)}
                        className={`form-control ${
                          validation.address_name ? "is-invalid" : ""
                        }`}
                      />
                      <label htmlFor="addressName">Address Name</label>
                      {validation.address_name && (
                        <div className="alert alert-danger small p-1 px-2 mt-1">
                          {validation.address_name[0]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <div className="form-floating mb-3">
                      <textarea
                        rows={3}
                        id="addressStreet"
                        placeholder="Address Street"
                        value={addressStreet}
                        onChange={(e) => setAddressStreet(e.target.value)}
                        className={`form-control ${
                          validation.address_street ? "is-invalid" : ""
                        }`}
                      ></textarea>
                      <label htmlFor="addressStreet">Address Street</label>
                      {validation.address_street && (
                        <div className="alert alert-danger small p-1 px-2 mt-1">
                          {validation.address_street[0]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <div className="form-floating mb-3">
                      <input
                        id="addressZipCode"
                        type="number"
                        maxLength={5}
                        placeholder="Zip Code"
                        value={addressZipCode}
                        onChange={(e) => setAddressZipCode(e.target.value)}
                        className={`form-control ${
                          validation.address_zip_code ? "is-invalid" : ""
                        }`}
                      />
                      <label htmlFor="addressZipCode">Zip Code</label>
                      {validation.address_zip_code && (
                        <div className="alert alert-danger small p-1 px-2 mt-1">
                          {validation.address_zip_code}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-3">
                  <div className="col col-md-4">
                    <div className="form-floating mb-3">
                      <select
                        id="addressCountry"
                        className={`form-select ${
                          validation.address_country ? "is-invalid" : ""
                        }`}
                        value={addressCountry}
                        onChange={(e) => selectState(e)}
                      >
                        <option>Select Country . . .</option>
                        {getListCountry.map((data, index) => (
                          <option key={index} value={data.data.id}>
                            {data.data.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="addressCountry">Country</label>
                      {validation.address_country && (
                        <div className="alert alert-danger small p-1 px-2 mt-1">
                          {validation.address_country[0]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <div className="form-floating mb-3">
                      <select
                        id="addressState"
                        className={`form-control ${
                          validation.address_state ? "is-invalid" : ""
                        }`}
                        value={addressState}
                        onChange={(e) => selectCity(e)}
                      >
                        <option>Select State . . .</option>
                        {getListState.map((data, index) => (
                          <option key={index} value={data.data.id}>
                            {data.data.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="addressState">State</label>
                      {validation.address_state && (
                        <div className="alert alert-danger small p-1 px-2 mt-1">
                          {validation.address_state[0]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <div className="form-floating mb-3">
                      <select
                        id="addressCity"
                        className={`form-control ${
                          validation.address_city ? "is-invalid" : ""
                        }`}
                        value={addressCity}
                        onChange={(e) => setAddressCity(e.target.value)}
                      >
                        <option>Select City . . .</option>
                        {getListCity.map((data, index) => (
                          <option key={index} value={data.data.id}>
                            {data.data.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="addressCity">City</label>
                      {validation.address_city && (
                        <div className="alert alert-danger small p-1 px-2 mt-1">
                          {validation.address_city[0]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary px-2"
                    onClick={() => {
                      setValidation([]);
                      props.close();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary px-3">
                    Save
                  </button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}

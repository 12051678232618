import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";
import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API } from ".././Config/Config";

export default function EditPermission() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //get api from local storage
  const apiUrl = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [success, setSuccess] = useState(true);

  const { id } = useParams();

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [validation, setValidation] = useState([]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Permissions", slug: "/permission", status: "" },
    { id: "3", title: "Edit Permissions", slug: "/permission/edit", status: "active" },
  ];

  useEffect(() => {
    axios
      .get(apiUrl + `/permissions/data/${id}`, header)
      .then(function (response) {
        let permissions = response.data.data.data;
        setName(permissions.name);
        setSlug(permissions.slug);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: error,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [token, apiUrl, header, id]);

  const updatePermission = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("slug", slug);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await axios.post(apiUrl + `/permissions/update/${id}`, formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      {success ? (
        <>
          <AppHeader />
          <div className="d-flex flex-row align-items-center align-self-center ps-4 pe-4 pt-2 pb-3 border-bottom mb-3">
            <div className="flex-grow-1">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>
              <a className="btn btn-primary" href="/permission" role="button">
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Back
              </a>
            </div>
          </div>
          <div className="container-fluid">
            <form className="p-4" onSubmit={updatePermission}>
              <div className="alert alert-info" role="alert">
                <FaIcons.FaInfoCircle size={20} /> Sign <span className="text-danger">*</span> is a mandatory data
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">*Name</label>
                {validation.name && <div className="alert alert-danger">{validation.name[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">*Slug</label>
                {validation.slug && <div className="alert alert-danger">{validation.slug[0]}</div>}
              </div>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div>
        </>
      ) : (
        <Navigate to="/permission" replace={true} />
      )}
      <AppFooter />
    </>
  );
}

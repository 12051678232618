import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import AppSpinner from "../../Components/AppSpinner";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Table from "./TableProductPrice";
import { numberFormat } from "../../TransactionLegal/Visa/NumberFormat";
import { API } from "../../Config/Config";

export default function DataTableProductPrice() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);
  const api = API;

  const [success, setSuccess] = useState(true);
  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [product, setProduct] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchProduct = useCallback(async () => {
    await axios.get(api + "/tools/product-price/visa/list", header).then((response) => {
      setProduct(response.data.data);
      // console.log(response.data.data);
      setIsLoaded(true);
    });
  }, [api, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(api + `/tools/product-price/delete/${id}`, header)
            .then(function(response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchProduct();
            })
            .catch(function(error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header, fetchProduct]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/product-price/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Country",
            accessor: "country.name",
          },
          {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell: { value } }) => {
              return <p>{numberFormat(value)}</p>;
            },
          },
          {
            Header: "Show",
            accessor: "show",
            Cell: ({ cell: { value } }) => {
              return <div>{value === 1 ? "Yes" : "No"}</div>;
            },
          },
        ],
      },
    ],
    [handleDelete]
  );
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchProduct();
  }, [token, fetchProduct]);

  if (!isLoaded) {
    return <AppSpinner title="Loading data Product Price Visa . . ." />;
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={product} />
    </div>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { API } from "../Config/Config";

export default function CreateEmployee() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}`, "Content-type": "application/json" },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Employee", slug: "/employee", status: "" },
    { id: "3", title: "Create Employee", slug: "/employee/create", status: "active" },
  ];

  const [success, setSuccess] = useState(true);

  const [listCountry, setListCountry] = useState([]);
  const [listState, setListState] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listGender, setListGender] = useState([]);
  const [listNationality, setListNationality] = useState([]);
  const [listReligion, setListReligion] = useState([]);
  const [listMarital, setListMarital] = useState([]);
  const [listBloodType, setListBloodType] = useState([]);
  const [listOffice, setListOffice] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);

  const [office, setOffice] = useState("");
  const [department, setDepartment] = useState("");
  const [photo, setPhoto] = useState([]);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [religion, setReligion] = useState("");
  const [marital, setMarital] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [bloodType, setBloodType] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const [validation, setValidation] = useState([]);

  const fetchParameter = useCallback(async () => {
    await axios
      .get(api + "/employees/parameters", header)
      .then(function (response) {
        setListGender(response.data.data.gender);
        setListNationality(response.data.data.nationality);
        setListReligion(response.data.data.religion);
        setListMarital(response.data.data.marital);
        setListBloodType(response.data.data.blood_type);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });
      });
  }, [api, header]);

  const fetchCountry = useCallback(async () => {
    await axios
      .get(api + "/employees/list_country", header)
      .then((response) => {
        setListCountry(response.data.data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });
      });
  }, [api, header]);

  const fetchOffice = useCallback(async () => {
    await axios
      .get(api + "/employees/office", header)
      .then(function (response) {
        setListOffice(response.data.data);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });
      });
  }, [api, header]);

  const fetchDepartment = useCallback(async () => {
    await axios
      .get(api + "/employees/department", header)
      .then(function (response) {
        setListDepartment(response.data.data);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });
      });
  }, [api, header]);

  const changeState = (e) => {
    setAddressCountry(e.target.value);
    axios
      .get(api + "/employees/list_states?country=" + e.target.value, header)
      .then((response) => {
        setListState(response.data.data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });
      });
  };

  const changeCity = (e) => {
    setAddressState(e.target.value);
    axios
      .get(api + "/employees/list_cities?state=" + e.target.value, header)
      .then((response) => {
        setListCity(response.data.data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });
      });
  };

  useEffect(() => {
    if (photo.length < 1) return;
    const newPhotoUrls = [];
    photo.forEach((photos) => newPhotoUrls.push(URL.createObjectURL(photos)));
    setPhotoUrls(newPhotoUrls);
  }, [photo]);

  function onPhotoChange(e) {
    setPhoto([...e.target.files]);
  }

  const saveEmployee = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("office", office);
    formData.append("department", department);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("birth_place", birthPlace);
    formData.append("birth_date", birthDate);
    formData.append("gender", gender);
    formData.append("nationality", nationality);
    formData.append("religion", religion);
    formData.append("marital", marital);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("blood_type", bloodType);
    formData.append("address_name", addressName);
    formData.append("address_street", addressStreet);
    formData.append("address_city", addressCity);
    formData.append("address_state", addressState);
    formData.append("address_country", addressCountry);
    formData.append("address_zip_code", addressZipCode);
    formData.append("expired_date", expiredDate);
    formData.append("photo", photo[0]);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + "/employees/create", formData, header)
      .then(function (response) {
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        } else if (response.data.status === "error") {
          setValidation(response.data.data);
          // console.log(response.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
        });
      });
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }

    fetchParameter();
    fetchCountry();
    fetchOffice();
    fetchDepartment();
  }, [api, fetchParameter, fetchCountry, fetchDepartment, fetchOffice, token]);

  return (
    <>
      <AppHeader />
      {success ? (
        <>
          <div className="d-flex flex-row align-items-center align-self-center ps-4 pe-4 pt-2 pb-3 border-bottom mb-3">
            <div className="flex-grow-1">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>
              <a className="btn btn-primary" href="/employee" role="button">
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Back
              </a>
            </div>
          </div>
          <div className="container-fluid mb-5">
            <form className="p-4" encType="multipart/form-data" onSubmit={saveEmployee}>
              <div className="alert alert-info" role="alert">
                <i className="bi bi-exclamation-circle-fill"></i> Sign <span className="text-danger">*</span> is a mandatory data
              </div>
              <div className="mb-3">
                {photoUrls.map((imageSrc, id) => (
                  <img alt="profile" key={id} src={imageSrc} style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "125px", height: "125px", borderRadius: "50%", marginBottom: "2rem" }} />
                ))}
                <label htmlFor="formFile" className="form-label">
                  *Photo Profile
                </label>
                <input type="file" multiple accept="image/*" onChange={onPhotoChange} name="photo" className={`form-control ${validation.photo ? "is-invalid" : ""}`} />
                {validation.photo && <div className="alert alert-danger">{validation.photo[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.office ? "is-invalid" : ""}`} value={office} onChange={(e) => setOffice(e.target.value)}>
                  <option>Select Office</option>
                  {listOffice.map((data, id) => (
                    <option key={id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Office</label>
                {validation.office && <div className="alert alert-danger">{validation.office[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.department ? "is-invalid" : ""}`} value={department} onChange={(e) => setDepartment(e.target.value)}>
                  <option>Select Department</option>
                  {listDepartment.map((data, id) => (
                    <option key={id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Department</label>
                {validation.department && <div className="alert alert-danger">{validation.department[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className={`form-control ${validation.first_name ? "is-invalid" : ""}`} id="floatingInput" placeholder="firstName@example.com" />
                <label htmlFor="floatingInput">*First Name</label>
                {validation.first_name && <div className="alert alert-danger">{validation.first_name[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className={`form-control ${validation.last_name ? "is-invalid" : ""}`} id="floatingInput" placeholder="lastName@example.com" />
                <label htmlFor="floatingInput">*Last Name</label>
                {validation.last_name && <div className="alert alert-danger">{validation.last_name[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className={`form-control ${validation.email ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">*Email</label>
                {validation.email && <div className="alert alert-danger">{validation.email[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} className={`form-control ${validation.mobile ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">*Mobile Phone</label>
                {validation.mobile && <div className="alert alert-danger">{validation.mobile[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={birthPlace} onChange={(e) => setBirthPlace(e.target.value)} className={`form-control ${validation.birth_place ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">*Birth Place</label>
                {validation.birth_place && <div className="alert alert-danger">{validation.birth_place[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="date" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} className={`form-control ${validation.birth_date ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">*Birth Date</label>
                {validation.birth_date && <div className="alert alert-danger">{validation.birth_date[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.gender ? "is-invalid" : ""}`} value={gender} onChange={(e) => setGender(e.target.value)}>
                  <option>*Select Gender</option>
                  {listGender.map((data, id) => (
                    <option key={id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Gender</label>
                {validation.gender && <div className="alert alert-danger">{validation.gender[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.nationality ? "is-invalid" : ""}`} value={nationality} onChange={(e) => setNationality(e.target.value)}>
                  <option>Select Nationality</option>
                  {listNationality.map((data, id) => (
                    <option key={id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Nationality</label>
                {validation.nationality && <div className="alert alert-danger">{validation.nationality[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.religion ? "is-invalid" : ""}`} value={religion} onChange={(e) => setReligion(e.target.value)}>
                  <option>Select Religion</option>
                  {listReligion.map((data, id) => (
                    <option key={id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Religion</label>
                {validation.religion && <div className="alert alert-danger">{validation.religion[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.marital ? "is-invalid" : ""}`} value={marital} onChange={(e) => setMarital(e.target.value)}>
                  <option>Select Marital</option>
                  {listMarital.map((data, id) => (
                    <option key={id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Marital</label>
                {validation.marital && <div className="alert alert-danger">{validation.marital[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.blood_type ? "is-invalid" : ""}`} value={bloodType} onChange={(e) => setBloodType(e.target.value)}>
                  <option>Select Blood Type</option>
                  {listBloodType.map((data, id) => (
                    <option key={id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Blood Type</label>
                {validation.blood_type && <div className="alert alert-danger">{validation.blood_type[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={addressName} onChange={(e) => setAddressName(e.target.value)} className={`form-control ${validation.address_name ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">Address Name</label>
                {validation.address_name && <div className="alert alert-danger">{validation.address_name[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={addressStreet} onChange={(e) => setAddressStreet(e.target.value)} className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">Address Street</label>
                {validation.addressStreet && <div className="alert alert-danger">{validation.addressStreet[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.address_country ? "is-invalid" : ""}`} value={addressCountry} onChange={changeState}>
                  <option>Select Address Country</option>
                  {listCountry.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Address Country</label>
                {validation.address_country && <div className="alert alert-danger">{validation.address_country[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.address_state ? "is-invalid" : ""}`} value={addressState} onChange={changeCity}>
                  <option>Select Address State</option>
                  {listState.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Address State</label>
                {validation.address_state && <div className="alert alert-danger">{validation.address_state[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className={`form-select ${validation.address_city ? "is-invalid" : ""}`} value={addressCity} onChange={(e) => setAddressCity(e.target.value)}>
                  <option>Select Address City</option>
                  {listCity.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Address City</label>
                {validation.address_city && <div className="alert alert-danger">{validation.address_city[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={addressZipCode} onChange={(e) => setAddressZipCode(e.target.value)} className={`form-control ${validation.address_zip_code ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">Address Zip Code</label>
                {validation.address_zip_code && <div className="alert alert-danger">{validation.address_zip_code[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="date" className={`form-control ${validation.expired_date ? "is-invalid" : ""}`} value={expiredDate} onChange={(e) => setExpiredDate(e.target.value)} id="floatingInput" placeholder="Expired Date" />
                <label htmlFor="floatingInput">Expired Date</label>
                {validation.notes && <div className="alert alert-danger mt-2">{validation.notes[0]}</div>}
              </div>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div>
        </>
      ) : (
        <Navigate to="/employee" replace={true} />
      )}
      <AppFooter />
    </>
  );
}

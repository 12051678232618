import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import AppSpinner from "../../Components/AppSpinner";
import Table from "./TableMOU";
import { API } from "../../Config/Config";

export default function DataTableMOU() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const [success, setSuccess] = useState(true);
  const [mou, setMOU] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const fetchMOU = useCallback(async () => {
    await axios.get(api + "/tools/mou/list", header).then((response) => {
      setMOU(response.data.data);
      setIsLoaded(true);
    });
  }, [api, header]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Title",
            accessor: "data.title",
          },
          {
            Header: "Version",
            accessor: "data.version",
          },
          {
            Header: "Status",
            accessor: "data.status",
            Cell: ({ cell: { value } }) => {
              return value === 1 ? <p>Active</p> : <p>Not Active</p>;
            },
          },
        ],
      },
    ],
    []
  );
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchMOU();
  }, [token, fetchMOU]);

  if (!isLoaded) {
    return <AppSpinner title="Loading data  . . ." />;
  }

  if (mou.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-6 fw-bold text-body-emphasis">Data not found</h1>
            <div className="col-lg-6 mx-auto">
              <p className="fw-light mb-4">Data MOU not found, to start filling in new data, please click the add new button .</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={mou} />
    </div>
  );
}

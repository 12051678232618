import React from "react";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

export default function IndexNewTransaction() {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <>
      <AppHeader />
      <div className="container">
        <div className="row">
          <h5 className="mb-4">Legal Documents - On Progress</h5>
          <div className="card mb-5">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <div className="input-group mb-3 mt-3">
                  <span className="input-group-text">
                    <i className="bi bi-search text-muted"></i>
                  </span>
                  <input type="text" className="form-control" placeholder="Search Document Here" />
                </div>
              </div>
              <div className="col-md-4 mb-3 mt-3">
                <select className="form-select" aria-label="Default select example">
                  <option selected>All Documents</option>
                  <option value="1">Single Entry VISA - Social</option>
                  <option value="1">Multiple Entry VISA - Social</option>
                  <option value="1">Single Entry VISA - Business</option>
                  <option value="1">Multiple Entry VISA - Business</option>
                  <option value="2">KITAS</option>
                  <option value="3">KITAP</option>
                  <option value="3">PMA</option>
                </select>
              </div>
              <div className="col-md-4">
                <div className="input-group mb-3 mt-3">
                  <span className="input-group-text">
                    <i className="bi bi-calendar-fill text-muted"></i>
                  </span>
                  <div className="col-sm">
                    <DatePicker
                      className="form-control"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={true}
                      placeholderText="Date Transaction"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col">
                Status
                <button type="button" className="btn btn-sm btn-outline-primary ms-3 me-2">
                  New Request
                </button>
                <button type="button" className="btn btn-sm btn-outline-primary me-2">
                  Waiting Approval
                </button>
                <button type="button" className="btn btn-sm btn-outline-primary me-2">
                  Waiting Payment
                </button>
                <button type="button" className="btn btn-sm btn-outline-primary me-2">
                  Payment Complete
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="card border-0 shadow mb-3">
                <div className="card-body">
                  <p className="card-title" style={{ fontSize: "0.9rem", marginBottom: "1.2rem" }}>
                    <i className="bi bi-bookmarks-fill"></i>&nbsp;&nbsp;
                    <strong>Single Entry VISA - Social</strong>&nbsp;&nbsp; 20 August 2022 &nbsp;&nbsp;<span className="badge text-bg-primary">Waiting For Review</span>
                  </p>
                  <hr />
                  <div className="row justify-content-between">
                    <div className="col-md-4">
                      <p className="h5 card-text">
                        <strong>John Doe</strong>
                      </p>
                      <p className="card-text">TR92830293</p>
                    </div>
                    <div className="col-md-4">
                      <p className="h5 card-text">Total</p>
                      <p className="card-text">
                        <strong>IDR 5.550.000</strong>
                      </p>
                    </div>
                  </div>
                  <div className="float-end mt-4">
                    <Link to="/detailTransaction" className="btn btn-primary">
                      Detail Transaction
                    </Link>
                  </div>
                </div>
              </div>

              <div className="card border-0 shadow mb-3">
                <div className="card-body">
                  <p className="card-title" style={{ fontSize: "0.9rem", marginBottom: "1.2rem" }}>
                    <i className="bi bi-bookmarks-fill"></i>&nbsp;&nbsp;
                    <strong>KITAS</strong>&nbsp;&nbsp; 20 August 2022 &nbsp;&nbsp;<span className="badge text-bg-warning">Waiting Approval</span>
                  </p>
                  <hr />
                  <div className="row justify-content-between">
                    <div className="col-md-4">
                      <p className="h5 card-text">
                        <strong>Harley Potter</strong>
                      </p>
                      <p className="card-text">TR92830293</p>
                    </div>
                    <div className="col-md-4">
                      <p className="h5 card-text">Total</p>
                      <p className="card-text">
                        <strong>IDR 2.500.000</strong>
                      </p>
                    </div>
                  </div>
                  <div className="float-end mt-4">
                    <Link to="/detailTransaction" className="btn btn-primary">
                      Detail Transaction
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow mb-3">
                <div className="card-body">
                  <p className="card-title" style={{ fontSize: "0.9rem", marginBottom: "1.2rem" }}>
                    <i className="bi bi-bookmarks-fill"></i>&nbsp;&nbsp;
                    <strong>KITAP</strong>&nbsp;&nbsp; 20 August 2022 &nbsp;&nbsp;<span className="badge text-bg-warning">Waiting Payment</span>
                  </p>
                  <hr />
                  <div className="row justify-content-between">
                    <div className="col-md-4">
                      <p className="h5 card-text">
                        <strong>Alice Lawrence</strong>
                      </p>
                      <p className="card-text">TR92830293</p>
                    </div>
                    <div className="col-md-4">
                      <p className="h5 card-text">Total</p>
                      <p className="card-text">
                        <strong>IDR 4.350.000</strong>
                      </p>
                    </div>
                  </div>
                  <div className="float-end mt-4">
                    <Link to="/detailTransaction" className="btn btn-primary">
                      Detail Transaction
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
}

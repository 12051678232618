import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import Swal from "sweetalert2";
import { API } from "../../Config/Config";

export default function EditFormElement() {
  //get token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);
  //get api
  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Form Element", slug: "/form-element", status: "" },
    { id: "3", title: "Edit Form Element", slug: "/form-element/edit", status: "active" },
  ];

  const { id } = useParams();

  const [success, setSuccess] = useState(true);
  const [formData, setFormData] = useState("");

  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [slug, setSlug] = useState("");
  const [required, setRequired] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    axios
      .get(api + `/tools/form-element/data/${id}`, header)
      .then(function (response) {
        let element = response.data.data.data;
        setCaption(element.caption);
        setDescription(element.description);
        setType(element.type);
        setSlug(element.slug);
        setRequired(element.required);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });

    setFormData(new FormData());

    if (!token) {
      setSuccess(false);
    }
  }, [token, api, header, id]);

  const handleToggle = () => {
    setRequired(!required);
    var data = !required ? 1 : 0;
    formData.append("required", data);
    // console.log(data);
  };

  const updateFormElement = async (e) => {
    e.preventDefault();

    formData.append("caption", caption);
    formData.append("description", description);
    formData.append("type", type);
    formData.append("slug", slug);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // console.log(...formData);
    const response = await axios.post(api + `/tools/form-element/update/${id}`, formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  return (
    <>
      <AppHeader />
      {success ? (
        <>
          <div className="d-flex flex-row align-items-center align-self-center ps-4 pe-4 pt-2 pb-3 border-bottom mb-3">
            <div className="flex-grow-1">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div>
              <a className="btn btn-primary" href="/form-element" role="button">
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Back
              </a>
            </div>
          </div>
          <div className="container-fluid">
            <form className="p-4" encType="multipart/form-data" onSubmit={updateFormElement}>
              <div className="alert alert-info" role="alert">
                <i className="bi bi-info-circle-fill"></i> Sign <span className="text-danger">*</span> is a mandatory data
              </div>
              {validation.errMessage && (
                <div className={`alert alert-danger ${validation.errMessage ? "d-block" : "d-none"}`}>
                  <i className="bi bi-exclamation-circle"></i>
                  {validation.errMessage[0]}
                </div>
              )}
              <div className="form-floating mb-3">
                <input type="text" value={caption} onChange={(e) => setCaption(e.target.value)} className="form-control" id="floatingInput" />
                <label htmlFor="floatingInput">*Caption</label>
                {validation.caption && <div className="alert alert-danger">{validation.caption[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  value={description === null ? "" : description}
                  onChange={(e) => setDescription(e.target.value)}
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                ></textarea>
                <label htmlFor="floatingTextarea2">Description</label>
                {validation.description && <div className="alert alert-danger">{validation.description[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <select className="form-select" value={type} onChange={(e) => setType(e.target.value)} id="floatingSelect" aria-label="Floating label select example">
                  <option>Select Type Form</option>
                  <option value="text">Text</option>
                  <option value="text-area">Text Area</option>
                  <option value="select">Select</option>
                  <option value="file">File</option>
                </select>
                <label htmlFor="floatingSelect">*Form Type</label>
                {validation.type && <div className="alert alert-danger">{validation.type[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} className="form-control" id="floatingInput" />
                <label htmlFor="floatingInput">*Slug</label>
                {validation.slug && <div className="alert alert-danger">{validation.slug[0]}</div>}
              </div>
              <div className="form-check form-switch">
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  *Required
                </label>
                <input className="form-check-input" onChange={handleToggle} value={required} checked={required} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                {validation.required && <div className="alert alert-danger">{validation.required[0]}</div>}
              </div>
              <div className="form-floating mb-5 text-end">
                <button type="submit" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-save"></i>
                  </span>
                  Save
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <Navigate to="/form-element" replace={true} />
      )}
      <AppFooter />
    </>
  );
}

/* Format Number */
function _formatNumber(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Formater {
  static formatNumber(value) {
    if (isNaN(value) || value === null) return "-";
    else return _formatNumber(value);
  }
}

export default Formater;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import AppSpinner from "../Components/AppSpinner";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { API, Storage } from "../Config/Config";
import empty from "../Components/empty.jpg";
import Formater from "../Components/AppDateFormatter";
import DataTableVendorAddress from "./DataTableVendorAddress";
import DataTableVendorNotes from "./DataTableVendorNotes";
import DataTableVendorQRCode from "./DataTableVendorQRCode";

export default function DetailVendor() {
  // CONFIG API AND HEADER //
  const api = API;
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const { id } = useParams();

  const navigate = useNavigate();

  //END CONFIG API AND HEADER //

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Vendors", slug: "/vendor", status: "" },
    {
      id: "3",
      title: "Detail Vendors",
      slug: "/detail/vendor",
      status: "active",
    },
  ];

  const [isLoaded, setIsLoaded] = useState(false);
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [vendorPIC, setVendorPIC] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");

  const fetchDataVendor = useCallback(() => {
    axios
      .get(api + `/vendor/data/${id}`, header)
      .then(function (response) {
        let vendors = response.data.data;
        // console.log(vendors);
        setIsLoaded(true);
        setImage(vendors.image);
        setName(vendors.name);
        setAddressName(vendors.address_name);
        setAddressStreet(vendors.address_street);
        setCity(vendors.city.name);
        setState(vendors.state.name);
        setCountry(vendors.country.name);
        setAddressZipCode(vendors.address_zip_code);
        setEmail(vendors.email);
        setPhone(vendors.phone);
        setVendorPIC(vendors.vendor_pic);
        setCreatedAt(vendors.created_at);
        setUpdatedAt(vendors.updated_at);
        setCreatedBy(
          `${vendors.created_by.first_name} ${vendors.created_by.last_name}`
        );
        setUpdatedBy(
          `${vendors.updated_by.first_name} ${vendors.updated_by.last_name}`
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [api, header, id]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(api + `/vendor/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              navigate("/vendor");
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header, navigate]
  );

  useEffect(() => {
    fetchDataVendor();
  }, [fetchDataVendor]);

  return (
    <>
      <AppHeader />
      <div className="d-flex flex-row border-bottom p-2 px-3">
        <div className="flex-grow-1 mt-1 small">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
        <div>
          <a className="btn btn-sm btn-primary" href="/vendor" role="button">
            <i className="bi bi-arrow-left me-2"></i>
            <span className="">Back</span>
          </a>
        </div>
      </div>
      {isLoaded === false ? (
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <AppSpinner title="Loading data detail vendor ..." />
        </div>
      ) : (
        <>
          <div className="container-fluid pt-3 m-0 mb-5 px-1">
            <div className="row row-cols-1 border-bottom">
              <div className="row row-cols-1 bg-primary text-bg-primary">
                <div className="col p-2 ps-4">
                  <div className="fs-2">Detail Vendor</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row row-cols-1">
                  <div className="col p-3 text-center">
                    {image ? (
                      <img
                        alt={`${image}`}
                        className="img-fluid img-thumbnail"
                        src={Storage + `/vendor/image/${id}/${image}`}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          maxHeight: "120px",
                          borderRadius: "50px",
                        }}
                      />
                    ) : (
                      <img
                        alt="default"
                        className="img-fluid img-thumbnail"
                        src={empty}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          maxHeight: "120px",
                          maxWidth: "120px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="row justify-content-start">
                  <div className="col px-4 py-2 text-center">
                    <div className="table-responsive mt-3">
                      <table className="table table-sm align-middle text-start">
                        <tbody>
                          <tr>
                            <td>Vendor Name</td>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>{phone}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{email}</td>
                          </tr>
                          <tr>
                            <td>Vendor PIC</td>
                            <td>{vendorPIC}</td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>
                              {addressName}, {addressStreet}, {city}, {state},{" "}
                              {country}, {addressZipCode}
                            </td>
                          </tr>
                          <tr className="small fw-light text-center">
                            <td colSpan={2}>
                              Created At{" "}
                              {createdAt
                                ? Formater.beautyDateTime(createdAt)
                                : "-"}{" "}
                              {" | By "} {createdBy ? createdBy : "-"}
                            </td>
                          </tr>
                          <tr className="small fw-light text-center">
                            <td colSpan={2}>
                              {updatedAt
                                ? Formater.beautyDateTime(updatedAt)
                                : "-"}{" "}
                              {" | By"} {updatedBy ? updatedBy : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row justify-content-center mb-3">
                      <div className="col">
                        <Link
                          to={`/vendor/edit/${id}`}
                          className="btn btn-primary px-4"
                        >
                          <i className="bi bi-pencil-square"></i> Edit
                        </Link>
                      </div>
                      <div className="col">
                        <button
                          className="btn btn-danger px-4"
                          onClick={() => handleDelete(id)}
                        >
                          <i className="bi bi-trash-fill"></i> Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="col border-secondary border-start border-opacity-25 p-4">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        Address
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                      >
                        Notes
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="contact-tab-pane"
                        aria-selected="false"
                      >
                        QR Code
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabIndex="0"
                    >
                      <DataTableVendorAddress />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabIndex="0"
                    >
                      <DataTableVendorNotes />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="contact-tab-pane"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                      tabIndex="0"
                    >
                      <DataTableVendorQRCode />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="disabled-tab-pane"
                      role="tabpanel"
                      aria-labelledby="disabled-tab"
                      tabIndex="0"
                    >
                      ...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <AppFooter />
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "./../Components/AppFooter";

import Swal from "sweetalert2";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API } from ".././Config/Config";

export default function CreateVendor() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;
  const navigate = useNavigate();

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Vendors", slug: "/vendor", status: "" },
    {
      id: "3",
      title: "Create Vendor",
      slug: "/vendor/create",
      status: "active",
    },
  ];

  const [listCountry, setListCountry] = useState([]);
  const [listState, setListState] = useState([]);
  const [listCity, setListCity] = useState([]);

  const fetchCountry = useCallback(async () => {
    await axios
      .get(api + "/vendor/countries", header)
      .then(function (response) {
        setListCountry(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const changeState = useCallback(async (items) => {
    setAddressCountry(items.target.value);
    await axios
      .get(api + `/vendor/states?country=${items.target.value}`, header)
      .then(function (response) {
        setListState(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const changeCity = useCallback(async (items) => {
    setAddressState(items.target.value);
    await axios
      .get(api + `/vendor/cities?state=${items.target.value}`, header)
      .then(function (response) {
        setListCity(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const [image, setImage] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const [name, setName] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressZipCode, setAddressZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pic, setPIC] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    if (image.length < 1) return;
    const newImageUrls = [];
    image.forEach((images) => newImageUrls.push(URL.createObjectURL(images)));
    setPreviewImage(newImageUrls);
  }, [image]);

  function imageOnChange(e) {
    setImage([...e.target.files]);
  }

  const saveVendor = async (e) => {
    e.preventDefault();
    setValidation([]);

    const formData = new FormData();

    formData.append("image", image[0]);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("vendor_pic", pic);
    formData.append("address_name", addressName);
    formData.append("address_street", addressStreet);
    formData.append("address_city", addressCity);
    formData.append("address_state", addressState);
    formData.append("address_country", addressCountry);
    formData.append("address_zip_code", addressZipCode);

    // console.log(...formData);

    Swal.fire({
      title: "Saving Vendor data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/vendor/create`, formData, header)
      .then(function (response) {
        if (response.data.status === "success") {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          navigate("/vendor");
        } else if (response.data.status === "error") {
          // console.log(response.data);
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  return (
    <>
      <AppHeader />
      <div className="d-flex flex-row border-bottom p-2 px-3">
        <div className="flex-grow-1 mt-1 small">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
        <div>
          <a className="btn btn-sm btn-primary" href="/vendor" role="button">
            <i className="bi bi-arrow-left me-2"></i>
            <span className="">Back</span>
          </a>
        </div>
      </div>
      <div className="container pt-5 mb-5 px-1">
        <form onSubmit={saveVendor}>
          <div className="row g-4">
            {previewImage.map((imageSrc, id) => (
              <img
                className="img-fluid img-thumbnail"
                alt="profile"
                key={id}
                src={imageSrc}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxHeight: "10rem",
                  maxWidth: "10rem",
                  borderRadius: "20%",
                  marginBottom: "1rem",
                }}
              />
            ))}
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">
                  Image
                </label>
                <input className={`form-control ${validation.image ? "is-invalid" : ""}`} type="file" id="formFile" accept="image/*" onChange={imageOnChange} />
                {validation.image ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.image[0]}</div> : ""}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input type="text" className={`form-control ${validation.name ? "is-invalid" : ""}`} id="floatingInputGrid" placeholder="name@example.com" onChange={(e) => setName(e.target.value)} />
                <label htmlFor="floatingInputGrid">Name</label>
                {validation.name ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.name[0]}</div> : ""}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input type="email" className={`form-control ${validation.email ? "is-invalid" : ""}`} id="floatingInputGrid" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor="floatingInputGrid">Email Address</label>
                {validation.email ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.email[0]}</div> : ""}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input type="text" className={`form-control ${validation.phone ? "is-invalid" : ""}`} id="floatingInputGrid" placeholder="name@example.com" onChange={(e) => setPhone(e.target.value)} />
                <label htmlFor="floatingInputGrid">Phone Number</label>
                {validation.phone ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.phone[0]}</div> : ""}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input type="text" className={`form-control ${validation.vendor_pic ? "is-invalid" : ""}`} id="floatingInputGrid" placeholder="name@example.com" onChange={(e) => setPIC(e.target.value)} />
                <label htmlFor="floatingInputGrid">Vendor PIC</label>
                {validation.vendor_pic ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.vendor_pic[0]}</div> : ""}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input type="text" className={`form-control ${validation.address_name ? "is-invalid" : ""}`} id="floatingInputGrid" placeholder="name@example.com" onChange={(e) => setAddressName(e.target.value)} />
                <label htmlFor="floatingInputGrid">Address Name</label>
                {validation.address_name ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_name[0]}</div> : ""}
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating">
                <input type="text" className={`form-control ${validation.address_street ? "is-invalid" : ""}`} id="floatingInputGrid" placeholder="name@example.com" onChange={(e) => setAddressStreet(e.target.value)} />
                <label htmlFor="floatingInputGrid">Address Street</label>
                {validation.address_street ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_street[0]}</div> : ""}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-floating">
                <select className={`form-select ${validation.address_country ? "is-invalid" : ""}`} id="floatingSelectGrid" onChange={changeState}>
                  <option selected>Choose Country ...</option>
                  {listCountry.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelectGrid">Country</label>
                {validation.address_country ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_country[0]}</div> : ""}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-floating">
                <select className={`form-select ${validation.address_state ? "is-invalid" : ""}`} id="floatingSelectGrid" onChange={changeCity}>
                  <option selected>Choose State ...</option>
                  {listState.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelectGrid">State</label>
                {validation.address_state ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_state[0]}</div> : ""}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-floating">
                <select className={`form-select ${validation.address_city ? "is-invalid" : ""}`} id="floatingSelectGrid" onChange={(e) => setAddressCity(e.target.value)}>
                  <option selected>Choose City ...</option>
                  {listCity.map((data, id) => (
                    <option key={id} value={data.data.id}>
                      {data.data.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelectGrid">City</label>
                {validation.address_city ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_city[0]}</div> : ""}
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input type="text" className={`form-control ${validation.address_zip_code ? "is-invalid" : ""}`} id="floatingInputGrid" placeholder="name@example.com" onChange={(e) => setAddressZipCode(e.target.value)} />
                <label htmlFor="floatingInputGrid">ZIP Code</label>
                {validation.address_zip_code ? <div className="alert alert-danger small p-1 px-2 mt-1">{validation.address_zip_code[0]}</div> : ""}
              </div>
            </div>
            <div className="col">
              <button type="submit" className="btn btn-primary px-4 float-end">
                <i className="bi bi-save"></i> &nbsp; Save
              </button>
            </div>
          </div>
        </form>
      </div>
      <AppFooter />
    </>
  );
}

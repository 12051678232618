import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import AppSpinner from "../Components/AppSpinner";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { API } from ".././Config/Config";

import Table from "./TableUser";

export default function DataTableUser() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [success, setSuccess] = useState({ value: false });
  const [user, setUser] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchUser = useCallback(async () => {
    await axios.get(api + "/app/all_user", header).then((response) => {
      setUser(response.data.data);
      setIsLoaded(true);
    });
  }, [api, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(api + `/users/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchUser();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header, fetchUser]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "data.id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  {/* <Link to="#" className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link> */}
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },

          {
            Header: "First Name",
            accessor: "data.first_name",
          },
          {
            Header: "Last Name",
            accessor: "data.last_name",
          },
          {
            Header: "Email",
            accessor: "data.email",
          },
          {
            Header: "Roles",
            accessor: "data[roles].name",
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchUser();
  }, [token, success, fetchUser]);

  if (!isLoaded) {
    return <AppSpinner title="Loading data User's . . ." />;
  }
  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={user} />
    </div>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Navigate, Link } from "react-router-dom";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import Swal from "sweetalert2";
import { API } from "./../Config/Config";

export default function CreateDepartment() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Department", slug: "/department", status: "" },
    { id: "3", title: "Create Department", slug: "/department/create", status: "active" },
  ];

  const [success, setSuccess] = useState(true);

  const [name, setName] = useState("");
  const [validation, setValidation] = useState([]);

  const saveDepartment = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/department/create`, formData, header)
      .then(function (response) {
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });
          setSuccess(false);
        } else if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
        });
      });
  };

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <AppHeader />

      {success ? (
        <div className="containter-fluid p-0 m-0 mb-5">
          <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
            <div className="flex-grow-1 py-2">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
            <div className="py-1">
              <Link to="/department" className="btn btn-sm btn-primary px-2">
                <span className="me-2">
                  <i className="bi bi-arrow-left-circle"></i>
                </span>
                Back
              </Link>
            </div>
          </div>
          <div className="px-4 pb-4">
            <form onSubmit={saveDepartment}>
              <div className="alert alert-secondary p-2" role="alert">
                <p className="small p-0 m-0">
                  <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
                  Sign <span className="text-danger">*</span> is a mandatory data
                </p>
              </div>
              <div className="form-floating mb-3">
                <input type="text" className={`form-control ${validation.name ? "is-invalid" : ""}`} value={name} onChange={(e) => setName(e.target.value)} id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">Name</label>
                {validation.name && (
                  <div className={`invalid-feedback ${validation.name ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.name[0]}
                  </div>
                )}
              </div>
              <div className="form-floating mb-3 text-end">
                <button type="submit" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-save"></i>
                  </span>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Navigate to="/department" replace={true} />
      )}
      <AppFooter />
    </>
  );
}

import React, { useMemo, useState, useEffect, useCallback } from "react";
import axios from "axios";
import AppSpinner from "../../Components/AppSpinner";
import { Link } from "react-router-dom";
import Table from "./TableTrack";
import { API } from "../../Config/Config";

export default function DataTableTrack() {
  const api = API;

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const roles = useMemo(() => {
    return localStorage.getItem("roles");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [success, setSuccess] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [listTransaction, setListTransaction] = useState([]);

  const fetchTransaction = useCallback(async () => {
    await axios.get(api + "/transaction-legal/tracking/list", header).then((response) => {
      setListTransaction(response.data.data);
      // console.log(response.data.data);
      setIsLoaded(true);
    });
  }, [api, header]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/track/update/${value}`} className="btn btn-sm btn-primary m-1">
                    <i className="bi bi-stack"></i>
                  </Link>

                  <Link to={`/track/edit/${value}`} className={`btn btn-sm btn-secondary m-1 ${roles === "4" ? "d-none" : ""}`}>
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  {/* <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link> */}
                </>
              );
            },
          },
          {
            Header: "Receipt Number",
            accessor: "receipt_number",
          },
          {
            Header: "Date",
            accessor: "date",
          },
          // {
          //   Header: "Customer",
          //   accessor: (d) => `${d.customer["first_name"]} ${d.customer["last_name"]}`,
          // },
          {
            Header: "Type",
            accessor: "type",
          },
          {
            Header: "Address",
            accessor: "address",
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({ cell: { value } }) => {
              return value === "new_request" ? (
                <>
                  <p>New Request</p>
                </>
              ) : value === "on_progress" ? (
                <>
                  <p>On Progress</p>
                </>
              ) : value === "sent_by_maxxs" ? (
                <>
                  <p>Sent By Maxx's</p>
                </>
              ) : value === "received_by_customer" ? (
                <>
                  <p>Received By Customer</p>
                </>
              ) : value === "completed_by_system" ? (
                <>
                  <p>Completed By System</p>
                </>
              ) : value === "completed/close" ? (
                <>
                  <p>Transaction Complete</p>
                </>
              ) : value === "cancel" ? (
                <>
                  <p>Cancel</p>
                </>
              ) : null;
            },
          },
          {
            Header: "Description",
            accessor: "description",
          },
        ],
      },
    ],
    [roles]
  );

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }

    fetchTransaction();
  }, [token, fetchTransaction]);

  if (!isLoaded) {
    return <AppSpinner title="Loading Data Transaction . . ." />;
  }

  return (
    <>
      {listTransaction.length < 1 ? (
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-5 fw-bold text-body-emphasis">Data not found</h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">Tracking data not found, to start filling in new data, please click the add new button at the top right of your screen.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid table-responsive">
          <Table columns={columns} data={listTransaction} />
        </div>
      )}
    </>
  );
}

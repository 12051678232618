import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import { API } from ".././Config/Config";

export default function AddUrlAccess() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //get api from local storage
  const apiUrl = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Roles", slug: "/roles", status: "" },
    { id: "3", title: "URL Access", slug: "/roles/url-access", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const [url, setUrl] = useState([]);
  const [urlAccess, setUrlAccess] = useState([]);
  const [name, setName] = useState("");
  const [formData, setFormData] = useState("");
  const [listUrl, setListUrl] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { id } = useParams();

  //get data url
  const fetchUrl = useCallback(() => {
    axios.get(apiUrl + "/urls/list", header).then((response) => {
      setListUrl(response.data.data);
      // console.log(response.data.data);
      setIsLoaded(true);
    });
  }, [apiUrl, header]);

  useEffect(() => {
    axios
      .get(apiUrl + `/roles/data/${id}`, header)
      .then(function (response) {
        let roles = response.data.data.data;
        setName(roles.name);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: error,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [apiUrl, header, id]);

  useEffect(() => {
    axios
      .get(apiUrl + `/url-permissions/list/${id}`, header)
      .then(function (response) {
        const data = response.data.data;
        // console.log(data.url);
        setUrlAccess(data.url);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: error,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [apiUrl, header, id]);

  useEffect(() => {
    setFormData(new FormData());
  }, []);

  const handleToggle = (c) => () => {
    // return the first index or -1
    const clickedUrl = url.indexOf(c);
    const all = [...url];

    if (clickedUrl === -1) {
      all.push(c);
    } else {
      all.splice(clickedUrl, 1);
    }

    var arr = all;
    // console.log(arr);
    for (var i = 0; i < arr.length; i++) {
      formData.append("url[]", arr[i]);
    }
  };

  const addUrlAccess = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await axios.post(apiUrl + `/url-permissions/create/${id}`, formData, header);
    if (response.data.status === "error") {
      // setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        text: response.data.message,
        timer: 2000,
      });
    } else if (response.data.status === "success") {
      // console.log(response.data.data);
      Swal.fire({
        icon: "success",
        text: response.data.message,
        timer: 2000,
      });
      setSuccess(false);
    }
  };

  //hook useEffect
  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      setSuccess(false);
    }

    fetchUrl();
  }, [token, fetchUrl]);

  if (!isLoaded) {
    return <AppSpinner title="Loading data URL Access . . ." />;
    // return (
    //   <>
    //     <div className="container-fluid p-0 m-0 mb-5">
    //       <AppHeader />
    //       <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
    //         <div className="flex-grow-1 py-2">
    //           <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
    //         </div>
    //         <div className="py-1">
    //           <a className="btn btn-primary" href="/url" role="button">
    //             <span className="me-2">
    //               <i className="bi bi-arrow-left"></i>
    //             </span>
    //             Back
    //           </a>
    //           <Link to="/url" className="btn btn-sm btn-primary px-2">
    //             <span className="me-2">
    //               <i className="bi bi-arrow-left-circle"></i>
    //             </span>
    //             Back
    //           </Link>
    //         </div>
    //       </div>
    //       <div className="container-fluid">
    //         <AppSpinner title="Loading data url access . . ." />
    //       </div>
    //       <AppFooter />
    //     </div>
    //   </>
    // );
  }
  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        {success ? (
          <>
            <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
              <div className="flex-grow-1 py-2">
                <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
              </div>
              <div className="py-1">
                <Link to="/roles" className="btn btn-sm btn-primary px-2">
                  <span className="me-2">
                    <i className="bi bi-arrow-left-circle"></i>
                  </span>
                  Back
                </Link>
              </div>
            </div>
            <div className="px-4 pb-4">
              <div className="alert alert-secondary p-2" role="alert">
                <p className="small p-0 m-0">
                  <i className="bi bi-info-circle-fill pe-2 p-0 m-0"></i>
                  Setup URL access for role &raquo; <strong>{name}</strong>
                </p>
              </div>
              <form onSubmit={addUrlAccess}>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>URL Module</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {urlAccess.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td className="col-md-6">
                              <label className="form-check-label" htmlFor="flexCheckDefault">
                                {data[0].name}
                              </label>
                            </td>
                            <td className="col-md-6">
                              <div className="form-check">
                                <input className="form-check-input" defaultChecked={data[0].role_id === null ? false : true} type="checkbox" value={data[0].id} onChange={handleToggle(data[0].id)} />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="form-floating mb-3 text-end">
                  <button type="submit" className="btn btn-sm btn-primary px-2">
                    <span className="me-2">
                      <i className="bi bi-save"></i>
                    </span>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Navigate to="/roles" replace={true} />
        )}
        <AppFooter />
      </div>
    </>
  );
}

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../Config/Config";
import AppSpinner from "../Components/AppSpinner";

export default function ModalVendorQRCode(props) {
  // -- General Setting -- //
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }, [token]);

  const [validation, setValidation] = useState([]);

  const [expDate, setExpDate] = useState("");
  // -- End General Setting -- //

  // -- Save Data Vendor QR Code -- //
  const saveQRCode = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("vendor_id", props.vendorID);
    formData.append("expired_date", expDate);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/vendor/qr-code/create`, formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            text: response.data.message,
            timer: 2000,
          });
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });

          setValidation([]);
          setExpDate("");

          props.close();
        }
      })
      .catch(function (error) {
        // console.log(error)
      });
  };
  // -- End Save Data Vendor QR Code -- //

  return (
    <>
      <Modal show={props.show} centered size="lg">
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="px-3 py-1" onSubmit={saveQRCode}>
            <div className="form-floating mb-3">
              <input
                type="date"
                id="floatingDate"
                className={`form-control ${
                  validation.expired_date ? "is-invalid" : ""
                }`}
                onChange={(e) => setExpDate(e.target.value)}
              />
              <label htmlFor="floatingDate">Expired Date</label>
              {validation.expired_date && (
                <div className="alert alert-danger small p-1 px-2 mt-1">
                  {validation.expired_date[0]}
                </div>
              )}
            </div>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-sm btn-secondary px-2"
                onClick={() => {
                  setValidation([]);
                  props.close();
                }}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-sm btn-primary px-3">
                Save
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

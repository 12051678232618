import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import AppBreadcrumb from "../Components/AppBreadcrumb";
import DataTableTransactionLegal from "./DataTableTransactionLegal";
import { Link } from "react-router-dom";
import { API } from ".././Config/Config";

export default function IndexTransactionLegal() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Transaction Legal", slug: "/transaction-legal", status: "active" },
  ];

  const [success, setSuccess] = useState(true);
  const [listModule, setListModule] = useState([]);

  const fetchModules = useCallback(async () => {
    await axios.get(api + `/transaction-legal/module-legal`, header).then((response) => {
      setListModule(response.data.data);
    });
  }, [api, header]);

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchModules();
  }, [token, fetchModules]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
          <div className="flex-grow-1 py-2">
            <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
          </div>
          <div className="py-1">
            <div className="dropdown">
              <button className="btn btn-sm btn-primary px-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-plus-circle"></i> Add New
              </button>
              <ul className="dropdown-menu">
                {listModule.map((data, id) => (
                  <li key={id}>
                    <Link className="dropdown-item" to={`/transaction-legal/${data.data.id}/create`}>
                      {data.data.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="container-fluid p-0 m-0 mb-5">
          <DataTableTransactionLegal />
        </div>
        <AppFooter />
      </div>
    </>
  );
}

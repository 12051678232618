import axios from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import AppSpinner from "../../Components/AppSpinner";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { API, Storage } from "../../Config/Config";
import Table from "./TableNewsUpdates";

export default function DataTableNews() {
  const api = API;

  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const [success, setSuccess] = useState(true);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [news, setNews] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchNews = useCallback(async () => {
    await axios
      .get(api + `/news/list`, header)
      .then(function(response) {
        setNews(response.data.data);
        setIsLoaded(true);
      })
      .catch(function(error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          timer: 2000,
        });
      });
  }, [api, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(api + `/news/delete/${id}`, header)
            .then(function(response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchNews();
            })
            .catch(function(error) {
              Swal.fire({
                icon: "error",
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header, fetchNews]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        // First group columns
        columns: [
          {
            Header: "Action",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/news/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Image",
            Cell: ({ cell: { row } }) => {
              return (
                <>
                  <img
                    alt="Pics"
                    style={{
                      maxWidth: "20rem",
                      maxHeight: "20rem",
                      //   borderRadius: "50%",
                    }}
                    src={Storage + `/news_updates/${row.original.id}/${row.original.image}`}
                  />
                </>
              );
            },
          },
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Show",
            accessor: "show",
            Cell: ({ cell: { value } }) => {
              return <div>{value === 1 ? <p>Active</p> : <p>Not Active</p>}</div>;
            },
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchNews();
  }, [fetchNews, token]);

  if (!isLoaded) {
    return (
      <>
        <AppSpinner title="Loading data News . . ." />
      </>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={news} />
    </div>
  );
}

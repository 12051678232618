import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import AppSpinner from "../../Components/AppSpinner";
import Swal from "sweetalert2";
import { numberFormat } from "./NumberFormat";
import CurrencyInput from "./../../Components/AppCurrencyInput";
import PercentageInput from "./../../Components/AppPercentageInput";
import { API, Storage, AUTH_MIDTRANS } from "../../Config/Config";
import { Wizard, Step } from "../../Components/AppWizard";
import empty from "../../Components/empty.jpg";
import { makeid } from "../../Components/AppGenerateString";
import { Modal } from "react-bootstrap";

export default function DetailTransVisa() {
  //token
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  //api
  const api = API;

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "Transaction Legal", slug: "/transaction-legal", status: "" },
    { id: "3", title: "Detail Transaction", slug: "/transaction-legal/detail", status: "active" },
  ];

  const { visa } = useParams();
  const [success, setSuccess] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [master, setMaster] = useState([]);
  const [detail, setDetail] = useState([]);
  const [log, setLog] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const [dataDetails, setDataDetails] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [formStatus, setFormStatus] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    axios
      .get(api + `/transaction-legal/detail/${visa}`, header)
      .then(function (response) {
        let master = response.data.data.master;
        let detail = response.data.data.detail;
        let log = response.data.data.log;

        setMaster(master.data);
        setDetail(detail);
        setLog(log.data);
        setIsLoaded(true);
        console.log(master.data);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An Error Occured!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, [api, header, visa]);

  const generateMOU = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: `MOU will be created for this transaction and cannot be changed.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Generating MOU ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .get(api + `/transaction-legal/visa/generate-mou/${id}`, header)
            .then(function (response) {
              window.location.reload(true);
              Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true,
              });
            });
        }
      });
    },
    [api, header]
  );

  const approveMOU = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: `The MOU will be approved and cannot be changed, make sure the customer has accepted it.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Approving MOU ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .get(api + `/transaction-legal/visa/approve-mou/${id}`, header)
            .then(function (response) {
              if (response.data.status === "success") {
                Swal.fire({
                  icon: "success",
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                window.location.reload(true);
              } else if (response.data.status === "error") {
                Swal.fire({
                  icon: "error",
                  title: response.data.message,
                  showConfirmButton: true,
                });
              }
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true,
              });
            });
        }
      });
    },
    [api, header]
  );

  const previewMOU = useCallback(async () => {
    await axios
      .get(api + `/transaction-legal/visa/preview-mou/${visa}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        // console.log(response);
        const file = window.URL.createObjectURL(response.data);
        const iframe = document.querySelector("iframe");
        if (iframe?.src) window.open((iframe.src = file));
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [api, token, visa]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(api + `/transaction-legal/visa/delete/${id}`, header)
            .then(function (response) {
              window.location.reload(true);
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header]
  );

  /**
   * Midtrans Configuration
   */
  useEffect(() => {
    //change this to the script source you want to load, for example this is snap.js sandbox env
    const midtransScriptUrl = "https://app.sandbox.midtrans.com/snap/snap.js";
    //change this according to your client-key
    const myMidtransClientKey = "SB-Mid-client-BIm58MNg9tCodXLZ";

    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    // optional if you want to set script attribute
    // for example snap.js have data-client-key attribute
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);

    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  const midtrans = useCallback(async () => {
    Swal.fire({
      title: "Generating Payment ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await axios
      .get(api + `/transaction-legal/visa/payment/${visa}`, header)
      .then((response) => {
        Swal.close();
        let data = response.data.data.snapToken;
        console.log(response);
        window.snap.pay(data, {
          onSuccess: function (result) {
            /* You may add your own implementation here */
            // console.log(result);

            const formData = new FormData();

            formData.append("status", "success");

            axios
              .post(api + `/transaction-legal/visa/handlePayment/${visa}`, formData, header)
              .then((response) => {
                Swal.fire({
                  icon: "success",
                  text: response.data.message,
                  timer: 2000,
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  text: error.response.data.message,
                  timer: 2000,
                });
              });
            alert(response.data.message);
          },
          onPending: function (result) {
            /* You may add your own implementation here */
            alert("wating your payment!");
            console.log(result);
          },
          onError: function (result) {
            /* You may add your own implementation here */
            alert("payment failed!");
            console.log(result);
          },
          onClose: function () {
            /* You may add your own implementation here */
            alert("you closed the popup without finishing the payment");
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: "error",
          title: error.message,
          // html: "Got an Error : <br /><br />" + "<strong>" + error.response.data.message + "</strong> <br /><br/> Please contact your administrator.",
          html: `Got an Error : <br />><br /> <strong>${error.response.data.message}</strong><br /><br /> Please contact your administrator`,
        });
      });
  }, [api, header, visa]);

  /**
   * End Midtrans Cofiguration
   */

  const statusDocumentProcess = useCallback(
    (id) => {
      Swal.fire({
        title: "Document on Process",
        html: "Are you sure want to change this transaction status to <strong>Document on Process</strong> ? <br /> You won't be able to revert this.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Updating Status ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .get(api + `/transaction-legal/visa/statusDocumentProcess/${id}`, header)
            .then(function (response) {
              window.location.reload(true);
              Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "An Error Occured!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    [api, header]
  );

  const [sendDocument, setSendDocument] = useState([]);
  const [statusDetail, setStatusDetail] = useState([]);

  useEffect(() => {
    setSendDocument(new FormData());
  }, []);

  const handleCheck = (c) => () => {
    //return the first index or -1
    const clickedStatus = statusDetail.indexOf(c);
    const all = [...statusDetail];

    if (clickedStatus === -1) {
      all.push(c);
    } else {
      all.splice(clickedStatus, 1);
    }

    var arr = all;

    // console.log(arr);

    for (var i = 0; i < arr.length; i++) {
      sendDocument.append("status[]", arr[i]);
    }
  };

  const statusSentDocument = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Document Hand Over",
      html: "Are you sure want to change this transaction status to <strong>Document Hand Over</strong> ? <br /> You won't be able to revert this.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Updating Status ...",
          html: "Please wait ...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        axios
          .post(api + `/transaction-legal/visa/statusDocumentHandOver/${visa}`, sendDocument, header)
          .then(function (response) {
            // console.log(response);
            if (response.data.status === "success") {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.reload(true);
            } else if (response.data.status === "error") {
              setValidation(response.data.data);
              Swal.fire({
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              icon: "error",
              text: error.response.data.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          });
      }
    });
  };

  const handleShow = useCallback(
    (id) => {
      axios.get(api + `/transaction-legal/visa/listDetails/${visa}/${id}`, header).then((response) => {
        let list = response.data.data.log;
        let detail = response.data.data.detail.data;
        setDataShow(list);
        setDataDetails(detail);
        setDataLoading(true);
      });
    },
    [api, header, visa]
  );

  const updateLogDetails = async (id) => {
    const formData = new FormData();

    formData.append("status", formStatus);
    formData.append("description", formDescription);

    Swal.fire({
      title: "Updating Status ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await axios.post(api + `/transaction-legal/visa/updateLogDetails/${visa}/${id}`, formData, header);
    if (response.data.status === "error") {
      setValidation(response.data.data);
      Swal.fire({
        icon: "error",
        title: response.data.message,
        timer: 2500,
      });
    } else if (response.data.status === "success") {
      Swal.fire({
        icon: "success",
        title: response.data.message,
        timer: 2000,
      });
      window.location.reload(false);
    }
  };

  const submitData = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Submit Data ?",
      html: "Are you sure want to Submit this Request ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Submitting Data ...",
          html: "Please wait ...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        axios
          .get(api + `/transaction-legal/visa/${visa}/submit`, header)
          .then(function (response) {
            // console.log(response);
            if (response.data.status === "success") {
              window.location.reload(true);
              Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (response.data.status === "error") {
              // setValidation(response.data.data);
              Swal.fire({
                icon: "error",
                title: response.data.message,
                // showConfirmButton: false,
                timer: 5000,
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              icon: "error",
              text: error.response.data.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          });
      }
    });
  };

  const handleClose = useCallback(() => {
    setDataShow(null);
    setDataDetails(null);
    setDataLoading(false);
  }, []);

  const [price, setPrice] = useState("");
  const [nominal, setNominal] = useState("");
  const [percent, setPercent] = useState("");

  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const handlePercentage = (event) => {
    let data = event.target.value;
    setNominal(data);
    let a = data.replace("IDR", "");
    let angka = a.replaceAll(",", "");
    let qty = detail.length;
    let satuan = master.destination["price"];
    let total = qty * satuan;
    let result = (angka / total) * 1;
    setPercent(result * 100);
  };

  const handleNominal = (event) => {
    let data = event.target.value;
    let percentage = data.replace("%", "");
    setPercent(percentage);
    let qty = detail.length;
    let satuan = master.destination["price"];
    let total = qty * satuan;
    let result = total * (percentage / 100);
    setNominal("IDR " + addCommas(result));
  };

  const handlePaymentOne = useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData();

      let data = price.replace("IDR", "");
      let angka = data.replaceAll(",", "");

      formData.append("price", angka);

      Swal.fire({
        title: "Saving Data ...",
        html: "Please wait ...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // try {
      //   const item_details = [];

      //   detail.forEach((value) => {
      //     item_details.push({
      //       id: "Document " + value.data.id,
      //       name: value.data.full_name + " Document",
      //       price: master["destination"].price,
      //       quantity: 1,
      //     });
      //   });

      //   let res = await fetch("https://api.sandbox.midtrans.com/v1/payment-links", {
      //     method: "POST",
      //     headers: {
      //       Accept: "application/json",
      //       "Content-type": "application/json",
      //       Authorization: AUTH_MIDTRANS,
      //     },
      //     body: JSON.stringify({
      //       transaction_details: {
      //         order_id: "VISA-" + makeid(10),
      //         gross_amount: Number(angka),
      //         payment_link_id: "VISA-" + makeid(5),
      //       },
      //       credit_card: {
      //         secure: true,
      //       },
      //       usage_limit: 1,
      //       enabled_payments: ["credit_card"],
      //       customer_details: {
      //         first_name: master["customer"].first_name,
      //         last_name: master["customer"].last_name,
      //         email: master["customer"].email,
      //         phone: master["customer"].mobile,
      //         notes: "Thank you for your order. Please follow the instructions to complete payment.",
      //       },
      //       item_details: item_details,
      //     }),
      //   });
      //   let resJson = await res.json();
      //   // console.log(res);
      //   // console.log(resJson.order_id);
      //   // console.log(resJson.payment_url);
      //   formData.append("order_id", resJson.order_id);
      //   formData.append("payment_link", resJson.payment_url);
      //   if (res.status === 200) {
      //     // alert("created snap midtrans");
      //     console.log(resJson);
      //   } else {
      //     alert(resJson.error_messages[0]);
      //     // console.log(resJson);
      //   }
      // } catch (err) {
      //   console.log(err);
      // }

      await axios
        .post(api + `/transaction-legal/visa/${visa}/termin_pay_one`, formData, header)
        .then((response) => {
          if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });
            window.location.reload(false);
          } else if (response.data.status === "error") {
            setValidation(response.data.data ? response.data.data : "");
            Swal.fire({
              icon: "error",
              text: response.data.message,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            html: `Got an Error : <br /><br /><strong>${error.response.data.message}</strong> <br /><br/> Please contact your administrator.`,
          });
        });
    },
    [api, header, price, visa]
  );

  const handlePaymentTwo = useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData();

      let data = nominal.replace("IDR", "");
      let angka = data.replaceAll(",", "");

      formData.append("nominal", angka);
      formData.append("percent", percent);

      // console.log(...formData);

      Swal.fire({
        title: "Saving Data ...",
        html: "Please wait ...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // try {
      //   const item_details = [];

      //   detail.forEach((value) => {
      //     item_details.push({
      //       id: "Document " + value.data.id,
      //       name: value.data.full_name + " Document",
      //       price: master["destination"].price,
      //       quantity: 1,
      //     });
      //   });

      //   let res = await fetch("https://api.sandbox.midtrans.com/v1/payment-links", {
      //     method: "POST",
      //     headers: {
      //       Accept: "application/json",
      //       "Content-type": "application/json",
      //       Authorization: AUTH_MIDTRANS,
      //     },
      //     body: JSON.stringify({
      //       transaction_details: {
      //         order_id: "VISA-" + makeid(10),
      //         gross_amount: Number(angka),
      //         payment_link_id: "VISA-" + makeid(5),
      //       },
      //       credit_card: {
      //         secure: true,
      //       },
      //       usage_limit: 1,
      //       enabled_payments: ["credit_card"],
      //       customer_details: {
      //         first_name: master["customer"].first_name,
      //         last_name: master["customer"].last_name,
      //         email: master["customer"].email,
      //         phone: master["customer"].mobile,
      //         notes: "Thank you for your order. Please follow the instructions to complete payment.",
      //       },
      //       // item_details: item_details,
      //     }),
      //   });
      //   let resJson = await res.json();
      //   // console.log(res);
      //   // console.log(resJson.order_id);
      //   // console.log(resJson.payment_url);
      //   formData.append("order_id", resJson.order_id);
      //   formData.append("payment_link", resJson.payment_url);
      //   if (res.status === 200) {
      //     // alert("created snap midtrans");
      //     console.log(resJson);
      //   } else {
      //     alert(resJson.error_messages[0]);
      //     // console.log(resJson);
      //   }
      // } catch (err) {
      //   console.log(err);
      // }

      await axios
        .post(api + `/transaction-legal/visa/${visa}/termin_pay_two`, formData, header)
        .then((response) => {
          if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 2000,
            });
            window.location.reload(false);
          } else if (response.data.status === "error") {
            setValidation(response.data.data ? response.data.data : "");
            Swal.fire({
              icon: "error",
              text: response.data.message,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            html: `Got an Error : <br /><br /><strong> ${error.response.data.message} </strong> <br /><br/> Please contact your administrator.`,
          });
        });
    },
    [api, header, nominal, percent, visa]
  );

  const generateInvoice = () => {
    Swal.fire({
      title: `${master.termin_payment[0].flag === "paid-off" ? "Generate Invoice Paid Off" : master.termin_payment[0].flag === "down-payment" ? "Generate Invoice Down Payment" : ""}`,
      // title: "Generate Invoice",
      html: "Are you sure want to Generate Invoice ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Generating Invoice ...",
          text: "Please wait ...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let urls = "";
        if (master.termin_payment[0].flag === "paid-off") {
          const formData = new FormData();
          try {
            const item_details = [];

            detail.forEach((value) => {
              item_details.push({
                id: "Document " + value.data.id,
                name: value.data.full_name + " Document",
                price: master["destination"].price,
                quantity: 1,
              });
            });

            let res = await fetch("https://api.sandbox.midtrans.com/v1/payment-links", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                Authorization: AUTH_MIDTRANS,
              },
              body: JSON.stringify({
                transaction_details: {
                  order_id: "VISA-" + makeid(10),
                  gross_amount: Number(master["destination"].price * detail.length),
                  payment_link_id: "VISA-" + makeid(5),
                },
                credit_card: {
                  secure: true,
                },
                usage_limit: 1,
                enabled_payments: ["credit_card"],
                customer_details: {
                  first_name: master["customer"].first_name,
                  last_name: master["customer"].last_name,
                  email: master["customer"].email,
                  phone: master["customer"].mobile,
                  notes: "Thank you for your order. Please follow the instructions to complete payment.",
                },
                item_details: item_details,
              }),
            });
            let resJson = await res.json();
            // console.log(res);
            // console.log(resJson.order_id);
            // console.log(resJson.payment_url);
            formData.append("number", resJson.order_id);
            formData.append("payment_url", resJson.payment_url);
            if (res.status === 200) {
              // alert("created snap midtrans");
              // console.log(resJson);
            } else {
              alert(resJson.error_messages[0]);
              // console.log(resJson);
            }
          } catch (err) {
            console.log(err);
          }

          formData.append("payment", "paid-off");

          urls = axios.post(api + `/transaction-legal/visa/generate-invoice/${visa}`, formData, header);
        } else if (master.termin_payment[0].flag === "down-payment") {
          const formData = new FormData();

          try {
            let res = await fetch("https://api.sandbox.midtrans.com/v1/payment-links", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                Authorization: AUTH_MIDTRANS,
              },
              body: JSON.stringify({
                transaction_details: {
                  order_id: "VISA-" + makeid(10),
                  gross_amount: Number(master["termin_payment"][0].term_value),
                  payment_link_id: "VISA-" + makeid(5),
                },
                credit_card: {
                  secure: true,
                },
                usage_limit: 1,
                enabled_payments: ["credit_card"],
                customer_details: {
                  first_name: master["customer"].first_name,
                  last_name: master["customer"].last_name,
                  email: master["customer"].email,
                  phone: master["customer"].mobile,
                  notes: "Thank you for your order. Please follow the instructions to complete payment.",
                },
              }),
            });
            let resJson = await res.json();
            // console.log(res);
            // console.log(resJson.order_id);
            // console.log(resJson.payment_url);
            formData.append("number", resJson.order_id);
            formData.append("payment_url", resJson.payment_url);
            if (res.status === 200) {
              // alert("created snap midtrans");
              console.log(resJson);
            } else {
              alert(resJson.error_messages[0]);
              // console.log(resJson);
            }
          } catch (err) {
            console.log(err);
          }

          formData.append("payment", "down-payment");

          urls = axios.post(api + `/transaction-legal/visa/generate-invoice/${visa}`, formData, header);
        }
        urls
          .then(function (response) {
            // console.log(response);
            if (response.data.status === "success") {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.reload(true);
            } else if (response.data.status === "error") {
              Swal.fire({
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              icon: "error",
              text: error.response.data.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          });
      }
    });
  };

  const generateInvoiceRepayment = async () => {
    Swal.fire({
      title: "Generate Invoice Repayment",
      // title: "Generate Invoice",
      html: "Are you sure want to Generate Invoice ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Generating Invoice ...",
          text: "Please wait ...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const formData = new FormData();
        try {
          let res = await fetch("https://api.sandbox.midtrans.com/v1/payment-links", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
              Authorization: AUTH_MIDTRANS,
            },
            body: JSON.stringify({
              transaction_details: {
                order_id: "VISA-" + makeid(10),
                gross_amount: Number(master["termin_payment"][1].term_value),
                payment_link_id: "VISA-" + makeid(5),
              },
              credit_card: {
                secure: true,
              },
              usage_limit: 1,
              enabled_payments: ["credit_card"],
              customer_details: {
                first_name: master["customer"].first_name,
                last_name: master["customer"].last_name,
                email: master["customer"].email,
                phone: master["customer"].mobile,
                notes: "Thank you for your order. Please follow the instructions to complete payment.",
              },
            }),
          });
          let resJson = await res.json();
          // console.log(res);
          // console.log(resJson.order_id);
          // console.log(resJson.payment_url);
          formData.append("number", resJson.order_id);
          formData.append("payment_url", resJson.payment_url);
          if (res.status === 200) {
            // alert("created snap midtrans");
            // console.log(resJson);
          } else {
            alert(resJson.error_messages[0]);
            // console.log(resJson);
          }
        } catch (err) {
          console.log(err);
        }

        formData.append("payment", "repayment");

        await axios
          .post(api + `/transaction-legal/visa/generate-invoice/${visa}`, formData, header)
          .then(function (response) {
            if (response.data.status === "success") {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.reload(true);
            } else if (response.data.status === "error") {
              Swal.fire({
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              icon: "error",
              text: error.response.data.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          });
      }
    });
  };

  const autoGenerateRepaymentInvoice = async () => {
    Swal.fire({
      title: "Generate Invoice Repayment",
      // title: "Generate Invoice",
      html: "Are you sure want to Generate Invoice ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Generating Invoice ...",
          text: "Please wait ...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const formData = new FormData();

        formData.append("payment", "auto-repayment");

        await axios
          .post(api + `/transaction-legal/visa/generate-invoice/${visa}`, formData, header)
          .then(function (response) {
            if (response.data.status === "success") {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.reload(true);
            } else if (response.data.status === "error") {
              Swal.fire({
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              icon: "error",
              text: error.response.data.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          });
      }
    });
  };

  const [payStatus, setPayStatus] = useState("");

  const payStatusValue = (event) => {
    setPayStatus(event.target.value);
  };

  const changeStatusPayment = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Updating Status ...",
      text: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const submit = new FormData();
    submit.append("pay_status", payStatus);

    await axios
      .post(api + `/transaction-legal/visa/change-status-payment/${visa}`, submit, header)
      .then(function (response) {
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload(true);
        } else if (response.data.status === "error") {
          Swal.fire({
            icon: "error",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: true,
          // timer: 1500,
        });
      });
  };

  // --------------------------- Update Document Section ------------------------- //

  const [detailID, setDetailID] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const [issuedDate, setIssuedDate] = useState("");
  const [issuedPlace, setIssuedPlace] = useState("");
  const [visaFileName, setVisaFileName] = useState([]);
  const [validationDocument, setValidationDocument] = useState([]);

  const [showModalDocument, setShowModalDocument] = useState(false);

  const handleCloseModalDocument = () => {
    setDetailID("");
    setShowModalDocument(false);
  };
  const handleShowModalDocument = (value) => {
    setDetailID(value);
    setShowModalDocument(true);
  };

  const updateDocument = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("expired_date", expiredDate);
    formData.append("issued_date", issuedDate);
    formData.append("issued_place", issuedPlace);
    formData.append("visa_file_name", visaFileName);

    Swal.fire({
      title: "Updating Document ...",
      text: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(api + `/transaction-legal/visa/updateDocument/${detailID}`, formData, header)
      .then(function (response) {
        if (response.data.status === "error") {
          Swal.fire({
            icon: "error",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setValidationDocument(response.data.data);
        } else if (response.data.status === "success") {
          handleCloseModalDocument();
          setExpiredDate("");
          setIssuedDate("");
          setIssuedPlace("");
          setVisaFileName("");

          Swal.fire({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // --------------------------- End Update Document Section ------------------------- //
  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
  }, [token]);

  if (!isLoaded) {
    return (
      <>
        <div className="container-fluid p-0 m-0 mb-5">
          <AppHeader />
          <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
            <div className="flex-grow-1 py-2">
              <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
            </div>
          </div>
          <AppSpinner title="Loading Detail Transaction . . ." />
        </div>
      </>
    );
  }

  return (
    <>
      <AppHeader />
      <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
        <div className="flex-grow-1 py-2">
          <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
        </div>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="card border-0">
            <div className="card-body">
              <div className="row justify-content-around">
                <div className="col-md-6">
                  <p className="text-muted">Customer</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">
                    <strong>
                      {master["customer"].first_name}&nbsp;{master["customer"].last_name}
                    </strong>
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Module</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{master["module"].name}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Transaction Number</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{master.trans_number}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Transaction Date</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{master.trans_date}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Visa Type</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{master["param_visa_type"].name}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Visa Purpose</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{master["param_visa_purpose"].name}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Destination</p>
                </div>
                <div className="col-md-6">
                  <p className="float-end">{master["destination"]["country"].name}</p>
                </div>
                <div className="col-md-6">
                  <p className="text-muted">Status</p>
                </div>
                <div className="col-md-6">
                  <span className="badge text-bg-primary float-end">{log.description}</span>
                </div>
                {master.termin_payment.length < 1 ? (
                  ""
                ) : (
                  <div className="col-12">
                    <div className="card text-bg-light mb-3" style={{ maxWidth: "20rem" }}>
                      <div className="card-header">Sales Price</div>
                      <div className="card-body">
                        {/* <p className="card-title mb-3">Total : {numberFormat(detail.length * master.destination.price)}</p> */}
                        <p className="card-title mb-3">Total : {master.termin_payment.length === 2 ? numberFormat(detail.length * master.destination.price) : numberFormat(master.termin_payment[0].term_value)}</p>
                        {master.termin_payment.map((item, index) => (
                          <p key={index} className="card-text">
                            {item.flag === "paid-off" ? "Paid Off" : item.flag === "down-payment" ? "Down Payment" : item.flag === "repayment" ? "Repayment" : ""} : {numberFormat(item.term_value)}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {log.status === 1 ? (
                  <div className="col-12">
                    <button className="btn btn-sm btn-primary ms-3 float-end" onClick={submitData}>
                      <i className="bi bi-send-fill"></i> Submit Data
                    </button>
                  </div>
                ) : log.status === 2 ? (
                  <div className="col-12">
                    <button className="btn btn-sm btn-primary ms-3 float-end" data-bs-toggle="modal" data-bs-target="#salesPrice">
                      <i className="bi bi-gear-wide-connected"></i> Setup Sales Price
                    </button>
                  </div>
                ) : log.status === 3 ? (
                  <div className="col-12">
                    <button className="btn btn-sm btn-primary ms-3 float-end" onClick={() => generateMOU(visa)}>
                      <i className="bi bi-printer-fill"></i> Generate MOU
                    </button>
                  </div>
                ) : log.status === 4 ? (
                  <div className="col-12">
                    <button className="btn btn-sm btn-primary ms-3 float-end" onClick={previewMOU} data-bs-toggle="modal">
                      <i className="bi bi-printer-fill"></i> Preview MOU
                    </button>
                    <button className="btn btn-sm btn-primary ms-3 float-end" onClick={() => approveMOU(visa)}>
                      <i className="bi bi-check-circle-fill"></i> Approve MOU
                    </button>
                  </div>
                ) : log.status === 5 ? (
                  <div className="col-12">
                    {/* <button className="btn btn-sm btn-primary ms-3 float-end" onClick={midtrans}>
                      <i className="bi bi-wallet-fill"></i> Pay
                    </button> */}
                    {master.termin_payment[0].flag === "paid-off" ? (
                      <button className="btn btn-sm btn-primary ms-3 float-end" onClick={generateInvoice}>
                        <i className="bi bi-wallet-fill"></i> Generate Payment Paid Off Manually
                      </button>
                    ) : master.termin_payment[0].flag === "down-payment" ? (
                      <button className="btn btn-sm btn-primary ms-3 float-end" onClick={generateInvoice}>
                        <i className="bi bi-wallet-fill"></i> Generate Down Payment Manually
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : log.status === 6 ? (
                  <div className="col-12">
                    <button className="btn btn-sm btn-primary ms-3 float-end" data-bs-toggle="modal" data-bs-target="#changeStatusPayment">
                      <i className="bi bi-bookmark-check-fill"></i> Change Status Payment
                    </button>
                  </div>
                ) : log.status === 8 ? (
                  <div className="col-12">
                    {master.termin_payment.length === 2 ? (
                      <>
                        {master.termin_payment[1].flag === "repayment" ? (
                          <>
                            <button className="btn btn-sm btn-primary ms-3 float-end" onClick={autoGenerateRepaymentInvoice}>
                              <i className="bi bi-bookmark-check-fill"></i> Auto Generate Repayment
                            </button>
                            <button className="btn btn-sm btn-outline-primary ms-3 float-end" onClick={generateInvoiceRepayment}>
                              <i className="bi bi-bookmark-check-fill"></i> Generate Repayment Manually
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <button className="btn btn-sm btn-primary ms-3 float-end" data-bs-toggle="modal" data-bs-target="#changeStatusSentModal">
                        <i className="bi bi-bookmark-check-fill"></i> Change Status to Document Hand Over
                      </button>
                    )}
                  </div>
                ) : log.status === 9 ? (
                  <div className="col-12">
                    <button className="btn btn-sm btn-primary ms-3 float-end" data-bs-toggle="modal" data-bs-target="#changeStatusPayment">
                      <i className="bi bi-bookmark-check-fill"></i> Change Status Payment
                    </button>
                  </div>
                ) : log.status === 10 ? (
                  <div className="col-12">
                    <button className="btn btn-sm btn-primary ms-3 float-end" data-bs-toggle="modal" data-bs-target="#changeStatusSentModal">
                      <i className="bi bi-bookmark-check-fill"></i> Change Status to Document Hand Over
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <hr />
              <strong>List Request Documents</strong>
              <div className="card card-body mt-3">
                {detail.map((value, index) => {
                  return (
                    <>
                      <div key={index} className="row row-cols-md-auto g-3 align-items-center">
                        <div className="col-md-1 text-center">
                          <img
                            src={value.data.file_photo === null ? empty : Storage + `/Services/Legal/Modules/${master["module"].name}/Transaction/${master.id}/${value.data.file_photo}`}
                            alt=""
                            style={{ width: "3.5rem", height: "3.5rem", borderRadius: "50%" }}
                          />
                        </div>
                        <div className="col-md-5" style={{ fontSize: "0.9rem" }}>
                          <div className="mt-3">
                            <strong>{value.data.full_name}</strong>
                            <p>{value.data.request_code}</p>
                            <span className="badge text-bg-primary">{value.data["detail_log_latest"][0].status}</span>
                          </div>
                          <div>
                            {log.status === 8 ? (
                              <>
                                <button className="btn btn-primary btn-sm mt-3" data-bs-toggle="modal" data-bs-target="#logStatusModal" onClick={() => handleShow(value.data.id)}>
                                  Update Status
                                </button>
                                <button className="btn btn-primary btn-sm mt-3 ms-3" onClick={() => handleShowModalDocument(value.data.id)}>
                                  Update Document
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <p className="float-end text-end mt-3" style={{ fontSize: "0.9rem" }}>
                            <strong>{numberFormat(master["destination"].price)}</strong>
                            <br />
                            {log.status === 1 || log.status === 2 ? (
                              <Link to={`/transaction-legal/detail/${visa}/data/${value.data.id}`} style={{ textDecoration: "none", fontSize: "0.9rem" }}>
                                Check Document <i className="bi bi-chevron-right" style={{ fontSize: "0.8rem" }}></i>
                              </Link>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                        <div className="col-md-1">
                          {log.status === 1 ? (
                            <Link to="#" className="btn btn-sm btn-danger" role="button" onClick={() => handleDelete(value.data.id)}>
                              <i className="bi bi-trash-fill"></i>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
              {log.status !== 1 ? (
                ""
              ) : (
                <div className="d-grid gap-2 mt-3">
                  <Link to={`/transaction-legal/detail/${visa}/create-another-documents`} className="btn btn-primary" role="button">
                    <i className="bi bi-plus-lg"></i> Add Another Documents
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal Setup Sales Price */}
      <div className="modal modal-lg fade" id="salesPrice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Setup Sales Price
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Total Price : {numberFormat(detail.length * master.destination["price"])}</p>
              <p>Select Payment Record :</p>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      1x Payment Record
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <form onSubmit={handlePaymentOne}>
                        <div className="form-floating mb-3">
                          <CurrencyInput
                            placeholder="IDR 0.00"
                            type="text"
                            defaultValue={detail.length * master.destination["price"]}
                            onChange={(e) => setPrice(e.target.value)}
                            className={`form-control ${validation.price ? "is-invalid" : ""}`}
                            id="floatingInput"
                          />
                          <label htmlFor="floatingInput">Input Nominal</label>
                          {validation.price && (
                            <div className={`invalid-feedback ${validation.price ? "d-block mb-3" : "d-none"}`}>
                              <i className="bi bi-exclamation-circle me-2"></i>
                              {validation.price[0]}
                            </div>
                          )}
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      2x Payment Record
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <form onSubmit={handlePaymentTwo}>
                        <div className="form-floating mb-3">
                          <CurrencyInput placeholder="IDR 0.00" type="text" value={nominal} onChange={handlePercentage} className={`form-control ${validation.price ? "is-invalid" : ""}`} id="floatingInput" />
                          <label htmlFor="floatingInput">Input Nominal</label>
                          {validation.nominal && (
                            <div className={`invalid-feedback ${validation.nominal ? "d-block mb-3" : "d-none"}`}>
                              <i className="bi bi-exclamation-circle me-2"></i>
                              {validation.nominal[0]}
                            </div>
                          )}
                        </div>
                        <div className="form-floating mb-3">
                          <PercentageInput placeholder="0%" value={percent} onChange={handleNominal} type="text" className={`form-control`} id="floatingPercent" />
                          <label htmlFor="floatingPercent">Input Percentage</label>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal Setup Sales Price */}

      {/* Modal Log Status */}
      <div className="modal fade" id="logStatusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {dataLoading === false ? "" : <>{dataDetails === null ? "" : dataDetails.full_name + " Document"}</>}
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="modal-body mb-3">
              {dataLoading === false ? (
                <p className="position-absolute top-50 start-50 translate-middle">Loading Data . . .</p>
              ) : (
                <>
                  {dataShow === null ? (
                    ""
                  ) : (
                    <>
                      <Wizard className="mb-3">
                        {dataShow.map((item, index) => (
                          <>
                            {/* <Line /> */}
                            <Step key={index}>
                              <span>{item.data.status}</span>
                              <span className="float-end text-muted">{item.data.status_date}</span>
                              <br />
                              <span className="text-muted">{item.data.description}</span>
                            </Step>
                          </>
                        ))}
                      </Wizard>
                      <hr />
                      <form>
                        <p>Update Log Status : </p>
                        <div className="form-floating mb-3">
                          <input type="text" className={`form-control ${validation.status ? "is-invalid" : ""}`} id="floatingInput" placeholder="Input status" onChange={(e) => setFormStatus(e.target.value)} />
                          <label htmlFor="floatingInput">Status</label>
                          {validation.status && (
                            <div className={`invalid-feedback ${validation.status ? "d-block mb-3" : "d-none"}`}>
                              <i className="bi bi-exclamation-circle me-2"></i>
                              {validation.status[0]}
                            </div>
                          )}
                        </div>
                        <div className="form-floating">
                          <textarea className="form-control" placeholder="Leave a description here" id="floatingTextarea2" style={{ height: "100px" }} onChange={(e) => setFormDescription(e.target.value)}></textarea>
                          <label htmlFor="floatingTextarea2">Description</label>
                        </div>
                        <div className="float-end mt-5">
                          <button type="button" className="btn btn-danger me-3" data-bs-dismiss="modal" onClick={handleClose}>
                            Close
                          </button>
                          <button type="button" className="btn btn-primary" onClick={() => updateLogDetails(dataDetails.id)}>
                            Save changes
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* End Modal Log Status */}

      {/* Modal Change Status to Sent By Maxx's */}
      <div className="modal fade" id="changeStatusSentModal" tabIndex="-1" aria-labelledby="changeStatusSentModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Change Status
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Select Document :
              <form onSubmit={statusSentDocument}>
                {detail.map((item, index) => (
                  <div key={index} className="form-check">
                    <input className="form-check-input" type="checkbox" value={item.data.id} id="flexCheckDefault" onChange={handleCheck(item.data.id)} />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      {item.data.full_name} - <span className="text-muted">{item.data.request_code}</span>
                      {validation.status && (
                        <div className={`invalid-feedback ${validation.status ? "d-block mb-3" : "d-none"}`}>
                          <i className="bi bi-exclamation-circle me-2"></i>
                          {validation.status[0]}
                        </div>
                      )}
                    </label>
                  </div>
                ))}
                <div className="float-end mt-5">
                  <button type="button" className="btn btn-danger me-3" data-bs-dismiss="modal" onClick={handleClose}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal Change Status to Sent By Maxx's */}

      {/* Modal Change Status Payment */}

      <div className="modal fade" id="changeStatusPayment" tabIndex="-1" aria-labelledby="changeStatusPayment" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="changeStatusPayment">
                Change Status Payment
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={changeStatusPayment}>
              <div className="modal-body">
                <p>Change Status To :</p>
                <div className="text-center mb-3">
                  <input type="radio" className="btn-check" name="options" id="option1" autoComplete="off" value="5" onChange={payStatusValue} />
                  <label className="btn btn-outline-danger me-3" htmlFor="option1">
                    Payment Failed
                  </label>
                  <input type="radio" className="btn-check" name="options" id="option2" autoComplete="off" value="2" onChange={payStatusValue} />
                  <label className="btn btn-outline-success" htmlFor="option2">
                    Payment Success
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* End Modal Change Status Payment */}

      {/* Modal Update Document  */}
      <Modal show={showModalDocument} onHide={handleCloseModalDocument}>
        <Modal.Header closeButton>
          <Modal.Title>Update Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={updateDocument}>
              <div className="form-floating mb-3">
                <input type="date" className={`form-control ${validationDocument.expired_date ? "is-invalid" : ""}`} id="floatingInput" placeholder="Expired Date" onChange={(e) => setExpiredDate(e.target.value)} />
                <label htmlFor="floatingInput">Expired Date</label>
                {validationDocument.expired_date && <div className="alert alert-danger mt-2">{validationDocument.expired_date[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="date" className={`form-control ${validationDocument.issued_date ? "is-invalid" : ""} `} id="floatingInput" placeholder="Issued Date" onChange={(e) => setIssuedDate(e.target.value)} />
                <label htmlFor="floatingInput">Issued Date</label>
                {validationDocument.issued_date && <div className="alert alert-danger mt-2">{validationDocument.issued_date[0]}</div>}
              </div>
              <div className="form-floating mb-3">
                <input type="text" className={`form-control ${validationDocument.issued_place ? "is-invalid" : ""}`} id="floatingInput" placeholder="name@example.com" onChange={(e) => setIssuedPlace(e.target.value)} />
                <label for="floatingInput">Issued Place</label>
                {validationDocument.issued_place && <div className="alert alert-danger mt-2">{validationDocument.issued_place[0]}</div>}
              </div>
              <div className="mb-5">
                <label htmlFor="formFile" className="form-label">
                  File Visa
                </label>
                <input className={`form-control ${validationDocument.visa_file_name ? "is-invalid" : ""}`} type="file" onChange={(e) => setVisaFileName(e.target.files[0])} />
                {validationDocument.visa_file_name && <div className="alert alert-danger mt-2">{validationDocument.visa_file_name[0]}</div>}
              </div>
              <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCloseModalDocument}>
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </Modal.Footer>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* End Modal Update Document  */}
      <AppFooter />
    </>
  );
}

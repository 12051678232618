import React, { useMemo, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AppSpinner from "../Components/AppSpinner";
import { API } from "../Config/Config";

import Table from "./TableDepartment";

export default function DataTableDepartment() {
  const api = API;
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const header = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    };
  }, [token]);

  const [dept, setDept] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [success, setSuccess] = useState(true);

  const fetchDepartment = useCallback(async () => {
    await axios.get(api + `/department/list`, header).then(function (response) {
      setDept(response.data.data);
      setIsLoaded(true);
    });
  }, [api, header]);

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleting Data ...",
            html: "Please wait ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          axios
            .delete(api + `/department/delete/${id}`, header)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 1500,
              });
              fetchDepartment();
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true,
              });
            });
        }
      });
    },
    [api, fetchDepartment, header]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  <Link to={`/department/edit/${value}`} className="btn btn-sm btn-secondary m-1">
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                  <Link to="#" className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(value)}>
                    <i className="bi bi-trash-fill"></i>
                  </Link>
                </>
              );
            },
          },
          {
            Header: "Name",
            accessor: "name",
          },
        ],
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
    fetchDepartment();
  }, [token, fetchDepartment]);

  if (!isLoaded) {
    return (
      <>
        <AppSpinner title="Loading data Deparment . . ." />
      </>
    );
  }

  if (dept.length < 1) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center align-self-center">
          <div className="px-4 py-5 my-5 text-center">
            <div className="fs-1">
              <i className="bi bi-info-circle"></i>
            </div>
            <h1 className="display-5 fw-bold text-body-emphasis">Data not found</h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">Department data not found, to start filling in new Department data, please click the add new button at the top right of your screen.</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="container-fluid table-responsive">
      <Table columns={columns} data={dept} />
    </div>
  );
}

import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import AppBreadcrumb from "../../Components/AppBreadcrumb";
import DataTableMOU from "./DataTableMOU";
import { API } from "../../Config/Config";
import Swal from "sweetalert2";

export default function IndexMOU() {
  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, []);

  const api = API;

  const [success, setSuccess] = useState(true);

  const arrBreadcrumbs = [
    { id: "1", title: "Home", slug: "/", status: "" },
    { id: "2", title: "MOU Templates", slug: "/index-mou", status: "active" },
  ];

  let navigate = useNavigate();
  const updateMOU = () => {
    navigate("/update-mou");
  };

  const pdf = useCallback(async () => {
    Swal.fire({
      title: "Generating MOU ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await axios({
      url: api + "/tools/mou/preview-mou",
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
        // "Content-type": "application/json",
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Template MOU.pdf");
        document.body.appendChild(link);
        link.click();

        Swal.fire({
          icon: "success",
          title: "PDF Generated.",
          // showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 500) {
          Swal.fire({
            icon: "warning",
            title: "No data to show.",
            // showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    // Swal.close();
  }, [token, api]);

  useEffect(() => {
    if (!token) {
      setSuccess(false);
    }
  }, [token]);

  return (
    <>
      <div className="container-fluid p-0 m-0 mb-5">
        <AppHeader />
        <div className="d-flex flex-row align-items-center align-self-center px-4 mb-3 pb-2 border-bottom">
          <div className="flex-grow-1 py-2">
            <AppBreadcrumb breadcrumbs={arrBreadcrumbs} />
          </div>
          <div className="py-1">
            <button className="btn btn-sm btn-primary px-2" onClick={updateMOU}>
              <i className="bi bi-plus-circle"></i> Add New
            </button>
            <button className="btn btn-sm btn-primary ms-3" onClick={pdf} target="_blank">
              <i className="bi bi-printer-fill"></i> Preview PDF
            </button>
          </div>
        </div>
        <div className="container-fluid p-0 m-0 mb-5">
          <DataTableMOU />
        </div>
        <AppFooter />
      </div>
    </>
  );
}

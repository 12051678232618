import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import Dashboard from "../Dashboard/Dashboard";
import IndexUser from "../User/IndexUser";
import CreateUser from "../User/CreateUser";
import EditUser from "../User/EditUser";
import IndexNewTransaction from "../Dashboard/IndexNewTransaction";
import DetailNewTransaction from "../Dashboard/DetailNewTransaction";
import DetailRequest from "../Dashboard/DetailRequest";
import IndexUrl from "../Url/IndexUrl";
import CreateUrl from "../Url/CreateUrl";
import EditUrl from "../Url/EditUrl";
import IndexCountry from "../Country/IndexCountry";
import CreateCountry from "../Country/CreateCountry";
import EditCountry from "../Country/EditCountry";
import IndexState from "../State/IndexState";
import CreateState from "../State/CreateState";
import EditState from "../State/EditState";
import IndexCity from "../City/IndexCity";
import CreateCity from "../City/CreateCity";
import EditCity from "../City/EditCity";
import IndexParam from "../Parameter/IndexParam";
import CreateParam from "../Parameter/CreateParam";
import EditParam from "../Parameter/EditParam";
import IndexPermission from "../Permission/IndexPermission";
import CreatePermission from "../Permission/CreatePermission";
import EditPermission from "../Permission/EditPermission";
import IndexRoles from "../Role/IndexRoles";
import CreateRoles from "../Role/CreateRoles";
import EditRoles from "../Role/EditRoles";
import AddUrlAccess from "../Role/AddUrlAccess";
import IndexCustomer from "../Customer/IndexCustomer";
import CreateCustomer from "../Customer/CreateCustomer";
import EditCustomer from "../Customer/EditCustomer";
import DetailsCustomer from "../Customer/DetailsCustomer";
import IndexServices from "../Services/IndexServices";
import CreateServices from "../Services/CreateServices";
import EditServices from "../Services/EditServices";
import IndexModules from "../Services/Modules/IndexModules";
import CreateModules from "../Services/Modules/CreateModules";
import EditModules from "../Services/Modules/EditModules";
import IndexFormElement from "../Tools/FormElement/IndexFormElement";
import CreateFormElement from "../Tools/FormElement/CreateFormElement";
import EditFormElement from "../Tools/FormElement/EditFormElement";
import IndexFormModules from "../Tools/FormModules/IndexFormModules";
import CreateFormModules from "../Tools/FormModules/CreateFormModules";
import IndexProductPrice from "../Tools/ProductPrice/IndexProductPrice";
import CreateProductPrice from "../Tools/ProductPrice/CreateProductPrice";
import EditProductPrice from "../Tools/ProductPrice/EditProductPrice";
import IndexTransactionLegal from "../TransactionLegal/IndexTransactionLegal";
import CreateTransVisa from "../TransactionLegal/Visa/CreateTransVisa";
import DetailTransVisa from "../TransactionLegal/Visa/DetailTransVisa";
import CreateAnotherDocuments from "../TransactionLegal/Visa/CreateAnotherDocuments";
import DataTransVisa from "../TransactionLegal/Visa/DataTransVisa";
import IndexMOU from "../Tools/MOU/IndexMOU";
import UpdateMOU from "../Tools/MOU/UpdateMOU";
import IndexOfficeLocation from "../OfficeLocation/IndexOfficeLocation";
import CreateOfficeLocation from "../OfficeLocation/CreateOfficeLocation";
import EditOfficeLocation from "../OfficeLocation/EditOfficeLocation";
import IndexTrack from "../TransactionLegal/Tracking/IndexTrack";
import CreateTrack from "../TransactionLegal/Tracking/CreateTrack";
import UpdateTrack from "../TransactionLegal/Tracking/UpdateTrack";
import EditTrack from "../TransactionLegal/Tracking/EditTrack";
import IndexEula from "../Tools/EulaTemplate/IndexEula";
import UpdateEula from "../Tools/EulaTemplate/UpdateEula";
import IndexNewsUpdates from "../Tools/NewsUpdates/IndexNewsUpdates";
import CreateNewsUpdates from "../Tools/NewsUpdates/CreateNewsUpdates";
import EditNewsUpdates from "../Tools/NewsUpdates/EditNewsUpdates";
import IndexEmployee from "../Employee/IndexEmployee";
import CreateEmployee from "../Employee/CreateEmployee";
import EditEmployee from "../Employee/EditEmployee";
import IndexDepartment from "../Department/IndexDepartment";
import CreateDepartment from "../Department/CreateDepartment";
import EditDepartment from "../Department/EditDepartment";
import ViewCustomerMemberClient from "../Customer/ViewCustomerMemberClient";
import IndexVendor from "../Vendor/IndexVendor";
import CreateVendor from "../Vendor/CreateVendor";
import EditVendor from "../Vendor/EditVendor";
import DetailVendor from "../Vendor/DetailVendor";

function Path() {
  return (
    <div className="Routes">
      <Routes>
        {/* Menu View Global */}
        <Route path="/member/:code" element={<ViewCustomerMemberClient />} />
        {/* End Menu View Global */}
        {/* Menu Auth */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* End Menu Auth */}

        {/* Menu Dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/newTransaction" element={<IndexNewTransaction />} />
        <Route path="/detailTransaction" element={<DetailNewTransaction />} />
        <Route path="/detailRequest" element={<DetailRequest />} />
        {/* End Menu Dashboard */}

        {/* Menu Database */}
        <Route path="/customers" element={<IndexCustomer />} />
        <Route path="/customers/create" element={<CreateCustomer />} />
        <Route path="/customers/details/:id" element={<DetailsCustomer />} />
        <Route path="/customers/details/edit/:id" element={<EditCustomer />} />
        <Route path="/user" element={<IndexUser />} />
        <Route path="/user/create" element={<CreateUser />} />
        <Route path="/user/edit/:id" element={<EditUser />} />
        <Route path="/office" element={<IndexOfficeLocation />} />
        <Route path="/office/create" element={<CreateOfficeLocation />} />
        <Route path="/office/edit/:id" element={<EditOfficeLocation />} />
        <Route path="/employee" element={<IndexEmployee />} />
        <Route path="/employee/create" element={<CreateEmployee />} />
        <Route path="/employee/edit/:id" element={<EditEmployee />} />
        <Route path="/department" element={<IndexDepartment />} />
        <Route path="/department/create" element={<CreateDepartment />} />
        <Route path="/department/edit/:id" element={<EditDepartment />} />
        <Route path="/vendor" element={<IndexVendor />} />
        <Route path="/vendor/create" element={<CreateVendor />} />
        <Route path="/vendor/detail/:id" element={<DetailVendor />} />
        <Route path="/vendor/edit/:id" element={<EditVendor />} />
        {/* End Menu Database */}

        {/* Menu Master */}
        <Route path="/country" element={<IndexCountry />} />
        <Route path="/country/create" element={<CreateCountry />} />
        <Route path="/country/edit/:id" element={<EditCountry />} />
        <Route path="/state" element={<IndexState />} />
        <Route path="/state/create" element={<CreateState />} />
        <Route path="/state/edit/:id" element={<EditState />} />
        <Route path="/city" element={<IndexCity />} />
        <Route path="/city/create" element={<CreateCity />} />
        <Route path="/city/edit/:id" element={<EditCity />} />
        <Route path="/parameter" element={<IndexParam />} />
        <Route path="/parameter/create" element={<CreateParam />} />
        <Route path="/parameter/edit/:id" element={<EditParam />} />
        <Route path="/permission" element={<IndexPermission />} />
        <Route path="/permission/create" element={<CreatePermission />} />
        <Route path="/permission/edit/:id" element={<EditPermission />} />
        <Route path="/services" element={<IndexServices />} />
        <Route path="/services/create" element={<CreateServices />} />
        <Route path="/services/edit/:id" element={<EditServices />} />
        <Route path="/services/:service/modules" element={<IndexModules />} />
        <Route path="/services/:service/modules/create" element={<CreateModules />} />
        <Route path="/services/:service/modules/:module/edit" element={<EditModules />} />
        <Route path="/services/:service/modules/:module/form" element={<IndexFormModules />} />
        <Route path="/services/:service/modules/:module/form/create" element={<CreateFormModules />} />
        {/* End Menu Master */}

        {/* Menu Settings */}
        <Route path="/url" element={<IndexUrl />} />
        <Route path="/url/create" element={<CreateUrl />} />
        <Route path="/url/edit/:id" element={<EditUrl />} />
        <Route path="/roles" element={<IndexRoles />} />
        <Route path="/roles/create" element={<CreateRoles />} />
        <Route path="/roles/edit/:id" element={<EditRoles />} />
        <Route path="/roles/url-access/:id" element={<AddUrlAccess />} />
        {/* End Menu Settings */}

        {/* Menu Transaction */}
        <Route path="/transaction-legal" element={<IndexTransactionLegal />} />
        <Route path="/transaction-legal/:module/create" element={<CreateTransVisa />} />
        <Route path="/transaction-legal/detail/:visa" element={<DetailTransVisa />} />
        <Route path="/transaction-legal/detail/:visa/create-another-documents" element={<CreateAnotherDocuments />} />
        <Route path="/transaction-legal/detail/:visa/data/:id" element={<DataTransVisa />} />
        <Route path="/track" element={<IndexTrack />} />
        <Route path="/track/create" element={<CreateTrack />} />
        <Route path="/track/update/:id" element={<UpdateTrack />} />
        <Route path="/track/edit/:id" element={<EditTrack />} />
        {/* End Menu Transaction */}

        {/* Menu Tools */}
        <Route path="/form-element" element={<IndexFormElement />} />
        <Route path="/form-element/create" element={<CreateFormElement />} />
        <Route path="/form-element/edit/:id" element={<EditFormElement />} />
        <Route path="/product-price" element={<IndexProductPrice />} />
        <Route path="/product-price/create" element={<CreateProductPrice />} />
        <Route path="/product-price/edit/:id" element={<EditProductPrice />} />
        <Route path="/index-mou" element={<IndexMOU />} />
        <Route path="/update-mou" element={<UpdateMOU />} />
        <Route path="/index-eula" element={<IndexEula />} />
        <Route path="/update-eula" element={<UpdateEula />} />
        <Route path="/news" element={<IndexNewsUpdates />} />
        <Route path="/news/create" element={<CreateNewsUpdates />} />
        <Route path="/news/edit/:id" element={<EditNewsUpdates />} />
        {/* End Menu Tools */}
      </Routes>
    </div>
  );
}

export default Path;
